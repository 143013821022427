/* CKEDITOR SPECIFICS */
body.inner-content {
	padding: 20px;
	overflow-x: hidden;
}

$blue: #00154d;
$white: #ffffff;
$black: #1d1d1d;
$grey: #919191;
$orange: #ea8302;
$grey_2: #8e8e8e;
$light_blue: #7a8cb0;
$dark_blue: #002a5c;

.floorcoverings {
	color: #0099a9;

	&:before {
		background: #0099a9;
	}
}

.construction {
	color: #7e4115;

	&:before {
		background: #7e4115;
	}
}

.medical {
	color: #00aeef;

	&:before {
		background: #00aeef;
	}
}

.laminate-substrates {
	color: #1d1934;

	&:before {
		background: #1d1934;
	}
}

.horticulture {
	color: #45a142;

	&:before {
		background: #45a142;
	}
}

.geotextiles {
	color: #ec008c;

	&:before {
		background: #ec008c;
	}
}

.filtration {
	color: #d91010;

	&:before {
		background: #d91010;
	}
}

.dlg {
	color: #44693d;

	&:before {
		background: #44693d;
	}
}

.coated-fabrics {
	color: #000154;

	&:before {
		background: #000154;
	}
}

.categories-generic {
	color: $blue;

	&:before {
		background: $blue;
	}
}

.new-to-dl,
.torodon {
	color: #00154d;

	&:before {
		background: #00154d;
	}
}

.slick-slide,
.slick-slide a {
	outline: none;
}

.no-margin {
	margin: 0;
}

.no-padding {
	padding: 0;
}

.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > td,
.table-borderless > tfoot > tr > th,
.table-borderless > thead > tr > td,
.table-borderless > thead > tr > th {
	border: none;
}

.site-content {
	padding-top: 0px;
}

.modal-open {
	overflow: hidden;

	.site-content {
		position: relative;
		z-index: 1999;
	}
}

.modal {
	z-index: 9999;
}

.container {
	max-width: 100%;
}

@media (min-width: 1200px) {
	.container {
		width: 1310px;
	}
}

.megamenu_mobile {
	max-height: 100vh;
	padding: 80px 0 20px 30px;

	.mCustomScrollBox {
		overflow-y: hidden;
	}

	.top-links {
		padding: 10px 0;

		a {
			text-align: right;
			float: right;
			margin-right: 10px;
			color: $blue;
			font-size: 24px;
			line-height: 25px;
			svg{
				fill:$blue;
			}

			@media screen and (max-width: 991px) {
				font-size: 22px;
			}

			@media screen and (max-width: 767px) {
				font-size: 18px;
			}

			&.thrace-group {
				float: left;
				margin: 0;
				max-width: 139px;
				display: inline-block;
			}
		}
	}

	.top_menu__level {
		padding: 0;
		margin: 0;
		list-style: none;

		li {
			.top_menu_item {
				padding: 8px 0;
				display: block;
				position: relative;
				border-bottom: 1px solid $blue;

				&:focus {
					text-decoration: none;
				}

				&.toggle {
					.icon {
						position: absolute;
						right: 10px;
						top: 50%;
						transform: translateY(-50%);

						svg {
							transition: all 0.2s ease-in-out;
							width: 12px;
							fill: $blue;
						}
					}
				}
			}

			.collapsed-content {
				background: #fff;
				max-height: 0;
				overflow-y: hidden;
				transition: all 0.5s ease-in-out;
				position: relative;
				padding-bottom: 0;

				.submenu {
					padding: 20px;
					list-style: none;

					li {
						a {
							line-height: 1;
							height: auto;
							padding: 7px 15px 7px 0;
							display: block;
							font-size: 1.1rem !important;
							color: $blue;

							@media screen and (max-width: 767px) {
								font-size: 1rem !important;
							}
						}
					}
				}
			}

			&.open {
				.top_menu_item {
					&.toggle {
						.icon {
							svg {
								-webkit-transform: rotate(-180deg);
								-ms-transform: rotate(-180deg);
								transform: rotate(-180deg);
							}
						}
					}
				}

				.collapsed-content {
					max-height: 2320px;
				}
			}

			.top_menu_item {
				font-size: 1.3rem;
				color: $blue;
			}
		}
	}

	.mCS-dark.mCSB_scrollTools {
		opacity: 1;

		.mCSB_dragger .mCSB_dragger_bar {
			background-color: $blue;
			width: 6px;
		}
	}
}

[data-module="nav"][data-type="top_side"] .nav_cont {
	width: 60% !important;
	margin: 0 20% !important;

	@media screen and (max-width: 1450px) {
		width: 70% !important;
		margin: 0 15% !important;
	}

	@media screen and (max-width: 991px) {
		width: calc(100% - 60px) !important;
		margin: 0 30px !important;
	}

	@media screen and (max-width: 767px) {
		width: calc(100% - 30px) !important;
		margin: 0 15px !important;
	}
}

svg {
	.st1-lobac,
	.st1-daltex,
	.st1-groshield,
	.st1-groshield,
	.st1-lobrene,
	.st1-lotrak {
		fill: #7a8cb0 !important;
	}

	.st0-lobac,
	.st0-daltex,
	.st0-groshield,
	.st0-lobrene,
	.st0-lotrak {
		fill: $blue !important;
	}

	.first-part {
		fill: #002b5c;
	}

	.second-part {
		fill: #7a8cb0;
	}

	.third-part {
		fill: #0d2240;
	}
}

.brand-item {
	&:hover {
		svg {
			* {
				fill: #ffffff !important;
			}
		}
	}
}

[data-module="nav"][data-type="top_side"] .menu__wrap {
	width: 100%;
}

[data-module="nav"][data-type="top_side"] .menu__wrap .menu__level .menu__item .have_sub {
	position: initial !important;
	width: 100%;
}

[data-module="nav"][data-type="top_side"] .second-level-content .menu__level .menu__item {
	width: 33%;

	@media screen and (max-width: 991px) {
		width: 50%;
	}

	@media screen and (max-width: 767px) {
		width: 100%;
	}
}

.capabilities_menu_item {
	width: 50% !important;
}

[data-module="nav"][data-type="top_side"] .menu__link {
	text-align: left;
}

.products-range-submenu {
	display: none;
	position: absolute;
	left: 80%;
	top: 0;
}

.products-range-with-submenu:hover {
	.products-range-submenu {
		display: block;
	}
}

[data-module="nav"][data-type="top_side"] .menu__level.menu_sub {
	margin: 0;
	padding: 0;
	position: absolute;
	list-style: none;
	display: none;
	left: 55%;
	top: 0;
	z-index: 3;
	transition: display 0s linear;
	transition-delay: 0.5s;

	.products-submenu {
		.menu__link {
			height: auto;
			line-height: 24px;
		}
	}

	.sub_menu_item {
		.menu__link {
			float: none;
			color: $black;

			.pname {
				color: $light_blue;
				font-family: donlowBold, serif;
				margin-left: 5px;

				svg {
					height: 16px;
					width: auto;

					&.daltex-vck-svg-logo,
					&.multi-tx-plus-svg-logo {
						height: 30px !important;
					}
				}
			}

			b span {
				background: $black !important;
			}
		}
	}
}

[data-module="inclusion"][data-type="even_odd"] .col-right .col-cont,
[data-module="inclusion"][data-type="even_odd"] .col-left .col-cont,
[data-module="inclusion"][data-type="even_odd"] .col-left,
[data-module="inclusion"][data-type="even_odd"] .col-right {
	height: auto !important;
	max-height: 500px;

	@media (max-width: 992px) {
		float: none;
		max-height: 400px;
	}

	@media (max-width: 767px) {
		max-height: 300px;
	}

	.title.text_cover {
		float: none;
		display: block;
		text-align: center;
	}
}

[data-module="nav"][data-type="top_side"] .menu__level {
	display: block;
	float: left;
	position: relative;
	left: 0;
	height: auto;
	margin: 0 0 0 6px;
	padding: 0;
	list-style-type: none;
}

/*.have_sub:hover {
	width: 100%!important;
	.menu__level.menu_sub {
		display: block;
	}
}*/

.morphsearch {
	top: -150px;
	right: -150px;
	position: fixed;
	background: #ffffff;

	.morphsearch-input {
		color: $blue;
		padding: 0;
	}

	.morphsearch-content {
		background: #ffffff;
	}

	.dummy-column {
		h2 {
			span {
				color: $blue !important;
			}
		}
	}

	.morphsearch-close::after,
	.morphsearch-close::before {
		background: $blue;
	}

	.dummy-media-object {
		border-radius: 0px;
		background: none;
		border: 1px solid $blue;

		h3 {
			color: $blue;
		}

		&:hover {
			background: $blue;

			h3 {
				color: $white;
			}
		}
	}

	.item {
		border-radius: 0px;
		background: none;
		border: 1px solid $blue;
		padding: 0.75em;
		display: block;
		margin: 0.3em 0;
		cursor: pointer;
		transition: all ease-in-out 0.2s;

		h3 {
			color: $blue;
			vertical-align: middle;
			font-size: 0.85em;
			display: block;
			font-family: "donlowBold", serif;
			margin: 0;
			transition: all ease-in-out 0.2s;
		}

		h4 {
			margin-bottom: 0;
			margin-top: 5px;
			font-size: 16px;
			transition: all ease-in-out 0.2s;
		}

		&:hover {
			background: $blue;

			h3 {
				color: $white;
			}

			h4 {
				color: $white;
			}
		}
	}
}

.page-hero {
	min-height: 800px;
	position: relative;
	background-size: cover;
	text-align: center;
	background-position: center center;

	.title {
		font-family: donlowBold, serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: $white;
		font-size: 42px;
		position: absolute;
		bottom: 25%;
		left: 50%;
		transform: translateX(-50%);
	}
}

h1,
h2,
h3,
h4,
h5 {
	color: $blue;
}

.inner-content {
	h1 {
		font-size: 42px;

		@media only screen and (max-width: 991px) {
			font-size: 40px;
		}

		@media screen and (max-width: 767px) {
			font-size: 38px;
		}
	}

	h2 {
		font-size: 32px;
		margin-bottom: 30px;

		@media only screen and (max-width: 991px) {
			font-size: 28px;
		}

		@media screen and (max-width: 767px) {
			font-size: 26px;
		}
	}

	h3 {
		font-size: 24px;

		@media only screen and (max-width: 991px) {
			font-size: 22px;
		}

		@media only screen and (max-width: 767px) {
			font-size: 20px;
		}
	}

	h4 {
		font-size: 20px;

		@media only screen and (max-width: 991px) {
			font-size: 18px;
		}

		@media only screen and (max-width: 767px) {
			font-size: 16px;
		}
	}

	a {
		color: $blue;
		text-decoration: underline;
		-moz-transition: all 0.15s;
		-o-transition: all 0.15s;
		-webkit-transition: all 0.15s;
		transition: all 0.15s;
	}

	p {
		font-size: 18px;
		margin-bottom: 20px;

		@media only screen and (max-width: 991px) {
			font-size: 16px;
			margin-bottom: 15px;
		}

		@media only screen and (max-width: 767px) {
			font-size: 14px;
			margin-bottom: 15px;
		}
	}

	ul {
		padding: 0;
		list-style-type: none;
		margin-bottom: 30px;

		li {
			font-size: 18px;

			@media screen and (max-width: 991px) {
				font-size: 16px;
			}

			@media only screen and (max-width: 767px) {
				font-size: 14px;
			}

			position: relative;
			margin-bottom: 10px;
			padding-left: 30px;

			&:before {
				content: "\f105";
				font: normal normal normal 20px/1 FontAwesome;
				position: absolute;
				left: 0;
				top: 2px;
				color: $blue;
			}
		}
	}

	h2.overview {
		position: relative;
		padding-left: 60px;

		&:before {
			position: absolute;
			content: url(../img/icons/overview.svg);
			left: 0;
			top: -10px;
			width: 50px;
		}

		@media screen and (max-width: 767px) {
			margin-left: 60px;
		}
	}

	h2.features {
		margin-top: 0;
		margin-bottom: 30px;
		position: relative;

		&:before {
			position: absolute;
			content: url(../img/icons/benefits.svg);
			right: 0;
			top: 0px;
			width: 50px;
		}
	}

	h2.statistics {
		margin-bottom: 30px;
		position: relative;

		&:before {
			position: absolute;
			content: url(../img/icons/statistics.svg);
			right: 0;
			top: 0px;
			width: 50px;
		}
	}

	.statistics-content {
		padding: 30px;
		background-color: $blue;
		color: $white;

		h2 {
			color: $white;
		}

		ul {
			li {
				color: #fff;

				&:before {
					color: #fff;
				}

				&.time,
				&.temp,
				&.files,
				&.check,
				&.tags,
				&.clock,
				&.bsi,
				&.edana,
				&.meltblown,
				&.print-colors,
				&.secondary-process,
				&.beam-area,
				&.extrusion,
				&.weave-holds,
				&.weave-covers {
					padding-left: 40px;
				}

				&.time:before,
				&.temp:before,
				&.files:before,
				&.check:before,
				&.tags:before,
				&.clock:before,
				&.bsi:before,
				&.edana:before,
				&.meltblown:before,
				&.print-colors:before,
				&.secondary-process:before,
				&.beam-area:before,
				&.extrusion:before,
				&.weave-holds:before,
				&.weave-covers:before {
					position: absolute;
					left: 0;
					top: 0px;
					width: 25px;
				}

				&.time:before {
					content: url(../img/icons/time.svg);
				}

				&.temp:before {
					content: url(../img/icons/temperature.svg);
				}

				&.files:before {
					content: url(../img/icons/doc.svg);
				}

				&.check:before {
					content: url(../img/icons/test.svg);
				}

				&.tags:before {
					content: url(../img/icons/palette.svg);
				}

				&.clock:before {
					content: url(../img/icons/alarm.svg);
				}

				&.bsi:before {
					content: url(../img/icons/bsi.svg);
				}

				&.edana:before {
					content: url(../img/icons/edana.svg);
				}

				&.meltblown:before {
					content: url(../img/icons/meltblown.svg);
				}

				&.print-colors:before {
					content: url(../img/icons/print-colors.svg);
				}

				&.secondary-process:before {
					content: url(../img/icons/secondary-process.svg);
				}

				&.beam-area:before {
					content: url(../img/icons/beam-area.svg);
				}

				&.extrusion:before {
					content: url(../img/icons/extrusion.svg);
				}

				&.weave-covers:before {
					content: url(../img/icons/weave-covers.svg);
				}

				&.weave-holds:before {
					content: url(../img/icons/weave-holds.svg);
				}
			}
		}

		.statistics {
			color: $white;
		}
	}
}

.sub-nav {
	color: $white;
	background: $blue url(../img/patterns/pattern-6.png) 0 0 repeat;
	left: 0;
	right: 0;
	top: auto;
	padding: 15px 50px 5px 4%;
	position: relative;
	display: block;
	z-index: 100;

	@media only screen and (max-width: 1600px) {
		padding: 15px 40px 5px 3%;
	}

	@media only screen and (max-width: 991px) {
		padding: 15px 30px 5px 30px;
	}

	@media only screen and (max-width: 767px) {
		padding: 10px 20px 10px 10px;
	}

	@media only screen and (max-width: 480px) {
		padding: 10px 10px 10px 0;
	}

	.icon.icon-share {
		svg {
			width: 28px;
			height: 28px;
			fill: $white;

			@media only screen and (max-width: 991px) {
				width: 25px;
				height: 25px;
			}

			@media only screen and (max-width: 767px) {
				width: 20px;
				height: 20px;
			}
		}
	}

	.right-nav {
		list-style-type: none;
		padding: 0;
		margin: 0;
		margin: -3px 0 0 0;

		@media only screen and (max-width: 767px) {
			margin: 0;
		}

		.share {
			@media only screen and (max-width: 767px) {
				line-height: 1;
			}
		}

		li {
			a {
				font-size: 18px;
				font-family: donlowMedium, serif;

				@media only screen and (max-width: 991px) {
					font-size: 16px;
				}

				@media only screen and (max-width: 767px) {
					font-size: 14px;
				}

				&:hover {
					color: $white;
				}
			}
		}
	}

	.sub-nav-tab {
		position: relative;

		@media only screen and (max-width: 767px) {
			padding: 3px 0;
		}

		&:not(:last-child):after {
			position: absolute;
			content: "/";
			color: $white;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}

		&:nth-last-of-type(1):after {
			position: absolute;
			content: "";
			color: $white;
			right: 0;
			top: 0;
		}

		.dropdown-arrow {
			margin-left: 25px;
			display: table;
			float: left;

			@media only screen and (max-width: 991px) {
				margin-left: 20px;
			}

			@media only screen and (max-width: 767px) {
				margin-left: 5px;
				float: left;
				display: inline-block;
			}

			@media only screen and (max-width: 480px) {
				font-size: 10px;
				margin-top: 1px;
			}
		}

		.menu {
			display: table;
			font-family: donlowMedium, serif;
			font-size: 18px;
			padding: 0px 25px;

			@media only screen and (max-width: 991px) {
				font-size: 16px;
				padding: 0px 20px;
			}

			@media only screen and (max-width: 767px) {
				font-size: 12px;
				padding: 0px 10px;
			}

			&:hover {
				color: $white;
			}

			.dropdown-label {
				display: table;
				float: left;

				@media only screen and (max-width: 480px) {
					white-space: nowrap;
					max-width: 100px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: block;
					float: left;
				}
			}
		}
	}

	.dropdown-menu {
		border-radius: 0px;
		top: 35px;

		@media only screen and (max-width: 991px) {
			top: 32px;
		}

		@media only screen and (max-width: 767px) {
			top: 29px;
		}

		li {
			a {
				color: $blue;
				font-size: 16px;

				&:hover,
				&:focus {
					background-color: $blue;
					color: $white;
				}
			}
		}
	}

	&.fixed {
		position: fixed;
		top: 72px;
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
		z-index: 999;

		@media only screen and (max-width: 991px) {
			top: 60px;
		}
	}
}

.sub-nav + .container {
	> .inner-content {
		padding: 50px 0;

		@media only screen and (max-width: 1200px) {
			padding: 40px 0;
		}

		@media only screen and (max-width: 767px) {
			padding: 30px 0;
		}
	}
}

/*
.second-container{
	padding-bottom: 100px;
	.second-content{
		padding-top: 30px;
	}


}*/
[data-module="nav"][data-type="top_side"] .nav_cont {
	width: 50%;
	margin: 0 33%;
}

.fixed-nav {
	position: fixed;
	z-index: 998;
	top: -200px;
	width: 100%;
	background-color: #fff;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);

	


	&.show-fix {
		top: 0;
		transition: top 0.5s;
		transition-delay: top ease-in-out 0.3s;
	}

	.dashboard {
		margin: 20px 0 20px -22px;

		.calendar {
			a {
				display: table;
			}

			@media only screen and (max-width: 520px) {
				left: -5px;
			}
		}
	}

	[data-module="nav-button"][data-type="burger_cross"][data-bcross-layout="go_right"] {
		@media only screen and (max-width: 520px) {
			left: 0;
		}
	}

	[data-module="nav-button"][data-type="burger_cross"][data-bcross-layout="go_right"] .container_burger {
		@media only screen and (max-width: 520px) {
			left: 0;
		}
	}

	[data-module="nav-button"][data-type="burger_cross"][data-bcross-layout="go_right"] [data-container="cross"] {
		left: 15px;
	}

	.burger-menu {
		@media only screen and (max-width: 520px) {
			left: 0 !important;
			margin: 0 !important;
			width: 36px !important;

			.container_burger {
				left: 0 !important;
			}
		}
	}

	[data-module="nav-button"][data-type="burger_cross"][data-bcross-layout="go_right"]
		[data-container="cross"]
		[data-line="right"] {
		top: 50%;
	}

	[data-module="nav-button"][data-type="burger_cross"][data-bcross-layout="go_right"] [data-container="line"] div {
		background: $blue;
	}

	[data-module="nav-button"][data-type="burger_cross"][data-bcross-layout="go_right"] .container_burger {
		width: 0 !important;
		padding: 0 0 9px 0;
	}

	.motto {
		text-align: center;
		color: $blue;
		margin:20px 0 20px -22px;

		@media only screen and (max-width: 1400px) {
			font-size: 1rem;
		}

		@media only screen and (max-width: 1200px) {
			font-size: 0.9rem;
				margin:20px 0 20px -42px;
		}
	}

	.fixed-logo {
		display: block;
		margin: 0 auto -5px auto;

		.logo {
			display: table;
			margin: 10px auto;
		}

		svg {
			width: 50px;
			height: 50px;

			@media only screen and (max-width: 767px) {
				width: 40px;
				height: 40px;
			}
		}
	}

	.social-container {
		height: 100%;
		margin: 20px 0 0 0;

		@media only screen and (max-width: 520px) {
			margin: 10px 0 0 0;
		}
	}

	.social-content {
		float: right;
		padding-right: 40px;

		@media only screen and (max-width: 1600px) {
			padding-right: 30px;
		}

		@media only screen and (max-width: 1400px) {
			padding-right: 20px;
		}

		@media only screen and (max-width: 520px) {
			width: 120px;
			float: right;
			padding-right: 0px;
		}
	}

	.social a {
		color: $blue;
		display: block;
		float: left;
		width: 26px;
		height: 26px;
		line-height: 26px;
		margin: 0 0 0 10px;
		padding: 0;
		font-size: 20px;
		align-items: center;
		position: relative;
		text-align: center;
		&:hover {
			color: #8e8e8e;
		}
		svg {
			height: 22px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			fill: #00154d;
			&:hover {
				fill: #8e8e8e;
			}
		}

		@media only screen and (max-width: 1600px) {
			margin: 0 0 0 5px;
		}

		@media only screen and (max-width: 1200px) {
			width: 25px;
			height: 25px;
			line-height: 25px;
			margin: 0 0 0 3px;
		}

		&.search-button,
		&.mail {
			@media only screen and (max-width: 1200px) {
				margin: 1px 0 0 3px;
			}
		}
	}
}

.container-full {
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;

	img {
		width: 100% !important;
	}
}

.category-details {
	h1 {
		font-size: 42px;
	}

	h2 {
		font-size: 32px;
		margin-bottom: 50px;

		@media screen and (max-width: 767px) {
			font-size: 26px;
			margin-bottom: 20px;
		}
	}
}

#main {
	width: 100%;
}

.more {
	display: table;
	padding: 10px 15px;
	background: $white;
	color: $blue;
	border: 1px solid $blue;
	margin: 0 auto;

	&:hover {
		background: $blue;
		color: $white;
	}
}

.narrow {
	.inner-content {
		margin-left: auto;
		margin-right: auto;
		padding-left: 30px;
		padding-right: 30px;

		@media (min-width: 1200px) {
			max-width: 1140px;
		}

		@media (max-width: 992px) {
			max-width: 940px;
		}

		@media (max-width: 768px) {
			max-width: 720px;
			padding-left: 15px;
			padding-right: 15px;
		}

		@media (max-width: 544px) {
			max-width: 576px;
		}
	}
}

.page_content {
	padding-top: 150px;

	@media (max-width: 991px) {
		padding-top: 110px;
	}

	@media (max-width: 767px) {
		padding-top: 60px;
	}
}

.main-markets {
	color: $white;
	padding: 40px 0 40px;
	background: $blue url(../img/patterns/pattern-6.png) 0 0 repeat;

	h3 {
		font-size: 18px;
		letter-spacing: 12px;
		margin-bottom: 40px;
		text-align: center;
		color: $white;

		@media (max-width: 767px) {
			padding: 0 15px;
		}
	}

	svg {
		fill: $white;
		height: 140px;
		width: auto;

		&.artificial-grass-svg-icon {
			height: 150px;
		}
	}

	.explore {
		position: absolute;
		opacity: 0;
		bottom: -30px;
		width: 100%;
		display: block;
		visibility: hidden;
		font-size: 16px;
		text-transform: lowercase;
		-moz-transition: all 0.15s;
		-o-transition: all 0.15s;
		-webkit-transition: all 0.15s;
		transition: all 0.15s;
	}

	.nonwovens,
	.artificial-grass,
	.textiles {
		position: relative;
		display: table;
		margin: 10px auto;

		&:hover {
			.explore {
				opacity: 1;
				visibility: visible;
			}

			.cat-title {
				-webkit-transform: translate3d(0, -100%, 0);
				transform: translate3d(0, -100%, 0);
			}
		}
	}

	.geotextiles {
		position: relative;
		display: table;
		margin: 10px auto;

		&:hover {
			.explore {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	.cat-title {
		overflow: hidden;
		display: block;

		i {
			text-align: center;
			margin: 0 auto;
			display: block;
			position: relative;
			font-style: normal;
			font-size: 23px;
			-webkit-transition: -webkit-transform 0.3s;
			transition: transform 0.3s;
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
			will-change: transform;

			@media (min-width: 1200px) and (max-width: 1320px) {
				font-size: 20px;
			}

			&:before {
				position: absolute;
				top: 100%;
				content: attr(data-hover);
				-webkit-transform: translate3d(0, 0, 0) translateX(-50%);
				transform: translate3d(0, 0, 0) translateX(-50%);
				left: 50%;
				width: 100%;
			}
		}
	}

	.hover {
		.cat-title i {
			-webkit-transform: translate3d(0, -100%, 0);
			transform: translate3d(0, -100%, 0);
		}
	}
}

.custom-col-offset {
	margin-left: 12.5%;

	@media only screen and (max-width: 1200px) {
		margin-left: 0;
	}
}

.full-box-link {
	padding: 30px;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0px;
	z-index: 1;

	.hov-line {
		position: absolute;
		background: $white;
	}

	.hov-line-1 {
		height: 2px;
		width: 0%;
		right: 0;
		top: 0;
		-webkit-transition: width 0.15s linear;
		transition: width 0.15s linear;
		-webkit-transition-delay: 0s;
		transition-delay: 0s;
	}

	.hov-line-2 {
		height: 0%;
		width: 2px;
		right: 0;
		bottom: 0;
		-webkit-transition: height 0.15s linear;
		transition: height 0.15s linear;
		-webkit-transition-delay: 0.15s;
		transition-delay: 0.15s;
	}

	.hov-line-3 {
		height: 2px;
		width: 0%;
		right: 0;
		bottom: 0;
		-webkit-transition: width 0.15s linear;
		transition: width 0.15s linear;
		-webkit-transition-delay: 0.15s;
		transition-delay: 0.15s;
	}

	.hov-line-4 {
		height: 0%;
		width: 2px;
		left: 0;
		bottom: 0;
		-webkit-transition: height 0.15s linear;
		transition: height 0.15s linear;
		-webkit-transition-delay: 0s;
		transition-delay: 0s;
	}

	&:hover {
		.hov-line-1 {
			-webkit-transition-delay: 0.15s;
			transition-delay: 0.15s;
			width: 100%;
		}

		.hov-line-2 {
			-webkit-transition-delay: 0s;
			transition-delay: 0s;
			height: 100%;
		}

		.hov-line-3 {
			-webkit-transition-delay: 0s;
			transition-delay: 0s;
			width: 100%;
		}

		.hov-line-4 {
			-webkit-transition-delay: 0.15s;
			transition-delay: 0.15s;
			height: 100%;
		}
	}
}

.way-point-animate {
	opacity: 0;
	transform: translateY(20px);

	&.shown {
		transform: translateY(0px);
		opacity: 1;
		transition: all 1s;
	}
}

.page-title {
	position: absolute;
	left: 0;
	top: 0;
	font-size: 82px;
	line-height: 1;
	color: #ededed;
	letter-spacing: 20px;
	font-family: donlowMedium, serif;
	-webkit-transform: rotate(180deg) translateX(-50%);
	-ms-transform: rotate(180deg) translateX(-50%);
	transform: rotate(-90deg) translate(-100%, -100%);
	-webkit-transform-origin: left;
	-ms-transform-origin: left;
	transform-origin: left;

	@media only screen and (max-width: 1600px) {
		-ms-transform: rotate(180deg) translateX(-20%);
		-webkit-transform: rotate(180deg) translateX(-20%);
		transform: rotate(180deg) translateX(-20%);
		left: -130px;
	}

	@media only screen and (max-width: 1199px) {
		display: none;
	}
}

.stats {
	padding: 100px 0;
	background: $blue url(../img/patterns/pattern-6.png) 0 0 repeat;
	color: $white;

	@media only screen and (max-width: 991px) {
		padding: 70px 0;
	}

	@media only screen and (max-width: 767px) {
		padding: 50px 0;
	}

	.stat-item {
		min-height: 190px;
		display: block;

		&:hover {
			color: #ffffff;
		}

		@media only screen and (max-width: 767px) {
			margin-bottom: 30px;
		}

		.full-box-link {
			&:before {
				width: 0px;
				height: 0px;
				position: absolute;
				top: -2px;
				right: -2px;
				content: "";
				visibility: hidden;
				border-right: 2px solid $white;
				border-top: 2px solid $white;
				-moz-transition: all 0.3s;
				-o-transition: all 0.3s;
				-webkit-transition: all 0.3s;
				transition: all 0.3s;

				@media only screen and (max-width: 767px) {
					right: 15px;
				}
			}

			&:after {
				visibility: hidden;
				width: 0px;
				height: 0px;
				position: absolute;
				bottom: -2px;
				left: -2px;
				content: "";
				border-left: 2px solid $white;
				border-bottom: 2px solid $white;
				-moz-transition: all 0.3s;
				-o-transition: all 0.3s;
				-webkit-transition: all 0.3s;
				transition: all 0.3s;

				@media only screen and (max-width: 767px) {
					left: 15px;
				}
			}

			&:hover:before {
				visibility: visible;
				width: 30px;
				height: 30px;
			}

			&:hover:after {
				visibility: visible;
				width: 30px;
				height: 30px;
			}
		}

		&:before {
			position: absolute;
			content: "";
			width: 0px;
			height: 0px;
			top: -2px;
			left: -2px;
			visibility: hidden;
			border-left: 2px solid $white;
			border-top: 2px solid $white;
			-moz-transition: all 0.3s;
			-o-transition: all 0.3s;
			-webkit-transition: all 0.3s;
			transition: all 0.3s;

			@media only screen and (max-width: 767px) {
				left: 15px;
			}
		}

		&:after {
			position: absolute;
			content: "";
			width: 0px;
			height: 0px;
			right: -2px;
			bottom: -2px;
			visibility: hidden;
			border-right: 2px solid $white;
			border-bottom: 2px solid $white;
			-moz-transition: all 0.3s;
			-o-transition: all 0.3s;
			-webkit-transition: all 0.3s;
			transition: all 0.3s;

			@media only screen and (max-width: 767px) {
				right: 15px;
			}
		}

		&:hover:before {
			visibility: visible;
			width: 30px;
			height: 30px;
		}

		&:hover:after {
			visibility: visible;
			width: 30px;
			height: 30px;
		}

		.explore {
			position: absolute;
			content: "";
			display: table;
			bottom: -50px;
			left: 50%;
			transform: translateX(-50%);
			margin: 0 auto;
			opacity: 0;
			-moz-transition: all 0.3s;
			-o-transition: all 0.3s;
			-webkit-transition: all 0.3s;
			transition: all 0.3s;

			@media only screen and (max-width: 767px) {
				display: none;
			}
		}

		&:hover {
			.explore {
				opacity: 1;
			}
		}
	}

	.animated {
		animation-duration: 1s;
		animation-fill-mode: both;
		font-size: 80px;

		@media only screen and (max-width: 1200px) {
			font-size: 70px;
		}

		@media only screen and (max-width: 991px) {
			font-size: 60px;
		}

		@media only screen and (max-width: 767px) {
			font-size: 50px;
		}
	}

	.title-top {
		color: $grey;
		font-size: 12px;
		font-family: donlowMedium, serif;
	}

	.title-bottom {
		font-size: 16px;
		letter-spacing: 3px;

		@media only screen and (max-width: 1200px) {
			font-size: 14px;
			letter-spacing: 1.5px;
		}

		@media only screen and (max-width: 767px) {
			font-size: 12px;
		}
	}
}

.statistics {
	.stats {
		padding-top: 0;
		padding-bottom: 100px;
		max-width: 1100px;
		margin: 0 auto;
		background: none;

		@media only screen and (max-width: 767px) {
			padding-bottom: 50px;
		}

		* {
			color: $blue;
		}

		.stat-item {
			@media only screen and (max-width: 767px) {
				min-height: 100px;
			}

			&:before {
				border-left: 2px solid $blue;
				border-top: 2px solid $blue;
			}

			&:after {
				border-right: 2px solid $blue;
				border-bottom: 2px solid $blue;
			}

			.animated {
				font-size: 65px;

				@media only screen and (max-width: 991px) {
					font-size: 55px;
				}

				@media only screen and (max-width: 767px) {
					font-size: 45px;
				}

				@media only screen and (max-width: 480px) {
					font-size: 35px;
				}
			}

			.title-bottom {
				letter-spacing: 2px;
			}

			.full-box-link {
				&:before {
					border-right: 2px solid $blue;
					border-top: 2px solid $blue;
				}

				&:after {
					border-left: 2px solid $blue;
					border-bottom: 2px solid $blue;
				}
			}
		}
	}
}

[data-module="inclusion"][data-type="even_odd"] {
	.col-right {
		.col-cont {
			@media only screen and (max-width: 767px) {
				float: inherit;
			}
		}
	}
}

.news-grid {
	padding: 100px 0;

	.content-grid {
		.content {
			.row {
				position: relative;
			}

			.news-date {
				color: $black;
				display: block;
				margin: 15px 0;
			}

			.btn-content {
				float: right;
			}

			.grid-category {
				float: left;
				position: relative;
				padding-left: 30px;
				line-height: 1;
				color: $blue;
			}
		}
	}
}

.cube {
	content: "";
	position: absolute;
	background: #fff;
	left: calc(50% - 20px);
	width: 40px;
	top: 40px;
	transform: rotate(45deg);
	z-index: 2;
	height: 40px;

	@media only screen and (max-width: 991px) {
		left: 50%;
		-ms-transform: rotate(45deg) translateX(-50%);
		transform: rotate(45deg) translateX(-50%);
		top: 0;
		display: block !important;
		margin: 0;
		margin-top: -5px;
	}
}

[data-module="inclusion"][data-type="even_odd"] .col-left,
[data-module="inclusion"][data-type="even_odd"] .col-left .col-cont,
[data-module="inclusion"][data-type="even_odd"] .col-right,
[data-module="inclusion"][data-type="even_odd"] .col-right .col-cont {
	height: auto !important;
	max-height: 440px !important;
	min-height: 440px !important;

	@media only screen and (max-width: 991px) {
		max-height: 400px !important;
		min-height: 400px !important;
	}
}

[data-module="inclusion"][data-type="even_odd"] .col-img img.img_vert,
[data-module="inclusion"][data-type="even_odd"] .col-img img.img_vert {
	max-height: 440px !important;
}

.cube.event {
	background: $orange;
}

.col-right .text-content,
.col-right .title.text_cover {
	text-align: left !important;
}

.col-left .text-content,
.col-left .title.text_cover {
	text-align: left !important;
}

.article-container {
	.title.text_cover {
		font-family: donlowBold, serif;
		color: $blue;
		font-size: 20px;
	}

	.text-content {
		color: $black;
		font-size: 18px;
	}

	.label-btn {
		padding: 0 !important;
		font-family: donlowBold, serif;
	}
}

.newsletter {
	padding: 25px 0;
	background: $blue url(../img/patterns/pattern-6.png) 0 0 repeat;
	color: $white;

	.newsletter-form {
		.close {
			color: #ffffff;
			opacity: 1;
			position: absolute;
			top: 20px;
			right: 20px;
		}
	}

	.newsletter-info {
		font-size: 18px;
		text-align: center;
		color: $white;
		padding: 0 10px;

		@media only screen and (max-width: 991px) {
			font-size: 16px;
		}

		@media only screen and (max-width: 767px) {
			font-size: 14px;
		}
	}

	.headline {
		font-size: 18px;
		text-align: center;
		color: $white;
		font-family: donlowBold, serif;
	}

	.newsletter-info {
		display: table;
		margin: 0 auto;
	}

	.newsletter-content {
		padding: 29px;
		margin: 0 auto;
		display: block;
		text-align: center;

		@media only screen and (max-width: 767px) {
			padding: 0;
		}
	}

	.form-control,
	output {
		font-size: 14px;
		line-height: 1.42857143;
		color: $white;
	}

	input:not([type]),
	input[type="text"],
	input[type="password"],
	input[type="email"] {
		background-color: transparent;
		border: none;
		position: relative;
		border-bottom: 1px solid $white;
		border-radius: 0;
		outline: none;
		height: 36px;
		width: 100%;
		max-width: 260px;
		font-size: 21px;
		margin: 0 20px;
		padding: 0;
		box-shadow: none;
		box-sizing: content-box;
		transition: all 0.3s;

		@media only screen and (max-width: 650px) {
			margin: 0 auto 20px auto;
		}
	}

	.label {
		padding: 0;
		position: relative;
		top: 10px;

		svg {
			fill: $white;
			width: 30px;
			height: 30px;
		}
	}

	input,
	.label,
	.btn-primary.submit-button {
		display: inline-block;
	}

	.btn-primary.submit-button {
		border-color: $white;
		border-radius: 0px;
		font-size: 16px;
		background-color: transparent;
		color: $white;
		-moz-transition: all 0.15s;
		-o-transition: all 0.15s;
		-webkit-transition: all 0.15s;
		transition: all 0.15s;
		padding: 10px 25px;

		@media only screen and (max-width: 650px) {
			font-size: 16px;
		}

		&:hover {
			background-color: $white;
			color: $blue;
		}
	}
}

.submit-cv-modal {
	padding: 25px 0;
	background: $blue url(../img/patterns/pattern-6.png) 0 0 repeat;
	color: $white;

	.close {
		position: absolute;
		top: -18px;
		right: 10px;
		line-height: 1;
		color: #fff;
		opacity: 1;

		&:hover {
			opacity: 0.5;
		}
	}

	a:hover {
		color: #fff !important;
		text-decoration: underline !important;
	}

	.submit-cv-info {
		font-size: 18px;
		text-align: center;
		color: $white;
	}

	.headline {
		font-size: 18px;
		text-align: center;
		color: $white;
		font-family: donlowBold, serif;
	}

	.newsletter-content {
		padding: 29px;
		margin: 0 auto;
		display: block;
		text-align: center;

		@media only screen and (max-width: 767px) {
			padding: 0 30px;
		}
	}

	.btn-primary.submit-button {
		border-color: $white;
		border-radius: 0px;
		font-size: 16px;
		background-color: transparent;
		color: $white;
		-moz-transition: all 0.15s;
		-o-transition: all 0.15s;
		-webkit-transition: all 0.15s;
		transition: all 0.15s;
		padding: 10px 25px;

		@media only screen and (max-width: 650px) {
			margin-top: 20px;
			font-size: 16px;
		}

		&:hover {
			background-color: $white;
			color: $blue;
		}
	}
}

footer {
	color: $black;
	padding: 30px 60px 5px;

	@media only screen and (max-width: 1199px) {
		padding: 25px 30px 5px;
	}

	@media only screen and (max-width: 767px) {
		padding: 20px 0px 5px;
	}

	.address {
		margin-bottom: 0;
		padding: 6px 0;
		font-size: 12px;

		@media only screen and (max-width: 1300px) {
			padding: 9px 0;
			line-height: 1.2;
		}

		@media only screen and (max-width: 767px) {
			text-align: center;
		}
	}

	.nav-legal,
	.footer-social {
		list-style-type: none;
	}

	.nav-legal {
		padding: 7px 0 7px 0px;
		//display: inline-block;

		@media only screen and (max-width: 1200px) {
			
		}

		@media only screen and (max-width: 991px) {
			text-align: center;
			margin: 10px auto 0;
			padding: 0;
		}

		@media only screen and (max-width: 767px) {
			justify-content: center;
		}

		.menu-item {
			border-left: 1px solid $black;
			display: inline-block;
			vertical-align: middle;
			margin-bottom: 5px;

			&:nth-child(1) {
				border-left: none;
			}

			a {
				display: table;
				color: $black;
				padding: 0 5px;
				font-size: 14px;

				@media only screen and (max-width: 1700px) {
				}

				@media only screen and (max-width: 1500px) {
					padding: 0 4px;
					font-size: 14px;
				}

				@media only screen and (max-width: 1300px) {
					padding: 0 3px;
					font-size: 14px;
				}

				@media only screen and (max-width: 1200px) {
					font-size: 14px;
					padding: 0 8px;
				}

				@media only screen and (max-width: 767px) {
					font-size: 14px;
					padding: 0 8px;
				}
			}

			@media only screen and (max-width: 767px) {
				&:nth-child(1) {
					border-left: none;
				}
			}
		}
	}

	.footer-social {
		padding-left: 0px;
		display: table;
		margin-left: auto;
			margin: 0 auto 0;
		@media only screen and (max-width: 1199px) {
		
		}

		@media only screen and (max-width: 767px) {
			margin: 10px auto;
			padding-left: 0px;
		}

		.social-item {
			display: inline-block;
			vertical-align: middle;

			a {
				display: flex;
				color: $black;
				padding: 0 15px;
				font-size: 24px;
				&:hover {
					color: #8e8e8e;
				}
				svg {
					fill: #00154d;
					&:hover {
						fill: #8e8e8e;
					}
				}

				@media only screen and (max-width: 1199px) {
					font-size: 20px;
					padding: 0 10px;
				}

				@media only screen and (max-width: 767px) {
					font-size: 18px;
				}
			}

			&:last-child{
				a {
					padding: 0 0px 0 15px;
				}
			}
		}
	}

	.tsh {
		a {
			display: table;
			margin-left: auto;
			@media only screen and (max-width: 1199px) {
			
			}

			@media only screen and (max-width: 767px) {
				float: none;
				margin: 0 auto;
				padding-right: 0px;
			}

			img {
				@media only screen and (max-width: 1199px) {
					width: 90px;
				}

				@media only screen and (max-width: 767px) {
					width: 80px;
				}
			}
		}
	}
}

/*CAREER*/
.career-block {
	background-size: cover;
	padding: 120px 0;
	background-position: center center;
	background-repeat: no-repeat;

	.career-link {
		display: table;
		margin: 0 auto;
		padding: 30px;
		background-color: rgba(0, 21, 77, 0.6);

		h4 {
			color: $white;
			font-size: 30px;
			text-align: center;
			margin-bottom: 15px;

			@media only screen and (max-width: 991px) {
				font-size: 26px;
			}

			@media only screen and (max-width: 767px) {
				font-size: 22px;
			}
		}

		a {
			color: $white;
			font-size: 30px;
			display: table;
			margin: 0 auto;
			padding: 10px 15px;
			text-decoration: none;
			border: 1px solid $white;

			@media only screen and (max-width: 991px) {
				font-size: 26px;
			}

			@media only screen and (max-width: 767px) {
				font-size: 22px;
			}

			@media only screen and (max-width: 480px) {
				font-size: 18px;
			}

			&:hover {
				background-color: $white;
				color: $blue;
			}
		}
	}
}

.custom-container {
	max-width: 100%;
	padding-left: 100px;
	padding-right: 70px;
	margin-right: auto;
	margin-left: auto;
	display: flex;

	@media only screen and (max-width: 767px) {
		padding-left: 15px;
		padding-right: 15px;
		margin: 15px auto;
	}

	.row {
		width: 100%;

		@media only screen and (max-width: 767px) {
			//width: auto;
		}
	}
}

.site-header {
	position: absolute;
	top: -165px;

	@media only screen and (max-width: 991px) {
		position: fixed !important;
		top: 0 !important;
		left: 0 !important;
		right: 0 !important;
		background: #fff !important;
		height: auto !important;
	}
}

.site-header.show-cont {
	position: absolute;
	top: 0;
	opacity: 1;
	padding-top: 65px;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;

	@media only screen and (max-width: 991px) {
		padding-top: 0;
	}
}

header,
.fixed-nav {
	.motto {
		font-family: donlowBold, serif;
		font-size: 1.2rem;
		height: 100%;
		white-space: nowrap;

		@media only screen and (max-width: 1440px) {
			font-size: 1rem;
		}

		@media only screen and (max-width: 1300px) {
			font-size: 0.9rem;
		}

		@media only screen and (max-width: 1200px) {
			font-size: 0.8rem;
		}

		.motto-sign {
			text-align: center;
			display: block;
		}
	}

	[data-module="nav-button"][data-type="burger_cross"][data-bcross-layout="go_right"] .container_burger {
		padding: 0 0 9px 0;
		left: 15px;
	}

	[data-module="nav-button"][data-type="burger_cross"] [data-container="line"] {
		height: 22px;
	}

	[data-module="nav-button"][data-type="burger_cross"][data-bcross-layout="go_right"] {
		position: relative;
		left: 15px;
		top: 0;
		padding: 0;
	}

	.social {
		.thrace-group {
			width: auto !important;
			height: auto;
			max-height: 30px;
			margin-top: 5px !important;

			@media only screen and (max-width: 1600px) {
				max-height: 28px;
				margin-top: 8px !important;
			}

			img {
				width: 140px;

				@media only screen and (max-width: 1600px) {
					width: 90px;
				}

				@media only screen and (max-width: 1200px) {
					width: 80px;
				}
			}
		}

		.mail {
			display: table !important;
			padding: 3px !important;
			line-height: 3px !important;

			svg {
				fill: $blue;
				max-width: 26px;
				max-height: 26px;

				&:hover {
					fill: $grey_2;
				}
			}
		}
	}

	.calendar {
		float: left;
		position: relative;
		cursor: pointer;

		a {
			display: block;
			line-height: 1;
			padding-bottom: 2px;
		}

		svg {
			fill: $blue;
			width: 31px;
			height: 25px;

			&:hover {
				fill: $grey_2;
			}
		}
	}
}

header {
	&[data-module="header"][data-type="with_text"] {
		.calendar,
		.mail,
		.burger-menu,
		.search-button {
			@media only screen and (max-width: 991px) {
				margin-top: 5px !important;
			}
		}

		.mobile-logo {
			a {
				@media only screen and (max-width: 991px) {
					display: table;
					margin: -5px auto 0;
				}
			}

			svg {
				height: 45px;
				width: auto;
				display: block;
			}
		}

		.social {
			a {
				position: relative;

				svg {
					height: 22px;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}

				@media only screen and (max-width: 1200px) {
					width: 22px;
					height: 22px;
					line-height: 22px;
					margin: 0 0 0 3px;
				}

				&.search-button,
				&.mail {
					@media only screen and (max-width: 1200px) {
						margin: 1px 0 0 3px;
					}
				}
			}
		}

		.burger-menu {
			@media only screen and (max-width: 991px) {
				float: right;
			}
		}

		.mobile {
			display: none;
			float: left;
			padding: 0 4px;

			@media only screen and (max-width: 991px) {
				display: table;
			}

			&.search-button {
				float: right;
				color: $blue;
				font-size: 25px;
				margin-right: -11px;
			}

			&.mail {
				position: relative;
				margin-left: 5px;

				span {
					height: 30px;
					margin-top: -4px !important;
					position: relative;
					display: table;

					svg {
						fill: $blue;
						width: 34px;
					}
				}
			}
		}
	}

	.custom-container {
		padding-left: 100px;
		padding-right: 20px;

		@media only screen and (max-width: 1400px) {
			padding-left: 50px;
			padding-right: 0px;
		}

		@media only screen and (max-width: 1200px) {
			padding-left: 30px;
			padding-right: 0;
		}
	}
}

.cookie-notice {
	opacity: 1;
	position: fixed;
	left: 0px;
	top: auto;
	bottom: 0px;
	right: auto;
	width: 100%;
	height: auto;
	padding: 10px;
	display: block;
	z-index: 100000;
	background: #ccc;

	button#cookies_btn {
		width: 25%;
		float: right;
		padding: 8px;
		border-radius: 30px;
		width: 30px;
		height: 30px;
		border: 0px;
		padding-top: 4px;
		background: #282828;
		color: #fff;
		font-family: "donlowLight", sans-serif;
	}

	button#cookies_btn:hover {
		background: #000;
		color: #737577;
	}

	#cookies_text {
		width: 70%;
		float: left;
		font-family: "donlowLight", serif;
		color: #282828;
		padding-top: 3px;
	}

	#cookies_text a {
		color: #282828;
		text-decoration: underline;
	}

	padding: 15px 4%;
	border: 1px solid rgba(204, 204, 204, 0.8);
	background: #fff;

	#cookies_text {
		line-height: 20px;
		font-family: "donlowLight", sans-serif;
		color: #282828;
		padding-top: 5px;
		font-size: 11px;
		font-size: 0.6875em;
		font-size: 0.6875rem;
	}

	#cookies_text a {
		color: #282828;
		text-decoration: underline;
	}

	button#cookies_btn {
		position: absolute;
		top: 50%;
		right: 4%;
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		transform: translateY(-50%);
		width: auto;
		border-radius: 0;
		color: #282828;
		border: 2px solid rgba(180, 166, 157, 0.5);
		background: none;
		line-height: 30px;
		height: 30px;
		padding: 0 35px;
		text-transform: uppercase;
		font-size: 9px;
		font-size: 0.5625em;
		font-size: 0.5625rem;
		font-weight: bold;
		letter-spacing: 1px;
	}

	button#cookies_btn:hover {
		background: none;
		border: 2px solid rgba(180, 166, 157, 1);
		color: #282828;
	}
}

[data-module="header"][data-type="with_text"] .social {
	padding: 0 10px;

	@media only screen and (max-width: 1800px) {
		padding: 0px;
	}
}

[data-module="inclusion"][data-type="even_odd"] .col-img span {
	background-color: $blue;
}

#modalSecondary {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	height: 100%;
	background: rgba(255, 255, 255, 0.9);
}

#modal {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	height: 100%;
	background: rgba(0, 21, 77, 0.3);

	.modal-lg {
		max-width: 1390px;
		width: 100%;
	}

	.modal-body {
		display: table;
		padding: 0px;
	}

	.modal-content {
		border-radius: 0;
		border: none;

		.header {
			display: table;
			width: 70px;
			float: left;

			button.close {
				position: absolute;
				bottom: 20px;
				left: 15px;
				opacity: 1;

				.icon.icon-close {
					padding: 10px;
					display: block;
					background: $blue;

					svg {
						fill: $white;
						width: 15px;
						height: 15px;
					}
				}
			}

			.title {
				-ms-transform: rotate(180deg);
				-webkit-transform: rotate(180deg);
				transform: rotate(180deg);
				-webkit-writing-mode: vertical-lr;
				-moz-writing-mode: vertical-lr;
				-ms-writing-mode: vertical-lr;
				writing-mode: vertical-lr;
				-webkit-text-orientation: sideways;
				-moz-text-orientation: sideways;
				-ms-text-orientation: sideways;
				text-orientation: sideways;
				position: absolute;
				top: 10px;
				left: 15px;
				font-size: 30px;
				color: $grey;
			}

			.dashboard {
				display: table;
				margin-top: 220px;
				margin-left: 20px;

				svg {
					fill: $blue;
					width: 25px;
					height: 25px;
				}
			}
		}

		.col-1 {
			display: table;
			width: 70%;
			float: left;
			position: relative;

			@media only screen and (max-width: 991px) {
				width: 60%;
			}

			@media only screen and (max-width: 767px) {
				width: 100%;
			}
		}

		.col-2 {
			display: table;
			width: calc(100% - 70px);
			float: right;
		}

		.col-3 {
			display: table;
			width: 30%;
			float: left;

			@media only screen and (max-width: 991px) {
				width: 40%;
			}

			@media only screen and (max-width: 767px) {
				width: 100%;
			}
		}

		.news {
			display: table;

			.item {
				position: relative;

				.post-title {
					position: absolute;
					bottom: 15px;
					width: 80%;
					padding: 10px 15px;
					background: $white;
					color: $blue;

					&:after {
					}
				}
			}
		}

		.products {
			display: table;

			.item {
				position: relative;
				display: block;
			}

			.prod-info {
				position: absolute;
				bottom: 15px;
				width: 80%;
				padding: 10px 15px;
				background: $white;
				color: $blue;

				&:after {
					content: "";
					position: absolute;
					right: -30px;
					width: 30px;
					top: 0;
					height: 100%;
					background: $blue;
				}
			}
		}

		.block-calendar {
			display: block;
			position: relative;
			max-height: 360px;

			@media only screen and (max-width: 1200px) {
				position: initial;
			}

			@media only screen and (max-width: 767px) {
				position: relative;
			}

			.calendar-controller {
				position: absolute;
				opacity: 0;
				-webkit-transition: all 0.2s ease-in-out;
				-moz-transition: all 0.2s ease-in-out;
				-ms-transition: all 0.2s ease-in-out;
				-o-transition: all 0.2s ease-in-out;
				transition: all 0.2s ease-in-out;
				bottom: -43px;
				width: 100%;
				background: $orange;
				color: $white;
				z-index: -1;
				padding: 10px;

				@media only screen and (max-width: 1200px) {
					bottom: 0;
				}

				.date,
				.event,
				.hour {
					float: left;

					@media only screen and (max-width: 767px) {
						float: none;
					}
				}

				.date {
					display: table;
					margin-right: 50px;

					@media only screen and (max-width: 1200px) {
						margin-right: 40px;
					}

					@media only screen and (max-width: 991px) {
						margin-right: 30px;
					}

					@media only screen and (max-width: 767px) {
						margin: 0 auto;
					}

					.date-number {
						font-size: 40px;
						font-weight: 700;
						display: block;
						line-height: 35px;
					}

					.date-month {
						text-transform: uppercase;
						display: table;
						margin: 0 auto;
					}
				}

				.event {
					padding: 15px 0;
					margin-right: 50px;
					font-size: 20px;

					@media only screen and (max-width: 1200px) {
						margin-right: 40px;
					}

					@media only screen and (max-width: 991px) {
						margin-right: 0px;
						width: calc(100% - 73px);
					}

					@media only screen and (max-width: 767px) {
						font-size: 16px;
						padding: 15px;
						width: 100%;
						margin-right: 0px;
						text-align: center;
					}
				}

				.hour {
					padding: 15px 0;
					font-size: 20px;
				}

				.actions {
					padding: 15px 50px;
					float: right;
					position: relative;

					@media only screen and (max-width: 991px) {
						float: left;
						display: block;
						padding: 15px;
						width: 100%;
					}

					.read-more {
						display: inline-block;

						@media only screen and (max-width: 1200px) {
							font-size: 18px;
						}

						@media only screen and (max-width: 991px) {
							float: right;
						}

						@media only screen and (max-width: 767px) {
							font-size: 16px;
						}

						@media only screen and (max-width: 480px) {
							float: none;
							width: 100%;
							display: block;
						}
					}

					.save-date {
						margin-right: 50px;
						display: inline-block;

						@media only screen and (max-width: 1200px) {
							font-size: 18px;
							margin-right: 40px;
						}

						@media only screen and (max-width: 991px) {
							margin-right: 30px;
							float: left;
						}

						@media only screen and (max-width: 767px) {
							font-size: 16px;
						}

						@media only screen and (max-width: 480px) {
							float: none;
							width: 100%;
						}
					}
				}
			}
		}

		.social {
			position: relative;
			margin-top: 42px;
			background: $blue;

			@media only screen and (max-width: 1400px) {
				margin-top: 0;
			}

			.item {
				width: 33.33333%;
				display: block;
				position: relative;
				color: $white;
				float: left;
				background-color: $blue;

				@media only screen and (max-width: 480px) {
					width: 100%;
				}

				.full-box-link {
					z-index: 0;
				}

				.social-content {
					display: block;
					margin: 0 auto;
					padding: 45px;
					text-align: center;

					@media only screen and (max-width: 1600px) {
						padding: 50px 35px;
						font-size: 18px;
						min-height: 240px;
					}

					@media only screen and (max-width: 1400px) {
						padding: 56px 45px;
						font-size: 16px;
						margin-top: 0;
						min-height: 250px;
					}

					@media only screen and (max-width: 1300px) {
						padding: 25px 45px;
						font-size: 16px;
						min-height: 184px;
					}

					@media only screen and (max-width: 991px) {
						padding: 30px 25px;
						margin-top: 0px;
						min-height: auto;
					}

					@media only screen and (max-width: 767px) {
						padding: 20px 10px;
						font-size: 0.9rem;
					}
				}

				.twiiter-ico {
					position: absolute;
					right: 10px;
					top: 5px;
				}

				&:nth-child(2) {
					background-color: #5e7fde;
				}
			}
		}
	}
}

.fc-toolbar h2 {
	font-family: donlowThin, serif;
	text-transform: uppercase;
	font-size: 24px;
	padding-right: 11px;
	letter-spacing: 1px;

	@media only screen and (max-width: 991px) {
		padding-right: 0;
		font-size: 22px;
	}

	@media only screen and (max-width: 767px) {
		font-size: 18px;
	}

	@media only screen and (max-width: 480px) {
		font-size: 14px;
	}
}

.fc button {
	@media only screen and (max-width: 480px) {
		height: 1.4em;
	}

	@media only screen and (max-width: 480px) {
		height: 1.2em;
	}
}

.fc-unthemed {
	background: #ffffff;
	padding: 0px;
}

.fc-state-default {
	background: none;
	border: none;
	color: #333;
	text-shadow: none;
	box-shadow: none;

	&:hover {
		opacity: 0.5;
	}
}

.fc-row .fc-content-skeleton td,
.fc-row .fc-helper-skeleton td {
	position: relative;
}

.fc-day-grid-container {
	height: auto !important;
}

.fc-basic-view .fc-body .fc-row {
	height: 40px !important;
	min-height: 0;
}

.fc td,
.fc th {
	border-style: none;
	padding: 0 0;
}

.fc-toolbar.fc-header-toolbar {
	margin-bottom: 1em;
	padding: 10px 40px;
	margin: 0;

	@media only screen and (max-width: 767px) {
		padding: 10px 20px;
	}
}

.fc td.fc-today {
	border-style: none;
}

.fc-unthemed td.fc-today {
	background: none;
}

.fc-event,
.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
	position: relative;
	float: none;
	width: 30px;
	display: table;
	margin: 0 auto;
	border-radius: 0;
	text-align: center;
	font-size: 20px;
	line-height: 30px;

	&:hover {
		background: rgba(234, 131, 2, 0.5);
		color: $orange;
		border-radius: 0;
	}

	@media only screen and (max-width: 991px) {
		width: 35px;
		font-size: 18px;
		line-height: 35px;
	}

	@media only screen and (max-width: 767px) {
		width: 30px;
		font-size: 16px;
		line-height: 30px;
	}
}

.fc-event,
.fc-ltr .fc-basic-view .fc-today .fc-day-number {
	background: rgba(234, 131, 2, 0.5);
	color: $orange;
	border-radius: 0;

	&:hover {
		background: rgba(234, 131, 2, 0.5);
		color: $orange;
	}
}

.fc-event-container {
	.fc-event {
		background: none;
		color: $orange;
		border: none;
		height: 30px;
		top: -32px;
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-ms-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;

		.fc-content {
			height: 8px;
			margin-right: -2px;
			width: 8px;
			background: rgba(234, 131, 2, 0.5);
			color: $orange;
			float: right;
			right: 0;
			position: relative;
		}

		.fc-title {
			display: none;
		}

		&:hover {
			border: 1px solid $orange;
			cursor: pointer;
		}
	}
}

.fc-view-container {
	padding: 10px 0;
	border-top: 1px solid $grey;
	border-bottom: none;
	margin: 0 40px 0 40px;
}

.fc-day-header span {
	text-transform: uppercase;
	color: $blue;
}

.fc-day-number {
	pointer-events: none;
}

.fc-event .fc-day-number {
	position: relative;
	color: $grey;
	pointer-events: all;
	cursor: pointer;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.fc-event .fc-day-number:before {
	position: absolute;
	content: "";
	top: 11px;
	right: -3px;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: $orange;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.fc-event.active .fc-day-number,
.fc-event .fc-day-number:hover {
	background: $orange;
	color: #fff;
}

.fc-event.active .fc-day-number:before,
.fc-event .fc-day-number:hover:before {
	right: 5px;
	opacity: 0;
}

.fc-button-group {
	//display: none;
}

.fc-view-container {
	@media only screen and (max-width: 991px) {
		margin: 0 20px;
	}

	@media only screen and (max-width: 767px) {
		margin: 0 10px;
	}
}

.fc-prev-button.disable {
	opacity: 0;
	pointer-events: none;
}

.save-date {
	.add-to-calendar-checkbox ~ a {
		height: 0;
		padding-bottom: 10px;
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-ms-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;

		&:hover,
		&:focus {
			color: #282828;
		}
	}

	.add-to-calendar.create-calendar {
		label {
			display: none !important;
		}

		.add-to-calendar-checkbox ~ a:before {
			width: 16px;
			height: 16px;
			color: $white;
			display: inline-block;
			background-image: none !important;
			margin-right: 0.5em;
			display: inline-block;
			font: normal normal normal 14px/1 FontAwesome;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}

		.icon-google:before {
			content: "\f1a0" !important;
		}

		.icon-yahoo:before {
			content: "\f19e" !important;
		}

		.icon-ical:before {
			content: "\f133" !important;
		}

		.icon-outlook:before {
			content: "\f274" !important;
		}
	}

	&.open {
		.add-to-calendar-checkbox ~ a {
			display: block;
			height: 20px;
		}
	}
}

.my-class {
	position: absolute;
	bottom: 30px;
	left: 0;
	z-index: 2;
	width: 150px;
	background: #fff;
	padding: 0;

	@media only screen and (max-width: 1200px) {
		bottom: 20px;
	}

	.add-to-calendar-checkbox ~ a {
		padding-bottom: 10px;
		display: none !important;
		color: $black;
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-ms-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;

		@media only screen and (max-width: 767px) {
			padding-bottom: 2px;
		}

		&:hover,
		&:focus {
			color: $blue;
		}
	}

	label {
		display: none !important;
	}

	.add-to-calendar-checkbox ~ a:before {
		width: 16px;
		height: 16px;
		color: $black;
		display: inline-block;
		background-image: none !important;
		margin-right: 0.5em;
		display: inline-block;
		font: normal normal normal 14px/1 FontAwesome;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.icon-google:before {
		content: "\f1a0" !important;
	}

	.icon-yahoo:before {
		content: "\f19e" !important;
	}

	.icon-ical:before {
		content: "\f133" !important;
	}

	.icon-outlook:before {
		content: "\f274" !important;
	}
}

.atcb-link {
	color: $black;
	position: relative;

	@media only screen and (max-width: 1200px) {
		font-size: 12px;
		line-height: 0.8em;
	}

	&:hover,
	&:focus,
	&:active {
		text-decoration: none;
		color: $blue;
	}
}

.news-actions {
	.addtocalendar {
		position: relative;
	}
}

.open {
	.my-class {
		padding: 15px 0 0;

		.add-to-calendar-checkbox ~ a {
			display: block !important;
		}
	}
}

.widget-row {
	margin: 50px 0;
	padding: 0;

	@media only screen and (max-width: 991px) {
		margin: 40px 0;
	}

	@media only screen and (max-width: 768px) {
		margin: 30px 0;
	}

	h2 {
		margin-top: 0;
		margin-bottom: 50px;

		@media only screen and (max-width: 991px) {
			margin: 0 0 40px 0;
		}

		@media only screen and (max-width: 768px) {
			margin: 0 0 30px 0;
		}
	}

	+ .widget-row {
		margin-top: 0;
	}
}

.stc-logo {
	position: absolute !important;
	right: calc(3% + 45px);
	bottom: 55px;
	max-width: 100px;
	z-index: 100;
	@media only screen and (max-width: 1600px) {
		right: calc(3% + 62px);
	}

	@media only screen and (max-width: 1300px) {
		right: calc(3% + 34px);
	}

	@media only screen and (max-width: 1200px) {
		right: calc(3% + 50px);
		bottom: 40px;
		max-width: 70px;
	}

	@media only screen and (max-width: 991px) {
		right: calc(2% + 65px);
	}

	@media (max-width: 767px) {
		right: calc(0% + 10px);
		bottom: 20px;
		width: 50px;
		height: auto;
	}

	&.dlg-hero-logo {
		bottom: 170px;

		@media only screen and (max-width: 1200px) {
			bottom: 130px;
		}

		@media (max-width: 767px) {
			bottom: 85px;
		}
	}
}

.with-logo {
	.dl-broch {
		right: 172px;

		@media only screen and (max-width: 1300px) {
			right: 140px;
		}

		@media only screen and (max-width: 1200px) {
			right: 125px;
		}

		@media only screen and (max-width: 767px) {
			right: 50px;
			bottom: -6px;
		}

		// right: 50px;

		// @media only screen and (max-width: 1300px) {
		// 	right: 24px;
		// }
	
		// @media only screen and (max-width: 1200px) {
		// 	right: 53px;
		// }
	
		// @media only screen and (max-width: 767px) {
		// 	right: -3px;
		// }
	}
}

.dl-broch {
	position: absolute;
	margin: 30px;
	bottom: 0;
	right: 55px;
	z-index: 100;

	@media only screen and (max-width: 1200px) {
		right: 25px;
	}

	@media only screen and (max-width: 767px) {
		right: 10px;
		bottom: -6px;
	}

	.dl-broch-tooltip {
		display: table;
		position: relative;

		svg {
			fill: $blue;
			width: 100px;
			height: 100px;
		}

		.tooltip-label {
			position: absolute;
			top: 14px;
			right: 120px;
			margin-left: -60px;
			bottom: auto;
			width: auto;
			border: 1px solid rgba(255, 255, 255, 0.7);
			border-left: none;
			font-size: 20px;
			line-height: 1;
			padding: 15px;
			white-space: nowrap;
			color: #00154d;
			text-align: center;
			background: #fff;
			z-index: 1000;
			opacity: 0;
			-webkit-transition: all 0.2s ease-in-out;
			-moz-transition: all 0.2s ease-in-out;
			-ms-transition: all 0.2s ease-in-out;
			-o-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;

			&:after {
				content: "";
				width: 0;
				height: 0;
				border-top: 27px solid transparent;
				border-bottom: 25px solid transparent;
				border-left: 25px solid #fff;
				opacity: 1;
				right: -26px;
				top: -1px;
				position: absolute;
				z-index: 5;
			}

			@media only screen and (max-width: 991px) {
				display: none;
			}
		}

		&:hover {
			.tooltip-label {
				z-index: 1;
				opacity: 1;
			}
		}
	}
}

label.has-error {
	padding-right: 50px;
	color: #a94442;

	a {
		color: #a94442;
	}
}

@media print {
	.hidden-print,
	.no-print,
	.no-print * {
		display: none !important;
	}

	a[href]:after {
		content: initial;
	}
}

/*<--START COOKIEBAR***************/
#cookie-bar {
	background: $blue;
	border-top: 1px solid #fff;
	height: auto;
	color: #fff;
	padding: 10px 20px;
	font-size: 16px;
	line-height: 1;
	text-align: center;
	position: fixed;
	width: 100%;
	bottom: 0;
	&.fixed {
		position: relative;
		top: 0;
		left: 0;
		width: 100%;
	}

	&.fixed.bottom {
		bottom: 0;
		top: auto;
	}

	p {
		clear: both;
		margin: 0;
		padding: 0;
		margin-bottom: 15px;
	}

	a {
		color: #fff;
		display: inline-block;
		border-radius: 0px;
		text-decoration: none;
		padding: 5px 15px;
		margin-left: 8px;
		margin-bottom: 5px;
		border: $blue solid 1px;
		text-transform: uppercase;
	}

	.cb-policy,
	.cb-enable {
		background: #fff;
		border: 1px solid #fff;
		color: $blue;
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-ms-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;

		&:hover {
			background: $blue;
			color: #fff;
		}
	}

	.cb-policy {
	}

	.inline-cookie-link {
		text-transform: none;
		text-decoration: underline;
		padding: initial;
		margin-left: 0;
	}
}

.error-template {
	padding: 240px 15px 140px 15px;
	text-align: center;
	min-height: 75vh;

	.error-actions {
		margin-top: 15px;
		margin-bottom: 15px;

		.btn {
			border-color: $blue;
			border-radius: 0px;
			font-size: 16px;
			background-color: fff;
			color: $blue;
			-moz-transition: all 0.15s;
			-o-transition: all 0.15s;
			-webkit-transition: all 0.15s;
			transition: all 0.15s;
			padding: 10px 25px;

			@media only screen and (max-width: 650px) {
				font-size: 16px;
			}

			&:hover {
				background-color: $blue;
				color: $white;
			}
		}
	}
}

.col-lg-5 {
	@media (min-width: 1200px) {
		width: 20%;
	}
}

.col-lg-5-footer {
	@media (min-width: 1200px) {
		width: 41.6667%;
	}
}

.col-lg-offset-half {
	@media (min-width: 1200px) {
		margin-left: 12.5%;
	}
}

.nhs {
	pointer-events: none;
	cursor: default;
	width: auto !important;
	height: auto !important;
	position: relative;
	top: -10px;

	img {
		height: 40px;
	}

	@media (max-width: 1800px) {
		top: -5px;

		img {
			height: 35px;
		}
	}

	@media (max-width: 1300px) {
		top: 2px;

		img {
			height: 22px;
		}
	}

	@media (max-width: 1024px) {
		top: 2px;

		img {
			height: 20px;
		}
	}

	@media (max-width: 991px) {
		img {
			height: 32px;
		}
	}

	@media (max-width: 767px) {
		top: -10px;

		img {
			height: 32px;
		}
	}

	@media (max-width: 380px) {
		top: -2px;

		img {
			height: 22px;
		}
	}
}

.construction-logo {
	position: relative;
	width: 147px;
	height: 147.7px;

	img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
body.home [data-module="slider"][data-type="cover_multi"] .swiper-wrapper {
	@media (min-width: 992px) {
		padding: 0 0 0px !important;
	}
}

.hero {
	.made-in-uk {
		z-index: 99;
		position: absolute;
		top: 45px;
		right: 46px;
		width: 60px;
		@media only screen and (max-width: 1600px) {
			right: 62px;
		}
		@media only screen and (max-width: 1300px) {
			right: 34px;
		}
		@media only screen and (max-width: 1200px) {
			right: 50px;
		}
		@media only screen and (max-width: 991px) {
			right: 65px;
		}
		@media only screen and (max-width: 767px) {
			width: 40px;
			top: 25px;
			right: 10px;
		}
	}
}

.twitter-svg {
	vertical-align: middle;
}

.contact-info {
	a {
		svg {
			&:hover {
				fill: #8e8e8e;
			}
		}
	}
}

.construction-details {
	position: absolute;
	z-index: 100;
	top: 45px;
	right: 172px;

	@media only screen and (max-width: 767px){
		right: 60px; 
		top: 25px;
		.hotline-box{
			min-height: 40px !important;
			padding: 5px 10px !important;

		}

	}
}

