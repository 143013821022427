.category-description,
.inner-content {
  p {
    margin-bottom: 30px;
    @media screen and (max-width: 991px) {
      margin: 0 0 25px;
    }
    @media screen and (max-width: 767px) {
      margin: 0 0 15px;
    }
  }

}

.video {
  .product-video {
    margin: 0 0 50px 0;
  }
}

.heros.products-hero {
  .title.app {
    h1 {
      max-width: 1650px;
      @media screen and (max-width: 767px) {
        text-align: center;
        padding: 0;
      }
    }
  }
  .hero-title {
    svg {
      height: 50px;
      width: auto;
      max-width: 370px;
      @media screen and (max-width: 767px) {
        height: 30px;
      }
      .dlg-end1, .dlg-end3, .dlg-end4,
      .dlg-en1, .dlg-en3, .dlg-en4,
      .dlg-ed1, .dlg-ed3,
      .dlg-gd1, .dlg-gd3 {
        fill: #ffffff;
      }
    }
  }
}

.benefits-list {
    padding-top: 50px;
    padding-bottom: 50px;
    h3{
      font-family: 'donlowBold',serif;
      padding-bottom: 20px;
      display: block;
    }
	@media screen and (max-width: 991px) {
		padding-top: 40px;
  		padding-bottom: 40px;
		hr{
			display: none;
		}
	}
	@media screen and (max-width: 767px) {
		padding-top: 30px;
  		padding-bottom: 30px;
	}
  .benefit-item {
    margin-bottom: 30px;
	@media screen and (max-width: 991px) {
		margin-bottom: 25px;
	}
	@media screen and (max-width: 767px) {
		margin-bottom: 20px;
	}
    .icon {
      display: table;
      margin: 0 auto;
      svg {
        width: auto;
        height: 40px;
        fill: $blue;
      }
    }
    .benefit-description {
      max-width: 250px;
      display: table;
      margin: 0 auto;
      text-align: center;
      color: $blue;

    }
  }
}

.prod-title {
  color: $light_blue;
  float: left;
}

.prod-sec-title {
  color: $dark_blue;
  font-weight: bold;
}

.prod-cat-title {
  display: block;
}
.category-details {

  .overview {
    padding-top: 50px;
    @media screen and (max-width: 767px) {
      padding-top: 30px;
    }
  }
}

.related-applications {
	h3 {
		font-family: donlowBold, serif;
	}
	.content{
		>.row{
			margin: 0 0 40px;
			@media screen and (max-width: 1200px) {
				margin: 0 0 30px;
			}
			@media screen and (max-width: 991px) {
				margin: 0 0 20px;
			}
		}	
	}	
	p{
		font-size:18px;
		@media screen and (max-width: 767px) {
			font-size: 16px;
		}
	}
	
	&.related-children-applications{
		h3{
		  	font-size: 20px;
			@media screen and (max-width: 991px) {
				font-size: 18px;
			}
			@media screen and (max-width: 767px) {
				font-size: 16px;
			}
		}
	}
	
	.col {
		overflow: hidden;
		padding: 0;
		.img_vert{
			width: 100%;
		}
		&.col-text{
			padding: 0 20px;
			@media screen and (max-width: 767px) {
				padding: 0 15px;
			}
		}
	}
	.cube {
		left: auto;
		right: -20px;
		z-index: 2;
		height: 40px;		
		@media screen and (max-width: 1199px) {
			right: auto;
			height: 30px;
			width: 30px;
			bottom: -15px;
			top: auto;
			left: calc(50% - 15px);
			transform: rotate(45deg) translateX(0%);
		}			
	}

	.pull-right {
		.cube {
			left: -20px;
			right: auto;
			@media screen and (max-width: 1199px) {
				right: auto;
				height: 30px;
				width: 30px;
				bottom: -15px;
				top: auto;
				left: calc(50% - 15px);
				transform: rotate(45deg) translateX(0%);
			}	
		}
	}

	.arrow {
		right: 10px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		color: $white;
	}

	.text-content {
		float: left !important;
		text-align: left;
	}
	.btn-content {
		float: left !important;
		.label-btn {
			.btn {
				padding: 0px;
			}
		}
	}
	.row.animation-enter {
		position: relative;
	}
  .related-products {
    margin-top: 10px;
    padding-bottom: 20px;
    > .row {
      margin-bottom: 20px;
    }
    .item {
      border-bottom: 1px solid $black;
      display: block;
      a {
        height: 50px;
		display: block;
        width: 100%;
        padding: 5px;
        margin-bottom: 5px;
        margin-top: 5px;
        position: relative;
        -webkit-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
        svg {
          height: 20px;
          width: auto;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);

          &.two-lines-logo {
            height: 34px;
          }
          &.mypex-svg-logo {
            height: 26px;
          }
          &.aquashield-svg-logo{
		  	height: 25px;
		   }

        }
        &.two-lines-logo,
        &.multitx-plus,
        &.rooftx-pro,
       // &.daltex-vcl,
        &.frametx-thermo,
        &.frametx-maxi,
        &.lotrak-25r,
        &.lotrak-50r,
        &.lotrak-70r,
        &.lotrak-20-20s,
        &.lotrak-30-30s,
        &.lotrak-40-40s,
        &.lobrene-lamination,
        &.groshield-extra,
        &.lobrene-groundcover,
        &.groshield-extra,
        &.daltex-laminate,
        &.daltex-fr,
        &.lobrene-scaffolding {
			svg {
				height: 34px;
			}
        }
        &.vapour-tx,
        &.daltex-vcl{
        	svg {
				height: 24px;
			}
        }

        &:hover {
          svg * {
            fill: #fff !important;
          }
        }
        .go-next {
          margin-left: 30px;
          position: relative;
          top: -3px;
        }

        &:after {
          position: absolute;
          content: "";
          left: 0;
          width: 0;
          top: 0;
          height: 100%;
          background: $blue;
          -webkit-transition: all .15s ease-in-out;
          transition: all .15s ease-in-out;
          z-index: -1;
        }
        &:hover {
          position: relative;
          text-decoration: none;
          .products-icon svg,
          .prod-title,
          .prod-sec-title,
          .prod-cat-title {
            color: $white;
            fill: $white;
          }

          &:after {
            left: 0;
            width: 100%;
            background: $blue;
          }

        }
      }

    }
    .prod-cat-title {
      display: initial;
      margin-left: 5px;
      color: $blue;
      font-weight: 700;
    }
    .products-icon {
      float: left;
      margin-right: 5px;
      svg {
        width: 20px;
        height: 20px;
        fill: $blue;
        display: block;
      }

    }
  }
}

.range-products {
  td {
    width: 25%;
  }
}

.discover-more {
  color: $blue;
  outline: 0;
  text-transform: uppercase;
  font-size: 1em;
  font-family: donlowBold, serif;
}

[data-module=button][data-type=back_offset] .btn-content .label-btn b {
  padding: 0px;
}

h2.category-title {
  font-size: 60px;
  text-transform: uppercase;
  color: #555555;
  margin-bottom: 60px;
  margin-top: 50px;
	@media screen and (max-width: 991px) {
		font-size: 50px;
		margin-bottom: 40px;
  		margin-top: 40px;
	}
	@media screen and (max-width: 767px) {
		font-size: 40px;
		margin-bottom: 30px;
  		margin-top: 30px;
	}
}


table{
  p{
    margin: 0!important;
  }
}

.category-description {
  padding-top: 50px;
  p {
    margin: 0 0 35px;
  }
}

.product-details {
	margin-top: 50px;
	//overflow: hidden;
	@media only screen and (max-width: 991px){
		margin-top: 40px;
	}
	@media only screen and (max-width: 767px){
		margin-top: 30px;
	}
	.product-brand {
		display: block;
		img {
			max-height: 40px;
		}
	}
	.details {
		padding: 40px 45px 150px;
		background: #f9f9f9;
		margin-top: 100px;
		position: relative;
		@media screen and (max-width: 767px) {
		  padding: 60px 15px 150px;
		}

		p {
		  margin: 0 0 30px;
		  font-size: 18px;
			@media screen and (max-width: 767px) {
				font-size: 16px;
			}
		}
		.cat-ic {
			float: left;
			margin-right: 20px;
			line-height: 30px;
			svg {
				fill: #555555;
				height: 40px;
				width: auto;
				max-width: 40px;
			}
		}
	}

}

.categories {

  .list-categories {

    @media only screen and (max-width: 767px) {
      padding: 50px 0 0 0;
    }
    .col-xl-3 {
      @media only screen and (min-width: 991px) {
        padding: 0 10px;
      }
    }
    .col-lx-4 {
      @media only screen and (min-width: 991px) and (max-width: 1400px) {
        width: 33.33%;
      }

    }
  .col-xs-12{
    padding: 5px;

  }
    .category-item {
      position: relative;
      max-height: 290px;
      height: 300px;
      margin: 0 auto;
      display: block;

      .name {
        font-size: 22px;
        position: relative;
        z-index: 2;
        top: 45px;
        left: 15px;
        display: table;
        padding: 5px 15px;
        background: rgba(255, 255, 255, 0);
        transition: all .15s ease-in-out;
        pointer-events: none;
        font-weight: 800;
        letter-spacing: 2px;
		@media screen and (max-width: 767px) {
			font-size: 18px;
		}
        &:before {
          position: absolute;
          content: "";
          height: 40px;
          width: 15px;
          left: -15px;
          top: -5px;
          opacity: 1;
          transition: all .15s ease-in-out;
        }
      }
      .description {
        position: relative;
        z-index: 2;
        top: 50px;
        left: 15px;
        padding: 5px 15px;
        background: rgba(255, 255, 255, 0);
        opacity: 0;
        pointer-events: none;
        -webkit-transition: all .15s ease-in-out;
        transition: all .15s ease-in-out;
        transition-delay: 0.06s;
      }

      &:hover {
        .name {
          left: 0px;
          padding: 5px 10px 5px 5px;
          background: rgba(255, 255, 255, 1);
          &:before {
            opacity: 0;
          }
        }
        .description {
          left: 0;
          padding: 5px 5px 5px 0px;
          background: #fff;
          opacity: 1;
          white-space: pre-wrap;

        }
        .overlay {
          opacity: 1;
          -webkit-filter: grayscale(0%) !important;
          -moz-filter: grayscale(0%) !important;
          -ms-filter: grayscale(0%) !important;
          -o-filter: grayscale(0%) !important;
          filter: none !important;
        }
      }
    }
    .overlay {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      opacity: 0.25;
      -webkit-transition: all ease-in-out .3s;
      transition-delay: all ease-in-out .3s;
      z-index: 1;
      pointer-events: all;
      -webkit-filter: grayscale(1);
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      filter: gray;
      filter: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' height='0'><filter id='greyscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0' /></filter></svg>#greyscale");
      filter: grayscale(100%);
		background-position: center center;
      &:hover {
        opacity: 1;
        -webkit-filter: grayscale(0%) !important;
        -moz-filter: grayscale(0%) !important;
        -ms-filter: grayscale(0%) !important;
        -o-filter: grayscale(0%) !important;
        filter: none !important;
      }
    }

  }
  .cta-block{
    padding: 0;
  }
}

.brands {

  .brands-list {

    margin: 0;
    list-style: none;
    .brand {
      margin-bottom: 20px;
      .brand-item {
        position: relative;
        display: block;
        border: 1px solid $blue;
        width: 100%;
        padding: 15px 5px;
        height: 100px;

        .brand-logo {
          display: table;
          margin: 0 auto;
          transform: translateY(-50%);
          position: relative;
          top: 50%;

          svg {
            height: 20px;
            display: block;
            width: auto;
            &.dlg-svg-logo {
              height: 30px;
            }

            &.mypex-svg-logo {
              height: 26px;
            }
            &.vapourtx-svg-logo,
            &.aquashield-svg-logo {
              height: 26px;
            }
            &.groshield-extra-svg-logo,
            &.groshield-strong-svg-logo,
            &.two-lines-logo {
              height: 35px;
            }
          }
        }

        &:hover {
          background: $blue;
          svg {
            * {
              fill: #ffffff;
            }
          }
          .products-icon {
            svg {
              fill: $white;
            }
          }

        }
      }

    }
  }

}

.directory-nav{
	padding-bottom: 80px;
	@media only screen and (max-width: 1200px){
		padding-bottom: 70px;
	}
	@media only screen and (max-width: 991px){
		padding-bottom: 50px;
	}
	@media only screen and (max-width: 767px){
		padding-bottom: 30px;
	}
	.cta-container {
		margin-top: 20px;
	}
	.cta-container h2 {
		font-size: 40px;
		font-family: donlowBold, serif;
	  	@media only screen and (max-width: 1200px){
			font-size: 36px;
		}
		@media only screen and (max-width: 991px){
			font-size: 32px;
		}
		@media only screen and (max-width: 767px){
			font-size: 26px;
		}
	}
	.directory-link {
		padding-top: 50px;
		min-height: 188px;
		h2 {
			font-size: 24px;
			margin-bottom: 0px;
			font-family: donlowBold, sans-serif;
			@media only screen and (max-width: 1200px){
				font-size: 22px;
			}
			@media only screen and (max-width: 991px){
				font-size: 20px;
			}
			@media only screen and (max-width: 767px){
				font-size: 16px;
				margin: 0;
				min-height: 33px;
				white-space: nowrap;
				width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
				
			}
		}
		h3 {
			font-size: 16px;
			font-weight: 700;
			font-style: normal;
			font-family: donlowBold, serif;
			display: table;
			position: relative;
			@media only screen and (max-width: 767px){
				font-size: 14px;
				margin-top: 0;
			}
		}
		&:hover {
			text-decoration: none
		}			
	}
	
	.directory-prev {
		text-align: right;
		transition: all 0.2s;
		padding-right: 40px;
		display:block;
		position: relative;
		h3{
			padding-left: 30px;
			float: right;
		}
		@media only screen and (max-width: 991px){
			padding-right: 30px;	
		}
		@media only screen and (max-width: 767px){
			padding-right: 20px;	
		}
		@media only screen and (max-width: 480px){
			padding-right: 0;	
		}
		&:before{
			position: absolute;
			left: 10px;
			right: 0;
			top: 0;
			bottom: 0;
			content: "";
			background: url("../img/icon-arrow-prev.png") 100% center no-repeat;
			background-size: contain;
			transition: all 0.2s;
		}
		&:hover {
			&:before{
				right: 20px;	
			}
		}
	}
	
	.directory-next {
		text-align: left;
		transition: all 0.2s;
		padding-left: 40px;
		display: block;
		position: relative;
	  	@media only screen and (max-width: 991px){
			padding-left: 30px;	
		}
		@media only screen and (max-width: 767px){
			padding-left: 20px;	
		}
		@media only screen and (max-width: 480px){
			padding-left: 0;	
		}
		&:before{
			position: absolute;
			right: 10px;
			left: 0;
			top: 0;
			bottom: 0;
			content: "";
			background: url("../img/icon-arrow-next.png") 0% center no-repeat;
			background-size: contain;
			transition: all 0.2s;
		}
		&:hover {
			&:before{
				left: 20px;	
			}
		}
		h3{
			padding-right: 30px;
		}
  	}
	.cta-container{
		hgroup{
			h3{
				color:$blue;
				font-size: 16px;
				text-transform: uppercase;
			}
			h2{
				color:$blue;
				font-size: 32px;
				text-transform: uppercase;
				@media only screen and (max-width: 991px){
					font-size: 28px;
				}
				@media only screen and (max-width: 767px){
					font-size: 24px;
				}
			}
		}
		.btn.btn-default{
			border-radius:0;
			background-color:#f9f9f9;
			border-color:#f9f9f9;
			color:$blue;
			font-size: 18px;
			text-transform: uppercase;
			&:hover{
				color: #ffffff;
				background-color:$blue;
				border-color:$blue;
			}
		}
	}
	.directory-link.directory-prev,
	.directory-link.directory-next{
		 text-transform: uppercase;
		font-size: 30px;
		color:$blue;
		@media only screen and (max-width: 991px){
			font-size: 26px;
		}
		@media only screen and (max-width: 767px){
			font-size: 24px;
		}
		h2{
		  text-transform: uppercase;
		  font-size: 24px;
		  color:$blue;
			@media only screen and (max-width: 1200px){
				font-size: 22px;
			}
			@media only screen and (max-width: 991px){
				font-size: 20px;
			}
			@media only screen and (max-width: 767px){
				font-size: 18px;
				margin: 0;
			}
		}
	}
	
	.go-prev,.go-next{
		font-size: 28px;
		line-height: 1px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
	.go-next{
		margin-left: 10px;
		right: 0;
	}
	.go-prev{
		margin-right: 10px;
		left: 0;
	}
}


.roofshield-inner-content{


	.roofshield-image-content-section{
			padding-bottom: 70px;
			.roofshield-content-wrapper{
				p:last-child {
					margin: 0;
				}
			}
		.pic-wrapper{
			position: relative;
			overflow: hidden;
			height: 100%;
			min-height: 420px;

			 @media only screen and (max-width: 767px){
			 	min-height: 360px;
				}
			.img-abs{
			  position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
        &.img-abs-right{
        	object-position: 100% 50%;
        }

         &.img-abs-left{
        	object-position: 0% 50%;
        }
        
				 @media only screen and (max-width: 767px){
				}

			}
		}
	}

	.roofshield-queries-section{
		position: relative;
		padding: 30px 0px;
		color: #fff;
		margin: 0px 0 70px;
		 //font-family: 'donlowMedium';
		 font-size: 36px;
		 @media only screen and (max-width: 1440px){
				font-size: 26px;
			}
			
			@media only screen and (max-width: 991px){
				font-size: 20px;
			}
			@media only screen and (max-width: 767px){
				font-size: 22px;
			}
			a{
				color: #00e5ca;
				transition: all .3s;
				text-decoration: none;
				&:hover{
					color: #fff;
				}
			}
		&:before{
			content:"";
			position: absolute;
			pointer-events: none;
			width: 150vw;
			background-color:$blue;
			height: 100%;
			top: 0;
			left: -50%;
		}
	}
	
}


.complete-envelope-solution{
	.complete-envelope-solution-upper-section{
		background-color:$blue;
		padding: 30px 0px;
		color: #fff;
		 font-size: 36px;
		  @media only screen and (max-width: 1440px){
				font-size: 26px;
			}
			
			@media only screen and (max-width: 991px){
				font-size: 24px;
			}

			@media only screen and (max-width: 767px){
				font-size: 22px;
			}
			
	}
	.complete-envelope-solution-content{
		max-width: 800px;
	}
}

.roofshield-carousel{
	.roofshield-carousel-title{
		position: absolute;
		padding: 15px;
		right: 0;
		z-index: 2;
		top: 0;
		color: $blue;
		background: rgba(255,255,255,.5);
		img{
			@media only screen and (max-width: 991px){
			  width: 180px;
			}
			@media only screen and (max-width: 767px){
			  width: 140px;
			}
		}
	}
	.image-container{
		position: relative;
		
	}
	.roofshield-carousel-list{

	}
}