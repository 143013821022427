
/**
 http://tympanus.net/codrops/2013/08/06/creative-link-effects/
 **/
*, *:after, *::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.btn-content a {
  position: relative;
  display: inline-block;
  margin: 15px 25px;
  outline: none;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1em;
  font-family: 'donlowBold', serif;
}
.btn-content a:hover, .btn-content a:focus {
  outline: none;
}
.center-container {
  display: table;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}
.center-container .center-content {
  display: table-cell;
  vertical-align: middle;
}
.title {
  font-family: 'donlowThin', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sub-title {
  font-family: 'donlowLight', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  color: #fff;
  text-decoration: none;
  outline: 0;
}
html.no-touch a:hover {
  color: #282828;
  outline: 0;
  text-decoration: none;
}
html.touch a:focus {
  color: #282828;
  outline: 0;
  text-decoration: none;
}
html.touch a:active {
  outline: 0;
  text-decoration: none;
}
textarea {
  resize: none;
}
.left_to_right a, .left_to_right .contLink {
  overflow: hidden;
  border: 1px solid #fff;
  padding: 0;
}
.left_to_right a span, .left_to_right .contLink span {
  padding: 10px;
}
.left_to_right a b, .left_to_right .contLink b {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 8px;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #fff;
  -webkit-transition: width 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}
html.no-touch .left_to_right a:hover, html.no-touch .left_to_right .contLink:hover {
  color: #282828;
}
html.no-touch .left_to_right a:hover b, html.no-touch .left_to_right .contLink:hover b {
  width: 100%;
}
html.touch .left_to_right a:focus, html.touch .left_to_right .contLink:focus {
  color: #282828;
}
html.touch .left_to_right a:focus b, html.touch .left_to_right .contLink:focus b {
  width: 100%;
}
html, body {
  font-size: 18px;
}
@media only screen and (max-width: 991px){	
	html, body {
	  font-size: 16px;
	}
}
@media only screen and (max-width: 767px){
	html, body {
	  font-size: 14px;
	}
}
/*detect mobile, tablet, smartphone */
.detect {
  width: 0;
  height: 0;
  opacity: 0;
}
.detect.global_tablet_mob {
  display: block;
}
@media (max-width: 768px) {
  .detect.global_tablet_mob {
    display: none;
  }
}
.detect.global_smart_mob {
  display: block;
}
@media (max-width: 544px) {
  .detect.global_smart_mob {
    display: none;
  }
}
/* column shortcode */
.full_col {
  float: left;
  width: 100%;
  margin-bottom: 30px;
}
.half_col {
  float: left;
  /*REPONSIVE OVERRIDING*/
}
@media (min-width: 150px) {
  .half_col {
    width: 100%;
  }
}
@media (min-width: 736px) {
}
@media (min-width: 769px) {
}
@media (min-width: 1050px) {
  .half_col {
    width: 50%;
  }
}
@media (min-width: 1050px) {
  .half_col.padding {
    padding: 20px;
  }
}
@media (min-width: 1050px) {
  .half_col.padding-left {
    padding-left: 20px;
  }
}
@media (min-width: 1050px) {
  .half_col.padding-right {
    padding-right: 20px;
  }
}
body {
  font-family: 'donlowLight', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#main {
  position: relative;
  z-index: 3;
  overflow: hidden;
}
.site-main {
  background-color: transparent;
}

.linee_bck {
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
}
.linee_bck span {
    -webkit-transform: translateY(-100.1%);
    -moz-transform: translateY(-100.1%);
    -ms-transform: translateY(-100.1%);
    -o-transform: translateY(-100.1%);
    transform: translateY(-100.1%);
    position: absolute;
    width: 1px;
    height: 100%;
    background: rgba(0, 0, 0, .05);
}
.linee_bck span.odd {
    top: 0;
}
.linee_bck span.even {
    bottom: 0;
}
.linee_bck span.line_1 {
    left: 16.5%;
}
.linee_bck span.line_2 {
    left: 33%;
}
.linee_bck span.line_3 {
    left: 50%;
}
.linee_bck span.line_4 {
    left: 66.5%;
}
.linee_bck span.line_5 {
    left: 83.5%;
}
@media (max-width: 544px) {
    .linee_bck span.line_1 {
        left: 25%;
    }
    .linee_bck span.line_2 {
        left: 50%;
    }
    .linee_bck span.line_3 {
        left: 75%;
    }
    .linee_bck span.line_4, .linee_bck span.line_5 {
        display: none;
    }
}

p a {
  color: inherit;
}

input::-moz-focus-inner {
  border: 0;
  outline: none;
}
a:hover, a:active, a:focus {
  border: 0;
  outline: none;
}
h3.sub_title {
  font-weight: 100 !important;
}
#menu_cover {
  background: #000;
}
.noMargin {
  margin: 0;
}
.no_footer .site-main {
  margin-bottom: 0 !important;
}
.scroll_down_line {
  width: 1px;
  height: 62px;
  overflow: hidden;
}
.scroll_down_line span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: #282828;
  margin: 0;
  padding: 0;
}
@media (max-width: 544px) {
  .scroll_down_line {
    display: none !important;
  }
}
[data-page="homepage"] .scroll_down_line {
  position: absolute;
  bottom: 0;
  left: 10%;
  margin: 0 0 0 -16px;
}
.site-main {
  margin-bottom: 500px;
}
.cont_prod, .col-intro-text, .big_title, .single_collection .cont_article {
  opacity: 0;
}
#main {
  margin-bottom: auto;
}
.page-template-homepage [data-module="footer"] {
  display: block;
}
.page-template-homepage #main {
  padding-bottom: 100px;
}
@media (max-width: 768px) {
  .page-template-homepage #main {
    padding-bottom: 196px;
  }
}
.detect {
  width: 0;
  height: 0;
  opacity: 0;
}
.detect.global_tablet_mob {
  display: block;
}
@media (max-width: 768px) {
  .detect.global_tablet_mob {
    display: none;
  }
}
.detect.global_smart_mob {
  display: block;
}
@media (max-width: 544px) {
  .detect.global_smart_mob {
    display: none;
  }
}
[data-module="button"][data-type="back_offset"] .btn-content .label-btn span {
  opacity: 0.5;
}
[data-module="button"][data-type="back_offset"] .btn-content .label-btn b {
  font-weight: 100;
}
[data-module="header"][data-type="with_text"] {
	top: 63px;
	color: #63635f;
	padding: 0;
}

@media (max-width:991px) {
	[data-module="header"][data-type="with_text"] .col-xs-4{
		padding: 0;
  	}
	[data-module="header"][data-type="with_text"] {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		background: #fff;
		height: auto;
  	}
	.nav-off header[data-module="header"][data-type="with_text"] .custom-container{
		margin: 10px auto;
	}
}


[data-module="header"][data-type="with_text"] .logo {
  padding: 0 0 0 6%;
  height: auto;
  margin-left: -15px;
  color: #282828;
  text-decoration: none !important;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (max-width: 1320px) {
  [data-module="header"][data-type="with_text"] .logo {
    height: auto;
  }
}
html.no-touch [data-module="header"][data-type="with_text"] .logo:hover {
  text-decoration: none !important;
}
html.touch [data-module="header"][data-type="with_text"] .logo:focus {
  text-decoration: none !important;
}
[data-module="header"][data-type="with_text"] .social {
  padding: 0 7%;
}
[data-module="header"][data-type="with_text"] .social a {
  color: #63635f;
}
html.no-touch [data-module="header"][data-type="with_text"] .social a:hover {
  text-decoration: none;
  color: #757575;
}
html.touch [data-module="header"][data-type="with_text"] .social a:focus {
  text-decoration: none;
  color: #757575;
}
[data-module="header"][data-type="with_text"] .social b {
  color: #63635f;
  font-weight: 100;
  line-height: 35px;
  font-size: 11px;
  font-size: 0.6875em;
  font-size: 0.6875rem;
}
[data-module="header"][data-type="with_text"] .social b:after {
  background: #63635f;
}
[data-module="header"][data-type="with_text"] .back-page-btn {
  width: auto;
  height: 110%;
  margin-left: -37px;
  position: absolute;
  left: 33%;
}
[data-module="header"][data-type="with_text"] .back-page-btn::after {
  display: none;
}
@media (max-width: 544px) {
  [data-module="header"][data-type="with_text"] .back-page-btn {
    display: none;
  }
}
[data-module="header"][data-type="with_text"] .base-icon-arrow_back {
  display: block;
  float: left;
  line-height: 24px;
  font-size: 51px !important;
  color: #63635f;
}
[data-module="header"][data-type="with_text"] .base-icon-arrow_back:before {
  content: "\e931";
}
[data-module="header"][data-type="with_text"] .back-page-content {
  color: #63635f;
}
[data-module="header"][data-type="with_text"] .back-page-content:after {
  display: block;
  float: right;
  content: "Back";
  margin: 4px 8px;
  font-size: 13px;
  font-size: 0.8125em;
  font-size: 0.8125rem;
  line-height: 22px;
  font-family: 'donlowLight', sans-serif;
  color: #63635f;
}
.black_header [data-module="header"][data-type="with_text"] {
  color: #00154d;
}
.black_header [data-module="header"][data-type="with_text"] .logo, .black_header [data-module="header"][data-type="with_text"] .base-icon-arrow_back {
  color: #00154d;
}
.black_header [data-module="header"][data-type="with_text"] .social a {
  color: #00154d;
}
html.no-touch .black_header [data-module="header"][data-type="with_text"] .social a:hover {
  color: #8e8e8e;
}
html.touch .black_header [data-module="header"][data-type="with_text"] .social a:focus {
  color: #8e8e8e;
}
.black_header [data-module="header"][data-type="with_text"] .social b {
  color: #00154d;
}
.black_header [data-module="header"][data-type="with_text"] .social b:after {
  background: #00154d;
}
.black_header [data-module="header"][data-type="with_text"] .back-page-content {
  color: #00154d;
}
.black_header [data-module="header"][data-type="with_text"] .back-page-content:after {
  color: #00154d;
}
[data-module="header"][data-type="with_text"] .claim_header {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 67px;
  line-height: 75px;
  margin: 0;
  font-weight: 100;
  font-family: 'donlowLight', sans-serif;
  text-transform: uppercase;
  text-align: center;
  color: rgba(0, 0, 0, .35);
  font-size: 11px;
  font-size: 0.6875em;
  font-size: 0.6875rem;
}
@media (max-width: 768px) {
  [data-module="header"][data-type="with_text"] .claim_header {
    display: none;
  }
}
.page-template-homepage [data-module="header"][data-type="with_text"] .claim_header {
  display: block;
}
.no-touch [data-module="header"][data-type="with_text"] {
}
@media (max-width: 544px) {
  [data-module="header"][data-type="with_text"] {
    top: 0;
  }
  [data-module="header"][data-type="with_text"] .logo {
    margin-left: 0;
    height: auto;
    -webkit-transform: translateY(0%) !important;
    -moz-transform: translateY(0%) !important;
    -ms-transform: translateY(0%) !important;
    -o-transform: translateY(0%) !important;
    transform: translateY(0%) !important;
  }
  [data-module="header"][data-type="with_text"] .logo .icon-exteta {
    -webkit-transform: translateY(0%) !important;
    -moz-transform: translateY(0%) !important;
    -ms-transform: translateY(0%) !important;
    -o-transform: translateY(0%) !important;
    transform: translateY(0%) !important;
  }
  [data-module="header"][data-type="with_text"] .social {
    display: none;
  }
  [data-module="header"][data-type="with_text"] [data-module="nav-button"] {
    float: right;
  }
  [data-module="header"][data-type="with_text"] .claim_header {
    display: none !important;
  }
}
[data-module="nav"][data-type="top_side"] .menu__link {
  line-height: 45px;
  font-family: 'donlowLight';
}
[data-module="nav"][data-type="top_side"] .menu__link[data-submenu]::after {
  display: none;
}
[data-module="nav"][data-type="top_side"] .nav_cont {
  width: 33.5%;
  margin: 0 33%;
}
[data-module="nav"][data-type="top_side"] .menu__wrap {
  padding: 0;
  margin-left: -24px;
}
[data-module="nav"][data-type="top_side"] .menu__secondary {
  right: 0;
  padding: 0;
  opacity: 0.8;
  filter: alpha(opacity=80);
}
[data-module="nav"][data-type="top_side"] .nav-lang {
  position: absolute;
  top: 142px;
  width: 50%;
  right: 0;
  height: auto;
  padding-top: 18px;
  text-align: right;
  font-family: 'donlowLight', sans-serif;
}
@media (max-width: 768px) {
  [data-module="nav"][data-type="top_side"] {
    overflow: auto;
  }
  [data-module="nav"][data-type="top_side"] .nav_cont {
    width: 80%;
    margin: 0 10%;
  }
}
@media (max-width: 544px) {
  [data-module="nav"][data-type="top_side"] {
    overflow: auto;
  }
  [data-module="nav"][data-type="top_side"] .menu__wrap {
    margin-bottom: 30px;
    margin-left: 0;
  }
}
[data-module="nav-button"][data-type="burger_cross"][data-bcross-layout="go_right"] {
  position: absolute;
  top: 12px;
  left: 16.5%;
  margin: 0 !important;
  width: 44px; 
}
[data-module="nav-button"][data-type="burger_cross"][data-bcross-layout="go_right"] .container_burger {
  left: 0;
  padding: 12px;
  border: none;
  transform: translateX(-50%);
}
@media (max-width: 1245px) {
  [data-module="nav-button"][data-type="burger_cross"][data-bcross-layout="go_right"] {
    position: relative;
    left: 0;
    margin: 0 5px !important;
  }
}
[data-module="nav-button"][data-type="burger_cross"][data-bcross-layout="go_right"] [data-container="line"] [data-line="top"] {
  top: calc(49.9031% - 10px);
}
[data-module="nav-button"][data-type="burger_cross"][data-bcross-layout="go_right"] [data-container="line"] [data-line="bottom"] {
  top: calc(50.0969% + 10px);
}
[data-module="nav-button"][data-type="burger_cross"][data-bcross-layout="go_right"] [data-container="line"] div {
  background: #63635f;
}
.black_header [data-module="nav-button"][data-type="burger_cross"][data-bcross-layout="go_right"] [data-container="line"] div, .black_header [data-module="nav-button"][data-type="burger_cross"][data-bcross-layout="go_right"] [data-container="cross"] div {
  background: #00154d;
}
@media only screen and  (max-width: 767px) {
	[data-module="nav-button"][data-type="burger_cross"][data-bcross-layout="go_right"] [data-container="line"] div, 
	[data-module="nav-button"][data-type="burger_cross"][data-bcross-layout="go_right"] [data-container="cross"] div {
	  background: #00154d;
	}	
}



[data-module="slider"][data-type="cover_multi"] .wrapper-text {
  left: 2%;
  margin: 0 0 0 -20px;
  height: auto;
  min-height: 0;
  bottom: 0;
}
[data-module="slider"][data-type="cover_multi"] .container-text .text_cover {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}
[data-module="slider"][data-type="cover_multi"] .wrapper-bg {
  width: 94%;
}
@media only screen and (max-width: 767px) {
	[data-module="slider"][data-type="cover_multi"] .wrapper-bg {
		width: auto;
		left: 0;
		right: 0;
	}	
}
[data-module="slider"][data-type="cover_multi"] .custom-pagination {
  width: 2%;
}
[data-module="slider"][data-type="cover_multi"] .custom-pagination .link_item {
  position: relative;
  min-height: 32px;
  margin: 3px 0;
  font-size: 12px;
  font-size: 0.75em;
  font-size: 0.75rem;
}
[data-module="slider"][data-type="cover_multi"] .custom-pagination .link_item section {
  top: 50%;
  right: 20%;
  width: 80%;
}
[data-module="slider"][data-type="cover_multi"] .custom-pagination .link_item b {
  display: block;
  position: absolute;
  left: 100%;
  width: auto;
  margin: 0 3px;
  padding: 0;
  text-align: left;
  font-weight: normal;
}
[data-module="slider"][data-type="cover_multi"] [data-module="button"][data-type="back_offset"] .btn-content .label-btn {
  margin: 8px 14px;
}
[data-module="slider"][data-type="cover_multi"] [data-module="button"][data-type="back_offset"] .btn-content .label-btn b {
  font-weight: 100;
}
@media (max-width: 991px) {
	[data-module="slider"][data-type="cover_multi"] .swiper-wrapper {
		padding-bottom: 70px;
	}
}
@media (max-width: 767px) {
	[data-module="slider"][data-type="cover_multi"] .swiper-wrapper {
		padding-bottom: 50px;
	}
}
@media (max-width: 544px) {
	[data-module="slider"][data-type="cover_multi"] .swiper-wrapper {
		padding-bottom: 30px;
	}
}
[data-module="inclusion"][data-type="even_odd"] {
	width: 100%;
	overflow: hidden;
	padding: 100px 0;
	opacity: 1;
}
[data-module="inclusion"][data-type="even_odd"] .container {
  max-width: 100% !important;
}
[data-module="inclusion"][data-type="even_odd"] .article-container {
  padding: 10%;
}









@media (max-width: 768px) {
  [data-module="inclusion"][data-type="even_odd"] .article-container {
    padding: 10%;
  }
}
@media (max-width: 544px) {
  [data-module="inclusion"][data-type="even_odd"] .article-container {
    padding: 10% 0;
  }
}
[data-module="inclusion"][data-type="even_odd"] .article-container .center-container {
  overflow: hidden;
}
[data-module="inclusion"][data-type="even_odd"] .pre_title {
  display: block;
  font-size: 12px;
  font-size: 0.75em;
  font-size: 0.75rem;
  line-height: 20px;
  font-family: 'donlowLight', sans-serif !important;
  font-weight: bold !important;
  color: #b4a69d;
  margin-bottom: 15px;
  padding: 5px 0;
}
[data-module="inclusion"][data-type="even_odd"] .title {
  font-family: 'Cervo-Light', serif;
  font-size: 42px;
  font-size: 2.625em;
  font-size: 2.625rem;
  margin-bottom: 10px;
}
@media (max-width: 544px) {
  [data-module="inclusion"][data-type="even_odd"] .title {
    max-width: 100%;
  }
}
[data-module="inclusion"][data-type="even_odd"] .text-content {
  float: right;
  clear: both;
}
[data-module="inclusion"][data-type="even_odd"] .text_cover {
  position: relative;
  float: right;
  clear: both;
  overflow: hidden;
}
[data-module="inclusion"][data-type="even_odd"] .text_cover b {
  display: block;
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  padding: 0;
  font-weight: normal;
}
[data-module="inclusion"][data-type="even_odd"] .text_cover span {
  position: absolute;
  top: 0;
  right: auto;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background: #00154d;
}
[data-module="inclusion"][data-type="even_odd"] .btn-content {
  float: right;
}
[data-module="inclusion"][data-type="even_odd"] .btn-content a {
  margin: 10px 0;
}
[data-module="inclusion"][data-type="even_odd"] .col {
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
}
[data-module="inclusion"][data-type="even_odd"] .col-right .text-content {
  width: 100%;
}
[data-module="inclusion"][data-type="even_odd"] .col-right .text_cover, [data-module="inclusion"][data-type="even_odd"] .col-right .btn-content, [data-module="inclusion"][data-type="even_odd"] .col-right .text-content {
  float: left;
}
[data-module="inclusion"][data-type="even_odd"] .col-right .text_cover span {
  right: 0;
  left: auto;
}
[data-module="inclusion"][data-type="even_odd"] .col-right .btn-content {
  text-align: left;
}
[data-module="inclusion"][data-type="even_odd"] .col-right .pre_title b:after {
  display: inline-block;
  content: "";
  width: 34px;
  height: 2px;
  margin: 2px 12px;
  background: #b4a69d;
}
[data-module="inclusion"][data-type="even_odd"] .col-right .article-container a {
  display: block;
  float: left;
}
[data-module="inclusion"][data-type="even_odd"] .col-left .btn-content {
  text-align: right;
}
[data-module="inclusion"][data-type="even_odd"] .col-left .pre_title b:before {
  display: inline-block;
  content: "";
  width: 34px;
  height: 2px;
  margin: 2px 12px;
  background: #b4a69d;
}
[data-module="inclusion"][data-type="even_odd"] .col-left .article-container a {
  display: block;
  float: right;
}
[data-module="inclusion"][data-type="even_odd"] .col-cont {
  overflow: hidden;
}
@media (max-width: 768px) {
  [data-module="inclusion"][data-type="even_odd"] {
    padding: 80px 0;
  }
}
[data-module="inclusion"][data-type="text_col"] .cover_noimg {
  background: #b4a69d;
}
.single_post [data-module="inclusion"][data-type="text_col"] figure {
  position: relative;
}
.single_post [data-module="inclusion"][data-type="text_col"] .overlay {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #b4a69d;
  opacity: 0.95;
  filter: alpha(opacity=95);
}
[data-module="inclusion"][data-type="text_col"] .container-title {
  text-align: center;
}
[data-module="inclusion"][data-type="text_col"] .container-title h1 {
  display: inline-block;
}
[data-module="matrix"][data-type="overlay_img"] {
  padding-top: 180px;
  padding-bottom: 180px;
  margin-left: -4%;
  margin-right: -4%;
  padding-left: 17.6%;
  padding-right: 18.1%;
  opacity: 1;
}
[data-module="matrix"][data-type="overlay_img"] .col {
  overflow: inherit;
}
@media (max-width: 768px) {
  [data-module="matrix"][data-type="overlay_img"] {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
[data-module="matrix"][data-type="overlay_img"][data-ovim_layout="no_even_odd"] .container {
  max-width: 100% !important;
  padding-left: 0;
  padding-right: 0;
  position: relative;
}
[data-module="matrix"][data-type="overlay_img"][data-ovim_layout="no_even_odd"] .col-text {
  padding-top: 50px;
}
[data-module="matrix"][data-type="overlay_img"][data-ovim_layout="no_even_odd"] .col-text .container-title .title {
  font-family: 'Cervo-Light', serif;
  font-size: 26px;
  font-size: 1.625em;
  font-size: 1.625rem;
  text-align: left;
  font-weight: normal;
}
[data-module="matrix"][data-type="overlay_img"][data-ovim_layout="no_even_odd"] .col-text .container-title .line {
  display: none;
}
[data-module="matrix"][data-type="overlay_img"][data-ovim_layout="no_even_odd"] .col-img img, [data-module="matrix"][data-type="overlay_img"][data-ovim_layout="no_even_odd"] .col-img .bg {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
@media (max-width: 768px) {
  [data-module="matrix"][data-type="overlay_img"][data-ovim_layout="no_even_odd"] .col-odd, [data-module="matrix"][data-type="overlay_img"][data-ovim_layout="no_even_odd"] .col-even {
    margin: 20px 0;
  }
}
[data-module="matrix"][data-type="overlay_img"][data-ovim_layout="no_even_odd"] .col-even [data-module="button"][data-type="back_offset"] .btn-content {
  float: right;
}
[data-module="matrix"][data-type="overlay_img"][data-ovim_layout="no_even_odd"] .col-even .col-img {
  left: auto;
  right: -100px;
}
[data-module="matrix"][data-type="overlay_img"][data-ovim_layout="no_even_odd"] .col-even .col-text {
  right: 0;
  left: auto;
  text-align: right;
}
[data-module="matrix"][data-type="overlay_img"][data-ovim_layout="no_even_odd"] .col-even .col-text .container-title .title {
  text-align: right;
}
[data-module="matrix"][data-type="overlay_img"][data-ovim_layout="no_even_odd"] .col-odd .col-text {
  left: 0;
}
[data-module="matrix"][data-type="overlay_img"][data-ovim_layout="no_even_odd"] .col-odd .col-img {
  left: -100px;
}
@media (max-width: 544px) {
  [data-page="homepage"] [data-module="matrix"][data-type="overlay_img"][data-ovim_layout="no_even_odd"] {
    display: none;
  }
}
[data-module="matrix"][data-type="vertical_gallery"] {
  margin-bottom: 100px;
}
[data-module="overlay"][data-type="photoswipe"] .pswp__bg {
  background-color: rgba(255, 255, 255, 1);
}
[data-module="overlay"][data-type="photoswipe"] .pswp__button--close {
  background-color: rgba(255, 255, 255, 0) !important;
  border: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  font-size: 30px;
  font-size: 1.875em;
  font-size: 1.875rem;
  margin: 5px;
}
[data-module="overlay"][data-type="photoswipe"] .base-icon-close:before {
  content: "\e932";
}
[data-module="overlay"][data-type="photoswipe"] .pswp__top-bar, [data-module="overlay"][data-type="photoswipe"] .pswp__caption {
  background: none !important;
}
[data-module="overlay"][data-type="photoswipe"] .pswp-btn-next {
  position: absolute;
  height: 50px;
  width: 50px;
  margin: 0;
  box-sizing: border-box;
  line-height: 50px;
  text-align: center;
  color: #333;
  border: none;
  font-size: 15px;
  font-size: 0.9375em;
  font-size: 0.9375rem;
  mix-blend-mode: luminosity;
  background: none;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
[data-module="overlay"][data-type="photoswipe"] .base-icon-arrow_forward:before {
  mix-blend-mode: luminosity;
  content: "next";
  font-family: 'Cervo-Light', serif;
}
[data-module="overlay"][data-type="photoswipe"] .base-icon-arrow_back:before {
  mix-blend-mode: luminosity;
  content: "prev";
  font-family: 'Cervo-Light', serif;
}
[data-module="overlay"][data-type="lightgallery"] .lg-backdrop {
  background-color: rgba(255, 255, 255, 1);
}
[data-module="overlay"][data-type="lightgallery"] .lg-backdrop.in {
  opacity: 1;
}
[data-module="overlay"][data-type="lightgallery"] .lg-close {
  background-color: rgba(255, 255, 255, 1) !important;
  border: none;
  color: #333;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  margin: 0;
  font-size: 30px;
  font-size: 1.875em;
  font-size: 1.875rem;
}
[data-module="overlay"][data-type="lightgallery"] .lg-prev, [data-module="overlay"][data-type="lightgallery"] .lg-next {
  height: 50px;
  width: 50px;
  margin: 0;
  box-sizing: border-box;
  line-height: 50px;
  text-align: center;
  color: #333;
  border: none;
  font-size: 15px;
  font-size: 0.9375em;
  font-size: 0.9375rem;
  mix-blend-mode: luminosity;
  background: none;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
[data-module="overlay"][data-type="lightgallery"] .lg-prev:before {
  mix-blend-mode: luminosity;
  content: "prev";
  font-family: 'Cervo-Light', serif;
}
[data-module="overlay"][data-type="lightgallery"] .lg-next:before {
  mix-blend-mode: luminosity;
  content: "next";
  font-family: 'Cervo-Light', serif;
}
[data-module="overlay"][data-type="lightgallery"] .lg-close:before {
  content: "\e932";
}
[data-module="overlay"][data-type="lightgallery"] .lg-actions .lg-icon {
  border: none;
  color: #333;
}
[data-module="overlay"][data-type="lightgallery"] #lg-counter, [data-module="overlay"][data-type="lightgallery"] .lg-download {
  display: none;
}
[data-module="footer"][data-type="minimal"] {
  padding-top: 40px;
  margin-top: -100px;
  position: relative;
  z-index: 10;
  opacity: 1;
}
[data-module="footer"][data-type="minimal"] .minimal_text {
  position: relative;
}
[data-module="footer"][data-type="minimal"] .minimal_text .phone {
  top: 2px;
  position: absolute;
  left: 45px;
  font-size: 17px;
  margin-right: 6px;
  color: #b4a69d;
}
[data-module="footer"][data-type="minimal"] .col-menu li a {
  border-bottom: 2px solid #b4a69d;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
[data-module="footer"][data-type="minimal"] .col-menu li a:focus, [data-module="footer"][data-type="minimal"] .col-menu li a:hover {
  padding-bottom: 1px;
}
[data-module="footer"][data-type="minimal"] .container {
  padding-left: 16.5%;
  padding-right: 16.9%;
}
[data-module="footer"][data-type="minimal"] .nav-legal {
  padding-left: 0;
}
[data-module="footer"][data-type="minimal"] .address .nav-legal {
  text-align: right;
}
@media (max-width: 768px) {
  [data-module="footer"][data-type="minimal"] {
    margin-top: -196px;
  }
  [data-module="footer"][data-type="minimal"] .content > .row {
    min-height: 96px;
    margin-bottom: 0;
  }
  [data-module="footer"][data-type="minimal"] .col-menu li {
    margin-right: 10px;
    margin-left: 10px;
  }
  [data-module="footer"][data-type="minimal"] .nav-legal {
    text-align: center;
  }
  [data-module="footer"][data-type="minimal"] .row {
    margin-bottom: 5px;
  }
}
[data-module="button"][data-type="back_offset"] {
  opacity: 1;
}
[data-module="matrix"][data-type="mix_sizing"] {
  opacity: 1;
}
[data-module="overlay"][data-type="lightgallery"] {
  opacity: 1;
  filter: alpha(opacity=100);
  opacity: 1 !important;
}
[data-module="overlay"][data-type="photoswipe"] {
  opacity: 1;
  filter: alpha(opacity=100);
  opacity: 1 !important;
}
[data-module="footer"] {
  display: none;
  position: static;
  height: 100px;
}
@media (max-width: 768px) {
  [data-module="footer"] {
    height: auto;
  }
}
[data-module="cookies"][data-type="standard"] {
  padding: 15px 4%;
  border: 1px solid rgba(204, 204, 204, .8);
  background: #fff;
}
[data-module="cookies"][data-type="standard"] #cookies_text {
  line-height: 20px;
  font-family: 'donlowLight', sans-serif;
  color: #282828;
  padding-top: 5px;
  font-size: 11px;
  font-size: 0.6875em;
  font-size: 0.6875rem;
}
[data-module="cookies"][data-type="standard"] #cookies_text a {
  color: #282828;
  text-decoration: underline;
}
[data-module="cookies"][data-type="standard"] button#cookies_btn {
  position: absolute;
  top: 50%;
  right: 4%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: auto;
  border-radius: 0;
  color: #282828;
  border: 2px solid rgba(180, 166, 157, .5);
  background: none;
  line-height: 30px;
  height: 30px;
  padding: 0 35px;
  text-transform: uppercase;
  font-size: 9px;
  font-size: 0.5625em;
  font-size: 0.5625rem;
  font-weight: bold;
  letter-spacing: 1px;
}
[data-module="cookies"][data-type="standard"] button#cookies_btn:hover {
  background: none;
  border: 2px solid rgba(180, 166, 157, 1);
  color: #282828;
}
.single_product .intro_prod {
  margin-top: 150px;
}
.single_product .intro_prod .col-intro-text {
  width: 60%;
  float: left;
  position: relative;
}
.single_product .intro_prod .col-intro-text header {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  top: 50%;
  overflow-x: hidden;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 27.5%;
  box-sizing: border-box;
}
.single_product .intro_prod .col-intro-text header .pre_title {
  display: block;
  float: left;
  clear: both;
  position: relative;
  font-size: 12px;
  font-size: 0.75em;
  font-size: 0.75rem;
  font-weight: normal;
  line-height: 20px;
  font-family: 'donlowLight', sans-serif;
  color: #b4a69d;
  margin-bottom: 15px;
  padding: 5px 0;
}
.single_product .intro_prod .col-intro-text header .pre_title:after {
  display: inline-block;
  content: "";
  width: 34px;
  height: 2px;
  margin: 2px 12px;
  background: #b4a69d;
}
.single_product .intro_prod .col-intro-text header .title {
  display: block;
  float: left;
  clear: both;
  position: relative;
  font-size: 57px;
  font-size: 3.5625em;
  font-size: 3.5625rem;
  line-height: 1.2em;
  font-family: 'Cervo-Light', serif;
  color: #282828;
  padding: 5px 0;
  font-weight: normal;
}
.single_product .intro_prod .col-intro-text header .pre_title, .single_product .intro_prod .col-intro-text header .title {
  overflow: hidden;
}
.single_product .intro_prod .col-intro-text header .pre_title span, .single_product .intro_prod .col-intro-text header .title span {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 7;
  width: 100%;
  height: 100%;
  background: #282828;
}
.single_product .intro_prod .col-intro-text .sub_title {
  position: absolute;
  font-family: 'donlowLight', sans-serif;
  font-size: 200px;
  font-size: 12.5em;
  font-size: 12.5rem;
  font-weight: bold;
  line-height: 0.8em;
  color: rgba(40, 40, 40, .04);
  -webkit-transform: rotate(-90deg) translateY(22%);
  -moz-transform: rotate(-90deg) translateY(22%);
  -ms-transform: rotate(-90deg) translateY(22%);
  -o-transform: rotate(-90deg) translateY(22%);
  transform: rotate(-90deg) translateY(22%);
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  -o-transform-origin: center center;
  transform-origin: center center;
  top: 25%;
  left: 0;
  text-align: right;
}
.single_product .intro_prod .col-intro-img {
  width: 40%;
  float: left;
}
.single_product .intro_prod [data-module="inclusion"][data-type="image"] {
  width: 100%;
  height: 100%;
}
.single_product .intro_prod [data-module="inclusion"][data-type="image"] .container {
  max-width: 100% !important;
  padding-left: 0;
  padding-right: 0;
  position: relative;
}
.single_product .intro_prod [data-module="inclusion"][data-type="image"] .image {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
}
.single_product .intro_prod [data-module="inclusion"][data-type="image"] .overlay {
  opacity: 1;
  filter: alpha(opacity=100);
  background: #b4a69d;
}
.single_product .intro_prod .scroll_down_line {
  position: absolute;
  bottom: 0;
  left: 16.5%;
}
@media (max-width: 768px) {
  .single_product .intro_prod .col-intro-text, .single_product .intro_prod .col-intro-img {
    width: 100%;
  }
  .single_product .intro_prod .col-intro-text header {
    width: 90%;
    left: 5%;
  }
}
@media (max-width: 544px) {
  .single_product .intro_prod .col-intro-text, .single_product .intro_prod .col-intro-img {
    width: 100%;
  }
  .single_product .intro_prod .col-intro-text .sub_title {
    font-size: 140px;
    font-size: 8.75em;
    font-size: 8.75rem;
    -webkit-transform: rotate(-90deg) translateY(-22%);
    -moz-transform: rotate(-90deg) translateY(-22%);
    -ms-transform: rotate(-90deg) translateY(-22%);
    -o-transform: rotate(-90deg) translateY(-22%);
    transform: rotate(-90deg) translateY(-22%);
    top: 20%;
  }
}
.single_product .cont_prod {
  margin-top: -40px;
}
.single_product .cont_prod > .col {
  margin-top: -40px;
}
.single_product .cont_prod article {
  width: 100%;
  padding: 250px 0 250px 33%;
  box-sizing: border-box;
  font-family: 'donlowLight', sans-serif;
  font-size: 15px;
  font-size: 0.9375em;
  font-size: 0.9375rem;
  line-height: 1.4em;
  color: rgba(40, 40, 40, .4);
}
.single_product .cont_prod article .cont_article {
  margin-bottom: 38px;
}
.single_product .cont_prod article blockquote {
  font-family: 'donlowLight', sans-serif;
  font-size: 30px;
  font-size: 1.875em;
  font-size: 1.875rem;
  color: #282828;
  font-weight: normal;
  line-height: 1.15em;
  margin-bottom: 28px;
  text-align: left;
  padding-right: 80px;
}
.single_product .cont_prod article blockquote p {
  text-align: left;
  padding: 0;
}
.single_product .cont_prod article p {
  padding: 0 30% 0 0;
  box-sizing: border-box;
}
.single_product .cont_prod article .extra_text {
  padding: 20px 0;
  width: 100%;
}
.single_product .cont_prod article .extra_text h3 {
  display: block;
  font-size: 12px;
  font-size: 0.75em;
  font-size: 0.75rem;
  font-weight: normal;
  line-height: 20px;
  font-family: 'donlowLight', sans-serif;
  color: #b4a69d;
}
.single_product .cont_prod article .extra_text h3:after {
  display: inline-block;
  content: "";
  width: 34px;
  height: 2px;
  margin: 2px 12px;
  background: #b4a69d;
}
.single_product .cont_prod article .extra_text b {
  display: block;
  font-size: 18px;
  font-size: 1.125em;
  font-size: 1.125rem;
  line-height: 1.4em;
  font-family: 'Cervo-Light', serif;
  font-weight: normal;
  color: #282828;
}
.single_product .cont_prod article .extra_text a {
  margin: 20px 0;
  text-transform: none !important;
  font-weight: 700;
  font-size: 13px;
  font-size: 0.8125em;
  font-size: 0.8125rem;
}
.single_product .cont_prod article .extra_text [data-module="button"][data-type="back_offset"] {
  margin-top: -1em;
}
.single_product .cont_prod article .extra_text [data-module="button"][data-type="back_offset"] .btn-content .label-btn b {
  font-size: 12px;
  font-size: 0.75em;
  font-size: 0.75rem;
  letter-spacing: 0px;
  font-weight: normal;
  font-family: 'donlowLight', sans-serif;
}
.single_product .cont_prod article .download h3 {
  display: block;
  font-size: 12px;
  font-size: 0.75em;
  font-size: 0.75rem;
  font-weight: normal;
  line-height: 20px;
  font-family: 'donlowLight', sans-serif;
  color: #b4a69d;
}
.single_product .cont_prod article .download h3:after {
  display: inline-block;
  content: "";
  width: 34px;
  height: 2px;
  margin: 2px 12px;
  background: #b4a69d;
}
.single_product .cont_prod article .download a {
  margin: 20px 0;
  text-transform: none !important;
  font-weight: 700;
  font-size: 13px;
  font-size: 0.8125em;
  font-size: 0.8125rem;
}
.single_product .cont_prod article .download [data-module="button"][data-type="back_offset"] .btn-content .label-btn b {
  font-size: 12px;
  font-size: 0.75em;
  font-size: 0.75rem;
  letter-spacing: 0px;
  font-weight: normal;
}
@media (max-width: 544px) {
  .single_product .cont_prod article {
    padding: 100px 4%;
  }
  .single_product .cont_prod article p {
    padding: 0 15% 0 0;
  }
}
.single_project .intro_prod .col-intro-text {
  width: 60%;
  float: left;
  position: relative;
  z-index: 7;
  padding-top: 180px;
}
.single_project .intro_prod .col-intro-text header {
  position: relative;
  float: left;
  width: 45%;
  height: auto;
  top: 50%;
  overflow-x: hidden;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 27.5%;
  box-sizing: border-box;
}
.single_project .intro_prod .col-intro-text header .pre_title {
  display: block;
  float: left;
  clear: both;
  position: relative;
  font-size: 12px;
  font-size: 0.75em;
  font-size: 0.75rem;
  font-weight: normal;
  line-height: 20px;
  font-family: 'donlowLight', sans-serif;
  color: #b4a69d;
  margin-bottom: 15px;
  padding: 5px 0;
}
.single_project .intro_prod .col-intro-text header .pre_title:after {
  display: inline-block;
  content: "";
  width: 34px;
  height: 2px;
  margin: 2px 12px;
  background: #b4a69d;
}
.single_project .intro_prod .col-intro-text header .title {
  display: block;
  float: left;
  clear: both;
  position: relative;
  font-size: 55px;
  font-size: 3.4375em;
  font-size: 3.4375rem;
  line-height: 1.2em;
  font-family: 'Cervo-Light', serif;
  color: #282828;
  padding: 5px 0;
  font-weight: normal;
}
.single_project .intro_prod .col-intro-text header .pre_title, .single_project .intro_prod .col-intro-text header .title {
  overflow: hidden;
}
.single_project .intro_prod .col-intro-text header .pre_title span, .single_project .intro_prod .col-intro-text header .title span {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 7;
  width: 100%;
  height: 100%;
  background: #282828;
}
.single_project .intro_prod .col-intro-text .sub_title {
  position: absolute;
  font-family: 'donlowLight', sans-serif;
  font-size: 200px;
  font-size: 12.5em;
  font-size: 12.5rem;
  font-weight: bold;
  line-height: 0.8em;
  color: rgba(40, 40, 40, .04);
  -webkit-transform: rotate(-90deg) translateY(22%);
  -moz-transform: rotate(-90deg) translateY(22%);
  -ms-transform: rotate(-90deg) translateY(22%);
  -o-transform: rotate(-90deg) translateY(22%);
  transform: rotate(-90deg) translateY(22%);
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  -o-transform-origin: center center;
  transform-origin: center center;
  top: 25%;
  left: 0;
  text-align: right;
}
.single_project .intro_prod .col-intro-img {
  width: 67%;
  float: left;
  position: absolute;
  z-index: 1;
  right: 0;
}
.single_project .intro_prod [data-module="inclusion"][data-type="image"] {
  padding-top: 180px;
}
.single_project .intro_prod [data-module="inclusion"][data-type="image"] .container {
  max-width: 100% !important;
  padding-left: 0;
  padding-right: 0;
  position: relative;
}
.single_project .intro_prod [data-module="inclusion"][data-type="image"] .image {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
}
.single_project .intro_prod [data-module="inclusion"][data-type="image"] .overlay {
  opacity: 1;
  filter: alpha(opacity=100);
  background: #b4a69d;
}
.single_project .intro_prod .scroll_down_line {
  position: absolute;
  bottom: 0;
  left: 16.5%;
}
@media (max-width: 768px) {
  .single_project .intro_prod .col-intro-text, .single_project .intro_prod .col-intro-img {
    position: relative;
    width: 100%;
  }
  .single_project .intro_prod .col-intro-text header {
    width: 90%;
    left: 5%;
  }
  .single_project .intro_prod [data-module="inclusion"][data-type="image"] {
    padding-top: 0;
    width: 100%;
    height: 100%;
    margin-top: 60px;
  }
}
@media (max-width: 544px) {
  .single_project .intro_prod .col-intro-text, .single_project .intro_prod .col-intro-img {
    width: 100%;
  }
  .single_project .intro_prod .col-intro-text .sub_title {
    font-size: 140px;
    font-size: 8.75em;
    font-size: 8.75rem;
    -webkit-transform: rotate(-90deg) translateY(-22%);
    -moz-transform: rotate(-90deg) translateY(-22%);
    -ms-transform: rotate(-90deg) translateY(-22%);
    -o-transform: rotate(-90deg) translateY(-22%);
    transform: rotate(-90deg) translateY(-22%);
    top: 20%;
  }
}
.single_project .cont_prod {
  padding: 130px 0;
}
.single_project .cont_prod article {
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  font-family: 'donlowLight', sans-serif;
  font-size: 15px;
  font-size: 0.9375em;
  font-size: 0.9375rem;
  line-height: 1.4em;
  color: rgba(40, 40, 40, .4);
}
.single_project .cont_prod article h3 {
  font-family: 'donlowLight', sans-serif;
  font-size: 30px;
  font-size: 1.875em;
  font-size: 1.875rem;
  color: #282828;
  font-weight: normal;
  margin-bottom: 1.1em;
  line-height: 1.15em;
}
.single_project .cont_prod article p {
  box-sizing: border-box;
}
.single_project .cont_prod article .download {
  margin-top: 28px;
}
.single_project .cont_prod article .download a {
  margin: 20px 0;
  text-transform: none !important;
  font-weight: 700;
  font-size: 13px;
  font-size: 0.8125em;
  font-size: 0.8125rem;
}
.single_project .cont_prod article .download [data-module="button"][data-type="back_offset"] .btn-content .label-btn b {
  font-size: 12px;
  font-size: 0.75em;
  font-size: 0.75rem;
  letter-spacing: 0px;
  font-weight: normal;
}
.single_project .cont_prod .col-img img {
  display: block;
  height: auto;
  padding: 0;
}
.single_project .cont_prod .col-img .bck {
  position: relative;
  height: 400px;
  background-size: cover;
  background-position: center center;
}
.single_project .cont_prod.even .col-text {
  float: left;
}
.single_project .cont_prod.even .col-text [data-module="button"][data-type="back_offset"] .btn-content {
  float: right;
}
.single_project .cont_prod.even article {
  padding: 0 8%;
  text-align: right;
}
.single_project .cont_prod.even article p {
  padding: 0 0 0 30%;
}
.single_project .cont_prod.even .col-img {
  float: right;
  padding-left: 0;
}
.single_project .cont_prod.even .col-img img, .single_project .cont_prod.even .col-img .bck {
  width: 92%;
  margin: 0;
}
.single_project .cont_prod.odd .col-text {
  float: right;
  padding-left: 0;
}
.single_project .cont_prod.odd article {
  padding: 0 16% 0 0;
}
.single_project .cont_prod.odd article p {
  padding: 0 30% 0 0;
}
.single_project .cont_prod.odd .col-img {
  float: left;
}
.single_project .cont_prod.odd .col-img img, .single_project .cont_prod.odd .col-img .bck {
  width: 84%;
  margin: 0 8%;
}
@media (max-width: 768px) {
  .single_project .cont_prod {
    padding: 80px 6%;
  }
  .single_project .cont_prod.even .col-text, .single_project .cont_prod.odd .col-text {
    padding: 0 30px;
  }
  .single_project .cont_prod.even article, .single_project .cont_prod.odd article {
    padding: 0;
  }
  .single_project .cont_prod.even article p, .single_project .cont_prod.odd article p {
    padding: 0;
  }
  .single_project .cont_prod.even .col-img, .single_project .cont_prod.odd .col-img {
    padding: 0 30px;
  }
  .single_project .cont_prod.even .col-img img, .single_project .cont_prod.odd .col-img img, .single_project .cont_prod.even .col-img .bck, .single_project .cont_prod.odd .col-img .bck {
    width: 100%;
    margin: 0;
  }
}
@media (max-width: 544px) {
  .single_project .cont_prod article {
    padding: 100px 4%;
  }
  .single_project .cont_prod article p {
    padding: 0 15% 0 0;
  }
}
.single_project [data-module="matrix"][data-type="vertical_gallery"] figure {
  margin-bottom: -35px;
}
.single_project [data-module="matrix"][data-type="vertical_gallery"] figure.img_panor.small {
  width: 45%;
  margin-left: 5%;
}
.single_project [data-module="matrix"][data-type="vertical_gallery"] figure.img_panor.big {
  width: 58%;
  margin-right: 0%;
}
.single_project [data-module="matrix"][data-type="vertical_gallery"] figure.img_vert.small {
  width: 30%;
  margin-left: 14%;
}
.single_project [data-module="matrix"][data-type="vertical_gallery"] figure.img_vert.big {
  width: 50%;
  margin-right: 6%;
}
.single_project [data-module="matrix"][data-type="vertical_gallery"] figure.z_top {
  z-index: 10;
  margin-top: -20px;
  margin-bottom: -55px;
}
@media (max-width: 768px) {
  .single_project [data-module="matrix"][data-type="vertical_gallery"] .detect.vert_gall_mob {
    display: none;
  }
}
.single_collection [data-module="inclusion"][data-type="text_col"] {
  padding-top: 150px;
}
.single_collection [data-module="inclusion"][data-type="text_col"] .container-title {
  background: none !important;
}
.single_collection [data-module="inclusion"][data-type="text_col"] .pre_title {
  display: block;
  font-size: 12px;
  font-size: 0.75em;
  font-size: 0.75rem;
  line-height: 20px;
  font-family: 'donlowLight', sans-serif;
  color: #b4a69d;
  text-align: center;
  text-transform: none;
  font-weight: normal;
  opacity: 1;
  filter: alpha(opacity=100);
  letter-spacing: normal;
}
.single_collection [data-module="inclusion"][data-type="text_col"] .pre_title:after {
  display: inline-block;
  content: "";
  width: 34px;
  height: 2px;
  margin: 2px 12px;
  background: #b4a69d;
}
.single_collection [data-module="inclusion"][data-type="text_col"] .title {
  font-size: 74px;
  font-size: 4.625em;
  font-size: 4.625rem;
  margin: 20px 0;
}
.single_collection [data-module="inclusion"][data-type="text_col"] .sub-title {
  color: rgba(40, 40, 40, .5);
  font-size: 20px;
  font-size: 1.25em;
  font-size: 1.25rem;
  padding: 0 25%;
}
@media (max-width: 544px) {
  .single_collection [data-module="inclusion"][data-type="text_col"] {
    padding-top: 100px;
  }
  .single_collection [data-module="inclusion"][data-type="text_col"] .title {
    font-size: 40px;
    font-size: 2.5em;
    font-size: 2.5rem;
  }
}
.single_collection .cont_article {
  margin-top: -140px;
  margin-bottom: 38px;
  position: relative;
  z-index: 10;
}
.single_collection .cont_article article {
  width: 45%;
  padding: 65px 84px;
  margin: 0 12%;
  box-sizing: border-box;
  font-family: 'donlowLight', sans-serif;
  font-size: 15px;
  font-size: 0.9375em;
  font-size: 0.9375rem;
  line-height: 1.4em;
  color: rgba(40, 40, 40, .4);
  background: #fff;
}
.single_collection .cont_article article blockquote {
  font-family: 'donlowLight', sans-serif;
  font-size: 30px;
  font-size: 1.875em;
  font-size: 1.875rem;
  color: #282828;
  font-weight: normal;
  line-height: 1.15em;
  margin-bottom: 28px;
  text-align: left;
  padding-right: 0px;
}
.single_collection .cont_article article blockquote p {
  padding: 0;
  line-height: 0.9em;
  text-align: left;
}
.single_collection .cont_article article p {
  padding: 0;
  box-sizing: border-box;
  text-align: justify;
  line-height: 1.6em;
}
.single_collection .cont_article article .extra_text {
  padding: 20px 0;
  width: 100%;
}
.single_collection .cont_article article .extra_text h3 {
  display: block;
  font-size: 12px;
  font-size: 0.75em;
  font-size: 0.75rem;
  font-weight: normal;
  line-height: 20px;
  font-family: 'donlowLight', sans-serif;
  color: #b4a69d;
}
.single_collection .cont_article article .extra_text h3:after {
  display: inline-block;
  content: "";
  width: 34px;
  height: 2px;
  margin: 2px 12px;
  background: #b4a69d;
}
.single_collection .cont_article article .extra_text b {
  display: block;
  font-size: 18px;
  font-size: 1.125em;
  font-size: 1.125rem;
  line-height: 1.4em;
  font-family: 'Cervo-Light', serif;
  font-weight: normal;
  color: #282828;
}
.single_collection .cont_article article .download a {
  margin: 20px 0;
  text-transform: none !important;
  font-weight: 700;
  font-size: 13px;
  font-size: 0.8125em;
  font-size: 0.8125rem;
}
.single_collection .cont_article article .download [data-module="button"][data-type="back_offset"] .btn-content .label-btn b {
  font-size: 12px;
  font-size: 0.75em;
  font-size: 0.75rem;
  letter-spacing: 0px;
  font-weight: normal;
}
@media (max-width: 768px) {
  .single_collection .cont_article article {
    width: 70%;
    margin: 0 10%;
  }
  .single_collection .cont_article article p {
    padding: 0 15% 0 0;
  }
}
@media (max-width: 544px) {
  .single_collection .cont_article {
    margin-left: 0;
    margin-right: 0;
  }
  .single_collection .cont_article article {
    padding: 100px 4%;
    width: 92%;
    margin: 0 4%;
  }
  .single_collection .cont_article article p {
    padding: 0;
  }
  .single_collection .cont_article article blockquote {
    font-size: 20px;
    font-size: 1.25em;
    font-size: 1.25rem;
  }
}
.single_collection .cont_gallery {
  position: relative;
  margin: 100px 12%;
  width: 76%;
  z-index: 14;
}
.single_collection .cont_gallery .big_title {
  position: absolute;
  font-family: 'donlowLight', sans-serif;
  font-size: 200px;
  font-size: 12.5em;
  font-size: 12.5rem;
  font-weight: bold;
  line-height: 0.8em;
  color: rgba(40, 40, 40, .04);
  top: -100%;
  right: -15%;
  text-align: right;
}
@media (max-width: 768px) {
  .single_collection .cont_gallery .big_title {
    font-size: 150px;
    font-size: 9.375em;
    font-size: 9.375rem;
  }
}
@media (max-width: 544px) {
  .single_collection .cont_gallery {
    margin: 80px 0%;
    width: 100%;
    padding: 0 5%;
  }
  .single_collection .cont_gallery .big_title {
    font-size: 100px;
    font-size: 6.25em;
    font-size: 6.25rem;
  }
}
.single_post .cont_article {
  margin-top: -788px;
  margin-bottom: 38px;
  position: relative;
  z-index: 10;
}
.single_post .cont_article article, .single_post .cont_article figure {
  width: 45%;
  padding: 65px 84px;
  margin: 0 12%;
}
.single_post .cont_article figure {
  height: 600px;
  margin-bottom: 50px;
  background-size: cover;
  background-position: center center;
}
@media (max-width: 768px) {
  .single_post .cont_article article, .single_post .cont_article figure {
    width: 100%;
    margin: 0;
  }
  .single_post .cont_article article p, .single_post .cont_article figure p {
    padding: 0;
  }
}
@media (max-width: 544px) {
  .single_post .cont_article {
    margin-left: 0;
    margin-right: 0;
  }
  .single_post .cont_article article, .single_post .cont_article figure {
    padding: 100px 4%;
    width: 92%;
    margin: 0 4%;
  }
  .single_post .cont_article article p, .single_post .cont_article figure p {
    padding: 0 15% 0 0;
  }
}
.single_page .cont_article {
  margin-top: -788px;
  margin-bottom: 38px;
  position: relative;
  z-index: 10;
}
.single_page .cont_article article, .single_page .cont_article figure {
  width: 76%;
  padding: 65px 84px;
  margin: 0 12%;
}
.single_page .cont_article figure {
  height: 600px;
  margin-bottom: 50px;
  background-size: cover;
  background-position: center center;
}
.single_page .cont_article article {
  font-size: 15px;
  font-size: 0.9375em;
  font-size: 0.9375rem;
  line-height: 1.4em;
  color: rgba(40, 40, 40, 1);
  background: #fff;
}
@media (max-width: 768px) {
  .single_page .cont_article article, .single_page .cont_article figure {
    width: 100%;
    margin: 0;
  }
  .single_page .cont_article article p, .single_page .cont_article figure p {
    padding: 0;
  }
}
@media (max-width: 544px) {
  .single_page .cont_article {
    margin-left: 0;
    margin-right: 0;
  }
  .single_page .cont_article article, .single_page .cont_article figure {
    padding: 100px 4%;
    width: 92%;
    margin: 0 4%;
  }
  .single_page .cont_article article p, .single_page .cont_article figure p {
    padding: 0 15% 0 0;
  }
}
.archive .page_content {
  padding: 0;
}
.archive .scroll_down_line {
  position: absolute;
  top: 0;
  left: 50%;
}
.archive .big_title {
  position: absolute;
  font-family: 'donlowLight', sans-serif;
  font-size: 200px;
  font-size: 12.5em;
  font-size: 12.5rem;
  font-weight: bold;
  line-height: 0.8em;
  color: rgba(40, 40, 40, .04);
  -webkit-transform: rotate(-90deg) translateY(22%);
  -moz-transform: rotate(-90deg) translateY(22%);
  -ms-transform: rotate(-90deg) translateY(22%);
  -o-transform: rotate(-90deg) translateY(22%);
  transform: rotate(-90deg) translateY(22%);
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  -o-transform-origin: center center;
  transform-origin: center center;
  top: 2.5%;
  left: 19.4%;
  text-align: right;
}
@media (max-width: 544px) {
  .archive .big_title {
    font-size: 140px;
    font-size: 8.75em;
    font-size: 8.75rem;
    -webkit-transform: rotate(-90deg) translateY(-22%);
    -moz-transform: rotate(-90deg) translateY(-22%);
    -ms-transform: rotate(-90deg) translateY(-22%);
    -o-transform: rotate(-90deg) translateY(-22%);
    transform: rotate(-90deg) translateY(-22%);
  }
}
.archive [data-module="matrix"][data-type="alternate_row"] {
  padding-top: 150px;
}
.archive [data-module="matrix"][data-type="alternate_row"] .overlay {
  background: rgba(40, 40, 40, .48);
}
.archive [data-module="matrix"][data-type="alternate_row"] .hover_text {
  display: none;
}
.archive [data-module="matrix"][data-type="alternate_row"] .pagination {
  padding-left: 83%;
}
.archive [data-module="matrix"][data-type="alternate_row"] .pagination ul {
  padding: 0;
}
.archive [data-module="matrix"][data-type="alternate_row"] .pagination ul li a {
  opacity: 0;
  color: #000;
  border: 1px solid #ccc;
  background-color: transparent;
  margin: 1.5px 0;
  -webkit-transition: border 0.4s ease-in-out;
  -moz-transition: border 0.4s ease-in-out;
  transition: border 0.4s ease-in-out;
}
.archive [data-module="matrix"][data-type="alternate_row"] .pagination ul li a:focus, .archive [data-module="matrix"][data-type="alternate_row"] .pagination ul li a:hover {
  border: 1px solid #000;
}
.archive [data-module="matrix"][data-type="alternate_row"] .pagination a.pagination-control-current {
  border: 1px solid #000;
}
.archive_material .intro_prod .col-intro-text {
  width: 60%;
  float: left;
  position: relative;
  z-index: 7;
  padding-top: 180px;
}
.archive_material .intro_prod .col-intro-text header {
  position: relative;
  float: left;
  width: 45%;
  height: auto;
  top: 50%;
  overflow-x: hidden;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 27.5%;
  box-sizing: border-box;
}
.archive_material .intro_prod .col-intro-text header .pre_title {
  display: block;
  float: left;
  clear: both;
  position: relative;
  font-size: 12px;
  font-size: 0.75em;
  font-size: 0.75rem;
  font-weight: normal;
  line-height: 20px;
  font-family: 'donlowLight', sans-serif;
  color: #b4a69d;
  margin-bottom: 15px;
  padding: 5px 0;
}
.archive_material .intro_prod .col-intro-text header .pre_title:after {
  display: inline-block;
  content: "";
  width: 34px;
  height: 2px;
  margin: 2px 12px;
  background: #b4a69d;
}
.archive_material .intro_prod .col-intro-text header .title {
  display: block;
  float: left;
  clear: both;
  position: relative;
  font-size: 55px;
  font-size: 3.4375em;
  font-size: 3.4375rem;
  line-height: 1.2em;
  font-family: 'Cervo-Light', serif;
  color: #282828;
  padding: 5px 0;
  font-weight: normal;
}
.archive_material .intro_prod .col-intro-text header .pre_title, .archive_material .intro_prod .col-intro-text header .title {
  overflow: hidden;
}
.archive_material .intro_prod .col-intro-text header .pre_title span, .archive_material .intro_prod .col-intro-text header .title span {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 7;
  width: 100%;
  height: 100%;
  background: #282828;
}
.archive_material .intro_prod .col-intro-text .sub_title {
  position: absolute;
  font-family: 'donlowLight', sans-serif;
  font-size: 200px;
  font-size: 12.5em;
  font-size: 12.5rem;
  font-weight: bold;
  line-height: 0.8em;
  color: rgba(40, 40, 40, .04);
  -webkit-transform: rotate(-90deg) translateY(22%);
  -moz-transform: rotate(-90deg) translateY(22%);
  -ms-transform: rotate(-90deg) translateY(22%);
  -o-transform: rotate(-90deg) translateY(22%);
  transform: rotate(-90deg) translateY(22%);
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  -o-transform-origin: center center;
  transform-origin: center center;
  top: 25%;
  left: 0;
  text-align: right;
}
.archive_material .intro_prod .col-intro-img {
  width: 67%;
  float: left;
  position: absolute;
  z-index: 1;
  right: 0;
}
.archive_material .intro_prod [data-module="inclusion"][data-type="image"] {
  padding-top: 180px;
}
.archive_material .intro_prod [data-module="inclusion"][data-type="image"] .container {
  max-width: 100% !important;
  padding-left: 0;
  padding-right: 0;
  position: relative;
}
.archive_material .intro_prod [data-module="inclusion"][data-type="image"] .image {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
}
.archive_material .intro_prod [data-module="inclusion"][data-type="image"] .overlay {
  opacity: 1;
  filter: alpha(opacity=100);
  background: #b4a69d;
}
.archive_material .intro_prod .scroll_down_line {
  position: absolute;
  bottom: 0;
  left: 16.5%;
}
@media (max-width: 768px) {
  .archive_material .intro_prod .col-intro-text, .archive_material .intro_prod .col-intro-img {
    position: relative;
    width: 100%;
  }
  .archive_material .intro_prod .col-intro-text header {
    width: 90%;
    left: 5%;
  }
  .archive_material .intro_prod [data-module="inclusion"][data-type="image"] {
    padding-top: 0;
    width: 100%;
    height: 100%;
    margin-top: 60px;
  }
}
@media (max-width: 544px) {
  .archive_material .intro_prod .col-intro-text, .archive_material .intro_prod .col-intro-img {
    width: 100%;
  }
  .archive_material .intro_prod .col-intro-text .sub_title {
    font-size: 140px;
    font-size: 8.75em;
    font-size: 8.75rem;
    -webkit-transform: rotate(-90deg) translateY(-22%);
    -moz-transform: rotate(-90deg) translateY(-22%);
    -ms-transform: rotate(-90deg) translateY(-22%);
    -o-transform: rotate(-90deg) translateY(-22%);
    transform: rotate(-90deg) translateY(-22%);
    top: 20%;
  }
}
.archive_material .cont_prod {
  padding: 130px 0;
}
.archive_material .cont_prod article {
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  font-family: 'donlowLight', sans-serif;
  font-size: 15px;
  font-size: 0.9375em;
  font-size: 0.9375rem;
  line-height: 1.4em;
  color: rgba(40, 40, 40, .4);
}
.archive_material .cont_prod article h3 {
  font-family: 'donlowLight', sans-serif;
  font-size: 30px;
  font-size: 1.875em;
  font-size: 1.875rem;
  color: #282828;
  font-weight: normal;
  margin-bottom: 1.1em;
  line-height: 1.15em;
}
.archive_material .cont_prod article p {
  box-sizing: border-box;
}
.archive_material .cont_prod article .download {
  margin-top: 28px;
}
.archive_material .cont_prod article .download a {
  margin: 20px 0;
  text-transform: none !important;
  font-weight: 700;
  font-size: 13px;
  font-size: 0.8125em;
  font-size: 0.8125rem;
}
.archive_material .cont_prod article .download [data-module="button"][data-type="back_offset"] .btn-content .label-btn b {
  font-size: 12px;
  font-size: 0.75em;
  font-size: 0.75rem;
  letter-spacing: 0px;
  font-weight: normal;
}
.archive_material .cont_prod .col-img img {
  display: block;
  height: auto;
  padding: 0;
}
.archive_material .cont_prod .col-img .bck {
  position: relative;
  height: 400px;
  background-size: cover;
  background-position: center center;
}
.archive_material .cont_prod.even .col-text {
  float: left;
}
.archive_material .cont_prod.even .col-text [data-module="button"][data-type="back_offset"] .btn-content {
  float: right;
}
.archive_material .cont_prod.even article {
  padding: 0 8%;
  text-align: right;
}
.archive_material .cont_prod.even article p {
  padding: 0 0 0 30%;
}
.archive_material .cont_prod.even .col-img {
  float: right;
  padding-left: 0;
}
.archive_material .cont_prod.even .col-img img, .archive_material .cont_prod.even .col-img .bck {
  width: 92%;
  margin: 0;
}
.archive_material .cont_prod.odd .col-text {
  float: right;
  padding-left: 0;
}
.archive_material .cont_prod.odd article {
  padding: 0 16% 0 0;
}
.archive_material .cont_prod.odd article p {
  padding: 0 30% 0 0;
}
.archive_material .cont_prod.odd .col-img {
  float: left;
}
.archive_material .cont_prod.odd .col-img img, .archive_material .cont_prod.odd .col-img .bck {
  width: 84%;
  margin: 0 8%;
}
@media (max-width: 768px) {
  .archive_material .cont_prod {
    padding: 130px 6%;
  }
  .archive_material .cont_prod.even .col-text, .archive_material .cont_prod.odd .col-text {
    padding: 0 30px;
  }
  .archive_material .cont_prod.even article, .archive_material .cont_prod.odd article {
    padding: 0;
  }
  .archive_material .cont_prod.even article p, .archive_material .cont_prod.odd article p {
    padding: 0;
  }
  .archive_material .cont_prod.even .col-img, .archive_material .cont_prod.odd .col-img {
    padding: 0 30px;
  }
  .archive_material .cont_prod.even .col-img img, .archive_material .cont_prod.odd .col-img img, .archive_material .cont_prod.even .col-img .bck, .archive_material .cont_prod.odd .col-img .bck {
    width: 100%;
    margin: 0;
  }
}
@media (max-width: 544px) {
  .archive_material .cont_prod article {
    padding: 100px 4%;
  }
  .archive_material .cont_prod article p {
    padding: 0 15% 0 0;
  }
  .archive_material .cont_prod .col-img {
    width: 100%;
  }
}
.archive_material_cat .big_title {
  top: 14%;
}
.archive_material_cat [data-module="matrix"][data-type="alternate_row"] .hover_text {
  display: block !important;
  position: absolute;
  overflow: hidden;
  top: 40%;
  left: -70px;
  width: 100px;
  height: 100px;
  color: #333;
  font-family: 'donlowLight', sans-serif;
  font-size: 16px;
  font-size: 1em;
  font-size: 1rem;
}
.archive_material_cat [data-module="matrix"][data-type="alternate_row"] .hover_text b {
  display: block;
  position: absolute;
  left: -20px;
  height: 0;
  width: 100px;
  -webkit-transform: rotate(-90deg) translateX(-100px);
  -moz-transform: rotate(-90deg) translateX(-100px);
  -ms-transform: rotate(-90deg) translateX(-100px);
  -o-transform: rotate(-90deg) translateX(-100px);
  transform: rotate(-90deg) translateX(-100px);
  -webkit-transform-origin: center left;
  -moz-transform-origin: center left;
  -ms-transform-origin: center left;
  -o-transform-origin: center left;
  transform-origin: center left;
  font-weight: normal;
  text-align: center;
  margin: 0;
  padding: 0;
  opacity: 1;
}
.archive_material_cat [data-module="matrix"][data-type="alternate_row"] .hover_text span {
  position: absolute;
  top: 50%;
  left: 36px;
  width: 64px;
  height: 1px;
  overflow: hidden;
}
.archive_material_cat [data-module="matrix"][data-type="alternate_row"] .hover_text span strong {
  display: block;
  position: relative;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #333;
}
.archive_material_cat [data-module="matrix"][data-type="alternate_row"] .wrapper-text .container-text .sub_title {
  color: #b4a69d;
  font-family: 'donlowLight', sans-serif;
  font-size: 13px;
  font-size: 0.8125em;
  font-size: 0.8125rem;
}
.archive_material_cat [data-module="matrix"][data-type="alternate_row"] .wrapper-text .container-text .sub_title b {
  padding-top: 4px;
}
.archive_material_cat [data-module="matrix"][data-type="alternate_row"] .even.rowA .hover_text, .archive_material_cat [data-module="matrix"][data-type="alternate_row"] .odd.rowB .hover_text {
  top: 65%;
}
[data-page="contacts"] {
  padding-top: 150px;
}
[data-page="contacts"] [data-module="form"][data-type="base"] .text-content h2 {
  font-family: 'Cervo-Light', serif;
  font-size: 21px;
  font-size: 1.3125em;
  font-size: 1.3125rem;
}
[data-page="contacts"] [data-module="form"][data-type="base"] .text-content p {
  font-family: 'donlowLight', sans-serif;
  font-size: 17px;
  font-size: 1.0625em;
  font-size: 1.0625rem;
}
[data-page="contacts"] [data-module="form"][data-type="base"] .text-content p:empty {
  display: none;
}
[data-page="contacts"] [data-module="form"][data-type="base"] .social {
  text-align: left;
  margin-top: 0;
}
[data-page="contacts"] [data-module="form"][data-type="base"] .social a {
  margin: 0 20px 0 0;
  width: auto;
  height: 28px;
  line-height: 28px;
  border: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  text-align: left;
  font-size: 14px;
  font-size: 0.875em;
  font-size: 0.875rem;
}
html.no-touch [data-page="contacts"] [data-module="form"][data-type="base"] .social a:hover {
  text-decoration: none;
  border: 0;
  color: #b4a69d;
}
html.touch [data-page="contacts"] [data-module="form"][data-type="base"] .social a:focus {
  text-decoration: none;
  border: 0;
  color: #b4a69d;
}
.next_post_row [data-module="inclusion"][data-type="text_link"] {
  width: 100%;
  height: 205px;
  overflow: hidden;
  padding: 0;
  opacity: 1;
}
.next_post_row [data-module="inclusion"][data-type="text_link"] .container {
  max-width: 100% !important;
}
.next_post_row [data-module="inclusion"][data-type="text_link"] .container .content, .next_post_row [data-module="inclusion"][data-type="text_link"] .container .container-title {
  position: relative;
  width: 100%;
  height: 100% !important;
}
.next_post_row [data-module="inclusion"][data-type="text_link"] a {
  font-family: 'Cervo-Light', serif;
  color: #282828;
  overflow: hidden;
}
.next_post_row [data-module="inclusion"][data-type="text_link"] .title, .next_post_row [data-module="inclusion"][data-type="text_link"] .sub-title, .next_post_row [data-module="inclusion"][data-type="text_link"] .pre-title {
  display: block;
  position: absolute;
  margin: 0;
  text-align: center;
}
.next_post_row [data-module="inclusion"][data-type="text_link"] .title {
  width: auto;
  top: 55%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 34px;
  font-size: 2.125em;
  font-size: 2.125rem;
  color: #282828;
  z-index: 7;
  overflow: hidden;
  padding: 5px 0;
  margin: 1em 0 0 0;
}
.next_post_row [data-module="inclusion"][data-type="text_link"] .title span {
  display: block;
  position: absolute;
  top: 0;
  right: 115%;
  width: 100%;
  height: 100%;
  background: #282828;
}
@media (max-width: 768px) {
  .next_post_row [data-module="inclusion"][data-type="text_link"] .title span {
    display: none;
  }
}
.archive_material_cat .next_post_row [data-module="inclusion"][data-type="text_link"] .title {
  text-transform: none;
}
.next_post_row [data-module="inclusion"][data-type="text_link"] .pre-title {
  display: block;
  float: left;
  clear: both;
  position: relative;
  top: 55%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 12px;
  font-size: 0.75em;
  font-size: 0.75rem;
  font-weight: normal;
  line-height: 20px;
  font-family: 'donlowLight', sans-serif;
  color: #b4a69d;
  padding: 5px 0;
}
.next_post_row [data-module="inclusion"][data-type="text_link"] .sub-title {
  width: 100%;
  font-family: 'donlowLight', sans-serif;
  bottom: -20px;
  line-height: 0.5em;
  letter-spacing: 10px;
  font-size: 250px;
  font-size: 15.625em;
  font-size: 15.625rem;
  color: rgba(40, 40, 40, .06);
  text-transform: none;
}
@media (max-width: 544px) {
  .next_post_row [data-module="inclusion"][data-type="text_link"] {
    height: 105px;
  }
  .next_post_row [data-module="inclusion"][data-type="text_link"] .title {
    font-size: 20px;
    font-size: 1.25em;
    font-size: 1.25rem;
  }
  .next_post_row [data-module="inclusion"][data-type="text_link"] .sub-title {
    font-size: 150px;
    font-size: 9.375em;
    font-size: 9.375rem;
  }
}
#area_riservata_loginForm, .area_riservata_form {
  font-family: 'Cervo-Light', serif;
  padding: 0 25%;
}
#area_riservata_loginForm label, .area_riservata_form label {
  display: block;
  width: 100%;
  line-height: 1.1em;
  padding: 5px 0;
  margin: 10px 0 5px;
  font-size: 14px;
  font-size: 0.875em;
  font-size: 0.875rem;
  color: #333;
  text-align: left;
}
#area_riservata_loginForm input:not([type='checkbox']), .area_riservata_form input:not([type='checkbox']), #area_riservata_loginForm textarea, .area_riservata_form textarea {
  width: 100%;
  background: #f2f2f2;
  border: none;
  line-height: 1.4em;
  padding: 10px;
  border: 1px solid #f2f2f2;
  color: #333;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  -webkit-appearance: none;
}
#area_riservata_loginForm input[type="submit"], .area_riservata_form input[type="submit"] {
  background: #ccc;
}
#area_riservata_loginForm input[type="hidden"], .area_riservata_form input[type="hidden"] {
  display: none;
  height: 0;
  width: 0;
  margin: 0;
}
#area_riservata_loginForm input:not([type='checkbox']):focus, .area_riservata_form input:not([type='checkbox']):focus, #area_riservata_loginForm textarea:focus, .area_riservata_form textarea:focus {
  outline: none;
  border: 1px solid #333;
  color: #333;
}
#area_riservata_loginForm input:not([type='checkbox']):hover, .area_riservata_form input:not([type='checkbox']):hover, #area_riservata_loginForm textarea:hover, .area_riservata_form textarea:hover {
  background: #ccc;
}
#area_riservata_loginForm input[type="submit"]:hover, .area_riservata_form input[type="submit"]:hover {
  background: #f2f2f2;
}
#area_riservata_loginForm .accessories, .area_riservata_form .accessories {
  font-family: 'donlowLight', sans-serif;
}
#area_riservata_loginForm .accessories a, .area_riservata_form .accessories a {
  color: #333;
}
html.no-touch #area_riservata_loginForm .accessories a:hover, html.no-touch .area_riservata_form .accessories a:hover {
  color: #333;
  text-decoration: underline;
}
html.touch #area_riservata_loginForm .accessories a:focus, html.touch .area_riservata_form .accessories a:focus {
  color: #333;
  text-decoration: underline;
}
.single-_cty_product .page_content.cont_related_prod [data-module="matrix"][data-type="alternate_row"] .hover_text {
  display: none;
}
.single-_cty_product .row.cont_prod.page_content.cont_related_prod {
  padding: 0;
}
.single-_cty_designer .single_collection .cont_article article p {
  padding: 0;
  line-height: 1.65em;
}
[data-module="nav"][data-type="top_side"] .menu__secondary address {
  position: relative;
  font-size: 17px;
  font-size: 1.0625em;
  font-size: 1.0625rem;
  color: #bababa;
  font-weight: 300;
}
@media screen and (max-width: 767px){
	[data-module="nav"][data-type="top_side"] .menu__secondary address {
		font-size: 16px;
		font-size: 1em;
		font-size: 1rem;		 
	}
}
[data-module="nav"][data-type="top_side"] .menu__secondary .contact2 {
  position: relative;
  font-size: 17px;
  font-size: 1.0625em;
  font-size: 1.0625rem;
  color: #bababa;
  font-weight: 300;
	
}
@media screen and (max-width: 767px){
	[data-module="nav"][data-type="top_side"] .menu__secondary .contact2 {
		font-size: 16px;
		font-size: 1em;
		font-size: 1rem;		 
	}
}
[data-module="nav"][data-type="top_side"] .menu__secondary .contact2 a {
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;
  color: #bababa;
}
[data-module="nav"][data-type="top_side"] .menu__secondary .contact2 a:hover {
  color: #000;
}
[data-module="nav"][data-type="top_side"] .menu__secondary .contact3 {
  position: relative;
  font-size: 10px;
  font-size: 0.625em;
  font-size: 0.625rem;
  color: #bababa;
  font-weight: 300;
}
[data-module="nav"][data-type="top_side"] .menu__secondary .nav-legal {
  position: relative;
  bottom: 0;
  top: auto;
  float: right;
}
[data-module="nav"][data-type="top_side"] .menu__secondary .nav-legal a {
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;
  color: #bababa;
}
[data-module="nav"][data-type="top_side"] .menu__secondary .nav-legal a:hover {
  color: #000;
}
@media (max-width: 768px) {
	[data-module="nav"][data-type="top_side"] .menu__secondary {
		position: relative;
		text-align: left;
		bottom: 0;
		width: 100%;
		padding: 20px 20px 20px 0;
		display: table;
	}
	[data-module=nav][data-type=top_side] .menu__secondary .nav-legal {
		float: left;
	}
}
[data-module="nav"][data-type="top_side"] .nav-lang a {
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;
}
[data-module="nav"][data-type="top_side"] .nav-lang a:hover {
  color: #000;
}
[data-module="nav"][data-type="top_side"] .nav-lang .active-lang a {
  color: #000;
}
@media (max-width: 544px) {
  [data-module="nav"][data-type="top_side"] .nav-lang {
    position: absolute;
    top: 120px !important;
    width: 50% !important;
    right: auto !important;
    height: 20px !important;
    padding-top: 0px !important;
    text-align: left;
    left: 8px !important;
  }
}
@media (max-width: 768px) {
  [data-module="nav"][data-type="top_side"] .nav-lang {
    top: 100px !important;
  }
}
@media (max-width: 544px) {
  [data-module="nav"][data-type="top_side"] .second-level-content {
    width: 70%;
  }
}
[data-module="nav"][data-type="top_side"] .second-level-content .menu__link b {
  padding: 0 7px;
}
@media (max-width: 544px) {
  [data-module="nav"][data-type="top_side"] .second-level-content .menu__link b {
    padding-right: 10px;
  }
}
.pswp__button {
  opacity: 0.9;
}
@media (max-width: 544px) {
  .big_title {
    display: none;
  }
}
@media (min-width: 1441px) and (max-width: 1600px) {
  [data-module="header"][data-type="with_text"] .logo {
    height: auto;    
  }
}
@media (min-width: 1601px) {
  [data-module="header"][data-type="with_text"] .logo {
    height: auto;
  }
}
