.downloads{
	padding: 50px 0;
  .download-row{
   td{
     cursor: pointer;
   }
  }
	.dld-language{
        display: table;
        margin: 0 auto;
    }
    .hover-cell{
        -moz-transition: all 0.3s;
         -o-transition: all 0.3s;
        -webkit-transition: all 0.3s;
        &:hover{
            background: rgba(0, 21, 77,0.2);
        }
    }
    .download-link{
        display: block;
        color: $blue;
        float: right;
        .icon{
            display: table;
            float: left;
            line-height: 1px;
            position: relative;
            top: -4px;
			@media only screen and (max-width: 480px){
				display: none;
			}
            svg{
                width:25px;
                height: 25px;
                fill:$blue;
                transform: rotate(180deg);
            }
        }
    }
}

.downloads-list{
	padding: 50px 0 0;
	margin:20px 0;
	background: $blue url(../img/patterns/pattern-6.png) 0 0 repeat;
	@media only screen and (max-width: 767px){
		padding: 25px 0 0;
	}

	.download-title{
    color:$white;
    font-size: 30px;
    margin-bottom: 20px;
    a{
      &:hover{
        color:$white;
        text-decoration: none;
      }
    }
    @media only screen and (max-width: 767px){
      font-size: 20px;
    }
    .collapse{
      display: block;
      .icon {
        font-family: donlowLight,serif;
        height: 35px;
        width: 35px;
        border: 1px solid $white;
        display: block;
        float: left;
        margin-right: 30px;
        color:$white;
        text-align: center;
        font-size: 60px;
        @media only screen and (max-width: 767px){
          margin-right: 30px;
        }
        @media only screen and (max-width: 450px){
          height: 30px;
          width: 30px;
          margin-right: 10px;
          font-size: 50px;
          margin-top: -5px;
        }
        .plus{
          display: block;
          line-height: 35px;
          @media only screen and (max-width: 450px){
            line-height: 30px;
          }
        }
        .minus {
          display: none;
          line-height: 27px;
          @media only screen and (max-width: 450px){
            line-height: 22px;
          }
        }
      }

      &.expanded{
        .plus{
          display: none;
        }
        .minus {
          display: block;

        }
      }
    }
  }
	
	.donwloads-filter{
		overflow-x: auto;
		overflow-y: hidden;	
		.mCSB_horizontal.mCSB_inside>.mCSB_container {
			margin-top: 20px;
			margin-bottom: 0;
		}
		.mCSB_scrollTools.mCSB_scrollTools_horizontal {
			top: 0;
			bottom: auto;
		}
		.nav-tabs{
			border: none;
			@media only screen and (max-width: 767px){
				white-space: nowrap;
			}
			li{
				@media only screen and (max-width: 767px){
					display: inline-block;
					float: none;
				}			    
				a{
					margin-right: 2px;
					line-height: 1.42857143;
					border:none;
					border-radius: 0;
					&:hover,&.active{
						background: $white;
						color: $blue;
						border:none;
					}
				}
			}
		}	
	}
	.scroll-controls{
		.arrow {
			background: #d9dde4;
			width: 50%;
			display: block;
			float: left;
			padding: 13px 5px;
			svg {
				margin: 0 auto;
				display: table;
				fill: #212121;
				width: 13px;
			}
		}
	}
	
  #download-body{
    background: $white;
    padding: 30px 0;
    &.collapse{
      display: none;
      visibility: hidden;
      overflow: hidden;
      height: 0;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      -webkit-transition: all 0.3s;
    }
    &.collapse.in{
      display: block;
      height: auto;
      visibility: visible;
      max-height: 500px;
    }
    .dld-language{
      display: table;
      margin: 0 auto;
    }
    .hover-cell{
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      -webkit-transition: all 0.3s;
      &:hover{
        background: rgba(0, 21, 77,0.2);
      }
    }
    .download-link{
      display: block;
      color: $blue;
      float: right;
      .icon{
        display: table;
        float: left;
        line-height: 1px;
        position: relative;
        top: -4px;
		
		  
        svg{
          width:25px;
          height: 25px;
          fill:$blue;
          transform: rotate(180deg);
        }
      }
    }
  }
}