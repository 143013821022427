.group-map-title{
	color: #fff;
    padding: 40px 0;
    background: $blue url(../img/patterns/pattern-6.png) 0 0 repeat;
	@media only screen and (max-width: 991px) {
		padding: 30px 0;
	}
	@media only screen and (max-width: 767px) {
		padding: 20px 0;
	}
	h3{
		font-size: 18px;
		letter-spacing: 12px;
		text-align: center;
		color: #fff;
		@media only screen and (max-width: 991px) {
			font-size: 16px;
			letter-spacing: 10px;
		}
		@media only screen and (max-width: 767px) {
			font-size: 14px;
			letter-spacing: 8px;
		}
	}
}
#map-container-contact {
	height:600px;
	@media only screen and (max-width: 1200px) {
		height:500px;
	}
	@media only screen and (max-width: 991px) {
		height:450px;
	}
	@media only screen and (max-width: 767px) {
		height:350px;
	}
}

#figMap area{
	&:hover{
		cursor: pointer;
	}
}
#company_map_container {
	img {
    	margin: 50px auto;
		@media only screen and (max-width: 991px) {
			margin: 40px auto;
		}
		@media only screen and (max-width: 767px) {
			margin: 30px auto;
		}
	}
}
.contact{
	color: $white;
	padding: 40px 15px;
    background: $blue url(../img/patterns/pattern-6.png) 0 0 repeat;
	@media only screen and (max-width: 991px) {
		padding: 30px 15px;
	}
	@media only screen and (max-width: 767px) {
		padding: 20px 15px;
	}
	.contact-info{
		h3{
			font-size: 36px;
			color: $white!important;
			@media only screen and (max-width: 991px) {
				font-size: 32px;
			}
			@media only screen and (max-width: 767px) {
				font-size: 28px;
			}
		}
	}
	@media screen and (max-width: 767px) {
		padding: 40px 15px;
		@media only screen and (max-width: 991px) {
			padding: 30px 15px;
		}
		@media only screen and (max-width: 767px) {
			padding: 20px 15px;
		}
	}	
	.form-control {
		display: block;
		width: 100%;
		height: 40px;
		padding: 6px 12px;
		background-color: $blue;
		border: 1px solid $white;
		color: $white;
		border-radius: 0px;
	}
	.btn-primary {
		color: #fff;
		background-color: $blue;
		border: 1px solid $white;
		border-radius:0px; 
		@media only screen and (max-width: 991px) {
			margin-top: 10px;
		}
		&:hover {
			background-color: #fff;
			color: #00154d;
		}
	}
}

.has-feedback label~.form-control-feedback {
  bottom: 0;
  top: auto;
}