.news-actions {
	position: absolute;
	left: 10%;
	bottom: 10%;
	right: 10%;
	@media screen and (max-width: 767px) {
		position: relative;
		left: 0;
		bottom: 0;
		right: 0;
		padding: 10px 0;
	}
}
.add-to-calendar.create-calendar {
	display:none;
	position: absolute;
	padding: 15px;
	top: 100%;
	background: $orange;
	z-index: 2;
}
.save-date.open{
	.add-to-calendar.create-calendar {
		&.currentcalendar{
			display:block;
		}
	}
}

.news-list-container {
	padding-bottom: 20px;
	.news-filter {
		border-bottom: none;
		margin-bottom: 50px;
	}
	.nav-tabs{
		border-bottom: none;
	}
	@media screen and (max-width: 1400px) {
		padding: 50px 0;
	}
	@media screen and (max-width: 991px) {
		padding: 50px 0;
		.nav>li>a {
		    display: block;
		    padding: 10px 5px;
		}
	}
	.row.even{
		.col-img{
			float:right;
		}
	}
	.article-container {
		padding: 15px;
		height: 320px;
		position: relative;
		@media screen and (max-width: 1200px) {
			height: 235px;
			padding: 10px;
		}
		@media screen and (max-width: 991px) {
			height: 320px;
			padding: 15px;
		}
		
		@media screen and (max-width: 767px) {
			height: auto;
			padding: 15px 0;
		}		
		.title.text_cover {
			@media screen and (max-width: 1200px) {
				font-size: 18px;
				margin: 0 0 10px 0;
			}
			@media screen and (max-width: 767px) {
				font-size: 16px;
				margin: 10px 0;
			}
			
		}
		.text-content{
			@media screen and (max-width: 1200px) {
				font-size: 16px;
				line-height: 1;
			}
			@media screen and (max-width: 767px) {
				font-size: 14px;
			}
		}
		.news-actions{
			left: 15px;
			right: 15px;
			@media screen and (max-width: 1200px) {
				bottom: 10px;
				left: 10px;
				right: 10px;
			}
			@media screen and (max-width: 767px) {
				left: 0;
				right: 0;
				bottom: 0;
			}			
			.grid-category{
				&.no-margin{
					padding: 0px;
				}
				@media screen and (max-width: 1200px) {
					padding: 0 0 0 20px;
					font-size: 14px;
				}
				.box{
					@media screen and (max-width: 1200px) {
						width: 15px;
						height: 15px;	
					}
				}
	
				.save-date{
					padding: 0px;
					color: $blue;
					font-size: 16px;
				}
			}
			.discover-more {
				@media screen and (max-width: 1200px) {
					font-size: 0.8em;
				}
			}
		}
	}

  	.tab-content.news-tab {
		padding-top: 100px;
		@media screen and (max-width: 1200px) {
			padding-top: 70px;
		}
		@media screen and (max-width: 991px) {
			padding-top: 60px;
		}
		@media screen and (max-width: 767px) {
			padding-top: 40px;
		}
	}
	.nav-item.news-category {
		width: 20%;
		@media screen and (max-width: 767px) {
			width: 50%;
		}
		.nav-link {
			text-align: center;
			color: $blue;
			border-radius: 0;
			border-bottom: 1px solid $blue;
			@media screen and (max-width: 767px) {
				padding: 10px 5px;
				font-size: 15px;
			}
			&.events {
				border-bottom: 1px solid $orange;
			}
			&.press-releases {
				border-bottom: 1px solid #01aeea;
			}
				&.case-studies{
				border-bottom: 1px solid #ec008c;
			}
		}
		&:hover,
		&:focus,
		&.active {
			.nav-link{
			background-color: $blue;
			color: $white;
				
			&.events {
				background-color: $orange;
			}
			&.press-releases {
				background-color: #01aeea;
			}
			&.case-studies{
				background-color: #ec008c;
			}
		}
	}
  }
  .news-date {
    color: $black;
  }
  .col {
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
  }
  .col-img {
    position: relative;
	  img{
		 &.default{
			  @media screen and (max-width: 767px){
					display: none;
			  }
			  @media screen and (max-width: 480px){
					display: block;
			  }
		  }
		  &.custom{
			  display: none;
			  @media screen and (max-width: 767px){
					display: block;
			  }
			  @media screen and (max-width: 480px){
					display: none;
			  }
		  } 
	  }
  }
  .col-cont {
    overflow: hidden;
  }
  .col-img.col-left img {
    right: 0;
  }
  .text-content {
    p {
      margin: 0;
    }
  }
  .load-more {
    display: table;
    margin: 30px auto;
    color: $blue !important;
    padding: 10px 15px;
    border-bottom: 1px solid $blue;
    font-size: 18px;
	@media only screen and (max-width: 991px){
		font-size: 16px;
	}
	@media only screen and (max-width: 767px){
		font-size: 14px;
	}
  }

  .line:nth-of-type(even) .col.col-img {
    float: right;
  }
  .line:nth-of-type(even) .text-content {
    text-align: right;
    @media screen and (max-width: 767px) {
      text-align: left;
    }
  }
  .line:nth-of-type(even) {
    .title.text_cover, .news-date,
    .text-content {
      text-align: right;
      @media screen and (max-width: 767px) {
        text-align: left;
      }
    }
  }
  .line:nth-of-type(even) .triangle {
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
    left: calc(50% - 15px);

  }

  .line .item:nth-child(even) {
    @media screen and (max-width: 1199px) {
      margin-bottom: 30px;
    }
    .title.text_cover, .news-date, .text-content {
      @media screen and (max-width: 1199px) {
        text-align: right;
      }
      @media screen and (max-width: 767px) {
        text-align: left;
      }
    }
    .col-img {
      @media screen and (max-width: 1199px) {
        float: right;
      }
      @media screen and (max-width: 767px) {
        text-align: left;
      }
    }
    .triangle {
      @media screen and (max-width: 1199px) {
        left: -15px;
        -ms-transform: rotate(270deg);
        transform: rotate(270deg);
      }
      @media screen and (max-width: 767px) {
        top: initial;
        bottom: 0;
        left: calc(50% - 30px);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }

  .line .item:nth-child(odd) {
    text-align:left;

    @media screen and (max-width: 1199px) {
      margin-bottom: 30px;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 10px;
    }
    .col-img {
      @media screen and (max-width: 1199px) {
        float: left;
      }
    }
    .title.text_cover, .news-date, .text-content {
      @media screen and (max-width: 1199px) {
        text-align: left;
      }
    }
    .triangle {
      @media screen and (max-width: 1199px) {

        right: -15px;
        left: initial;
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
      }
      @media screen and (max-width: 767px) {
        top: initial;
        bottom: 0;
        left: calc(50% - 30px);
        right: initial;
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }

  .grid-category {
    display: block;
    padding: 0 0 0 25px;
    position: relative;
  }

  [data-module=button][data-type=back_offset] .btn-content {
    float: right;
    .label-btn {
      margin: 5px;
      b {
        float: right;
      }
    }
  }
}

.box {
	position: absolute;
	left: 0;
	width: 18px;
	top: 50%;
	bottom: 0;
	height: 18px;
	transform: translateY(-50%);
	&.box-1 {
		background: #01aeea;
	}
	&.box-2 {
		background: $blue;
	}
	&.box-3 {
		background: $orange;
	}
	&.box-4 {
		border: 1px solid $blue;
	}
	&.box-5 {
		background: #ec008c;
	}
}

.triangle {
	position: absolute;
	left: calc(50% - 45px);
	width: 60px;
	top: 30px;
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
	z-index: 2;
	width: 0;
	height: 0;
	border-left: 30px solid transparent;
	border-right: 30px solid transparent;
	border-top: 30px solid $white;
	&.event {
		border-top: 30px solid $orange;
	}
}

.featured-news {
  .featured-carousel {
    .slick-dots {
      li {
        button {
          border: 1px solid $blue;
          border-radius: 7px;
          background: 0 0;
          margin: 15px 7px;
          display: block;
          height: 14px;
          width: 14px;
          opacity: 1;
          -webkit-transition: all .2s ease-in-out;
          -moz-transition: all .2s ease-in-out;
          -ms-transition: all .2s ease-in-out;
          -o-transition: all .2s ease-in-out;
          transition: all .2s ease-in-out;
          &:before {
            content: "";
            display: none;
          }
        }
        &:hover,
        &.slick-active {
          button {
            background: $blue;
          }
        }
      }
    }
  }
}

.news-actions {
  .grid-category {
    font-size: 16px;
  }
}

.related-news {
	padding: 0px 0 100px;
	@media only screen and (max-width: 1200px){
		padding: 0px 0 80px;
	}
	@media only screen and (max-width: 991px){
		padding: 0px 0 60px;
	}
	@media only screen and (max-width: 767px){
		padding: 0px 0 40px;
	}
	.related-carousel {
		.slick-dots {
		bottom: -50px;
			li {
	button {
	  border: 1px solid $blue;
	  border-radius: 7px;
	  background: 0 0;
	  margin: 15px 7px;
	  display: block;
	  height: 14px;
	  width: 14px;
	  opacity: 1;
	  -webkit-transition: all .2s ease-in-out;
	  -moz-transition: all .2s ease-in-out;
	  -ms-transition: all .2s ease-in-out;
	  -o-transition: all .2s ease-in-out;
	  transition: all .2s ease-in-out;
	  &:before {
		content: "";
		display: none;
	  }
	}
	&:hover,
	&.slick-active {
	  button {
		background: $blue;
	  }
	}
	}
		}
		.related-item {
			position: relative;
			overflow: hidden;
			.cube {
				right: -35px;
				width: 70px;
				top: 35px;
				height: 70px;
				left: auto;
				@media only screen and (max-width: 991px){
					right: -34px;
					width: 40px;
					top: 35px;
					height: 40px;
					left: auto;
				}
				@media only screen and (max-width: 767px){
					right: calc(50% - 34px);
					top: auto;					
					left: auto;
					bottom: -34px;
				}				
			}
			.related-image {
				position: relative;
				overflow: hidden;
			}
			.related-caption {
				padding: 30px 0px 50px 0;
				@media only screen and (max-width: 991px){
					padding: 0 0 40px;
				}
				h3 {
					font-family: donlowBold, serif;
					color: $blue;
					@media only screen and (max-width: 991px){					 
						font-size: 20px;
					}
					@media only screen and (max-width: 767px){
						font-size: 18px;
					}
				}
				a.discover-more {
					display: block;
					position: absolute;
					bottom: 0;
					color: $blue;
				}
			}
		}
	}
}

.news-item{
	.triangle {
		left: auto;
		right: -15px;	
	}
	.col{
		padding: 0;
		
	}  
	.col-img{
		img{
			width: 100%;
		}
	}
}
.news-list-container .row.even .news-item{
	.triangle {
		right: auto;
		left: -15px;	
		-ms-transform: rotate(-90deg);
			transform: rotate(-90deg);
	}
}


.article-details{
	.post-tags{
		padding: 15px 0px 30px;
		a{
			background:$blue;
			color:#fff;
			border:1px solid $blue;
			padding: 10px;
			transition: all .3s ease-in-out;
			font-weight: 700;
    		font-size: 14px;

			&:focus,
			&:hover{
				background:#fff;
				color:$blue;
			}
		}
		
	}
	.post-author{
		padding:40px 0 10px;
		.post-author-placeholder{
			width: 50px;
			height: 50px;
			border-radius:50%;
			background: $blue;
			color:#fff;
			position:relative;
			margin-right: 15px;
			span{
				position:absolute;
				top: 50%;
				left: 50%;
				transform:translate(-50%,-50%);
				    font-family: donlowBold, serif;
			}
		}
		.post-author-title{
			font-size: 14px;
			line-height: 1.2;
			b{
				color:$blue;
			}
		}
		
	}
}

@media only screen and (max-width: 991px){
	.news-list-container .row  >.col:nth-child(even) .col-img {
		float: left!important;	
		.triangle {
			left: auto;
			right: -15px;
			-ms-transform: rotate(90deg);
			transform: rotate(90deg);
		}
		
		   
	}
	.news-list-container .row  >.col:nth-child(odd) .col-img {
		float: right!important;	
		.triangle {
			left: -15px;
			right: 0;
			-ms-transform: rotate(-90deg);
				transform: rotate(-90deg);
		}
	}
}
@media only screen and (max-width: 767px){
	.news-list-container .row  >.col:nth-child(even) .col-img,
	.news-list-container .row  >.col:nth-child(odd) .col-img {
		float: left!important;
		.triangle {
			left: calc(50% - 30px);
			right: auto;
			-ms-transform: rotate(90deg);
			transform: rotate(180deg);
			/* bottom: 0; */
			top: auto;
			bottom: 0;
		}
	}
}
