.cv-submit-form{
	padding-bottom: 70px;
	@media only screen and (max-width: 767px) {
		padding-bottom: 30px;
	}
	h3{
		font-family: donlowBold,serif;
		font-size: 18px;
		padding: 0 15px;
		@media only screen and (max-width: 767px) {
			font-size: 16px;
		}
	}
	.well {
		min-height: 20px;
		padding: 19px;
		margin-bottom: 20px;
		background-color: $white;
		border:none;
		border-radius: 0px;
		-webkit-box-shadow: inset 0 0px 0px rgba(0,0,0,.0);
		box-shadow: inset 0 0px 0px rgba(0,0,0,.0);
		label{
			color:$blue;
		}
		.form-control {
			display: block;
			width: 100%;
			height: 34px;
			padding: 6px 12px;
			background-color: #fff;
			border: 1px solid $blue;
			border-radius: 0px;
			-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
			box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
			-webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
			-o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
			transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
			color: $black;
		}
		.form-group{
			h3{
				padding:0;
			}
		}
		.date-space{
			margin-top: 10px;
		}
		
		.small-explanation{
			display:block;
			font-size: 14px;
			color: #777;
			font-family: 'donlowBold',serif;

		}
	}
	.btn{
		border-radius: 0px;
		background: $blue;
		color:$white;
		padding: 10px 30px;
		font-size:18px;
	}
	.cr-title{
		padding: 0 15px;
	}
	.eq-opportunities-title{
		padding:0;
	}
	.list {
		padding: 0;
		list-style-type: none;
		li{
			position: relative;
			float: left;
			margin-right: 15px;
		}
		label{
			padding-left: 30px;
		}
	}
	.checkbox-terms{
		position: relative;
		a,label{
			color:$black;
		}
		label{
			padding-left: 30px;
		}   
		a{
			color:$blue;
			&:hover,&:focus{
				color:$black;
			}
		}
		
	}
	
	.iradio_square-blue {
		position: absolute;
		top: -1px;
		left: 0;
		background-position: -120px 0;
		display: inline-block;
		vertical-align: middle;
		margin: 0;
		padding: 0;
		width: 22px;
		height: 22px;
		border: none;
		cursor: pointer;
		border: 1px solid $blue;
		transition: all .2s;
		&.checked{
			background-position: -168px 0;
			background: $blue;
		}
		&.checked:after{
			content: '✔';
			color: #ffffff;
			font-size: 1.3em;
			top: -4px;
			left: 2px;
			position: absolute;
		}
		
	}

	.survey-steps-container {
		margin: 20px 0 50px 0;
		overflow: hidden;
		background-color: $blue;
		@media only screen and (max-width: 991px) {
			margin: 15px 0 40px 0;
		}
		@media only screen and (max-width: 767px) {
			margin: 10px 0 30px 0;
		}
	}
	.survey-steps {
		color: #ffffff;
		text-transform: uppercase;
		position: relative;
		text-align: center;
		padding: 10px 0!important;
		display: table!important;
		margin: 0px auto!important;
	}

	.survey-steps:before {
		position: absolute;
		content: '';
		top: 0;
		bottom: 0;
		left: -500px;
		right: -160px;
	}
	.survey-steps .survey-progress {
		position: absolute;
		top: 50%;
		left: 0;
		content: '';
		height: 1px;
		background: #ffffff;
		padding: 0;
		margin-top: -1px;
	}

	.survey-steps .survey-progress-bar {
		position: absolute;
		top: 50%;
		left: 0;
		margin-top: -2px;
		content: '';
		height: 3px;
		background: #ffffff;
		display: none;
		transition: right 0.5s;
		-webkit-transition: right 0.5s;
		-moz-transition: right 0.5s;
		-ms-transition: right 0.5s;
		-o-transition: right 0.5s;
	}
	.survey-steps li {
		list-style: none;
		position: relative;
	}
	.survey-steps li.step {
		min-width: 150px;
	}
	.survey-steps li.step:before {
		z-index: 1;
		position: absolute;
		top: 50%;
		left: 50%;
		content: '';
		width: 14px;
		height: 14px;
		background: $blue;
		border-radius: 50%;
		margin-top: -7px;
		margin-left: -7px;
		border: 0px solid #ffffff;
	}
	.survey-steps li.step.active:before {
		background: #ffffff;
		border: 4px solid #ffffff;
	}

	.survey-steps .active .step-counter, .survey-steps .active .step-title {
		color: #ffffff;
	}
	.survey-steps .step-title {
		padding-top: 10px;
		font-size: 12px;
	}
	.survey-steps .step-counter {
		margin-bottom: 20px;
		font-size: 18px;
	}
	.survey-steps .step-counter, .survey-steps .step-title {
		display: block;
	}
	.survey-steps li.step:after {
		position: absolute;
		top: 50%;
		left: 50%;
		content: '';
		width: 16px;
		height: 16px;
		background: #ffffff;
		border-radius: 50%;
		margin-top: -8px;
		margin-left: -8px;
		z-index: 0;
	}
}
.careers-list{
  margin: 50px 0;
	h2{
		margin-bottom: 30px;
	}
	.item {
		position: relative;
		max-height: 330px;
		height: 330px;
		margin: 0 auto;
		display: block;
		overflow: hidden;
		margin-bottom: 30px;
		@media only screen and (max-width: 767px) {
			margin-bottom: 15px;
		}
		.career-release,
		.career-name{
			padding: 10px;
			background: rgba(0, 21, 77, 0.5);
			color: $white;
		}
		.career-release{
			font-size: 16px;
			position: relative;
			z-index: 2;
			top: 100%;
			left: 50%;
			display: table;
			transform: translateX(-50%) translateY(-100%);
			-webkit-transition: all .15s ease-in-out;
			transition: all .15s ease-in-out;
			opacity: 0;
			max-width: 75%;
			@media only screen and (max-width: 1200px) {
				font-size: 14px;
			}
			@media only screen and (max-width: 991px) {
				font-size: 16px;
			}
			@media only screen and (max-width: 991px) {
				font-size: 14px;
			}
		}
		.career-name {
			font-size: 18px;
			position: relative;
			z-index: 2;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			display: table;
			-webkit-transition: all .15s ease-in-out;
			transition: all .15s ease-in-out;
			@media only screen and (max-width: 767px) {
				font-size: 16px;
			}
		}
		.cv-link{
			font-size: 18px;
			position: relative;
			z-index: 2;
			top: 100%;
			left: 50%;
			transform: translateX(-50%) translateY(-100%);
			display: table;
			-webkit-transition: all .15s ease-in-out;
			transition: all .15s ease-in-out;
			padding: 10px;
			background: rgba(255, 255, 255, 1);
			color: $blue;
			opacity: 0;
			@media only screen and (max-width: 767px) {
				font-size: 16px;
			}
		}
		.overlay {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			opacity: .25;
			-webkit-transition: all ease-in-out .3s;
			transition-delay: all ease-in-out .3s;
			z-index: 1;
			pointer-events: all;
			-moz-filter: grayscale(100%);
			-webkit-filter: grayscale(100%);
			filter: grayscale(100%);
		}
		&:hover{
			.career-name {
				top: 10%;
				transform: translateX(-50%) translateY(-10%) ;
			}
			.career-release{
				opacity: 1;
				top: 30%;
				transform: translateX(-50%) translateY(-30%);
				margin-bottom: 10px;
				p{
					margin: 0;
				}
			}
			.cv-link{
				opacity: 1;
				top: 30%;
				transform: translateX(-50%) translateY(-30%);
			}
			.overlay {
				opacity: 1;
				-webkit-filter: grayscale(0)!important;
				-moz-filter: grayscale(0)!important;
				-ms-filter: grayscale(0)!important;
				-o-filter: grayscale(0)!important;
				filter: none!important;
			}
		}
	}
	&.vacancies-list{
		.career-release{
			font-size: 14px;
			max-width: 90%;
		}
	}
}
.factory-operators{
		ul{
			padding: 0;
			list-style-type: none;
			margin-bottom: 30px;
		li{
		  font-size: 18px;
			> ul{
				margin-bottom: 0px;
			}
			@media screen and (max-width: 991px){
				font-size: 16px;
			}
		  @media only screen and (max-width: 767px){
			font-size: 14px;
		  }
		  position: relative;
		  margin-bottom: 10px;
		  padding-left: 30px;
		  &:before{
			content: "\f105";
			font: normal normal normal 20px/1 FontAwesome;
			position: absolute;
			left: 0;
			top: 2px;
			color: $blue;
		  }
		}

	  }
}
