.partners{
	.item{
		position: relative;
		.partner-logo{
			float: left;

			img{
				margin:0 auto;
			}
		}
		.partner-info{
			float: left;
			h3{
				font-family: donlowBold,serif;
				color: $blue;
			}
			a{
				position: relative;
				display: inline-block;
				margin: 15px 0px;
				outline: 0;
				color: $blue;
				text-transform: uppercase;
				font-size: 1em;
				font-family: donlowBold,serif;
			}
		}
		.cube {
			background: #fff;
			left: calc(50% - 38px);
			width: 60px;
			top: 60px;
			-ms-transform: rotate(45deg);
			transform: rotate(45deg) translateX(-50%);
			z-index: 2;
			height: 60px;
		}		
	}
	.slick-dots li.slick-active button, .slick-dots li:hover button {
		background: $blue;
	}
	.slick-dots {
		bottom: -50px;
	}
	.slick-dots li button {
		border: 1px solid $blue;
		border-radius: 7px;
		background: 0 0;
		margin: 15px 7px;
		display: block;
		height: 14px;
		width: 14px;
		opacity: 1;
		-webkit-transition: all .2s ease-in-out;
		-moz-transition: all .2s ease-in-out;
		-ms-transition: all .2s ease-in-out;
		-o-transition: all .2s ease-in-out;
		transition: all .2s ease-in-out;
	}
}