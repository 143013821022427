.cd-container {
	width: 90%;
	max-width: 1170px;
	margin: 0 auto;
}
.cd-container::after {
	/* clearfix */
	content: '';
	display: table;
	clear: both;
}
#cd-timeline {
	position: relative;
	padding: 2em 0;
	margin-top: -3em;
	margin-bottom: 2em;
}
#cd-timeline::before {
	content: '';
	position: absolute;
	top: 100px;
	left: 18px;
    bottom: 100px;
	width: 4px;
	background: $blue;
}
@media only screen and (min-width: 992px) {
	#cd-timeline {
		margin-top: -3em;
		margin-bottom: 3em;
	}
	#cd-timeline::before {
		left: 50%;
		margin-left: -2px;
	}
}

.cd-timeline-block {
	position: relative;
	margin: 2em 0;
}
.cd-timeline-block:after {
	content: "";
	display: table;
	clear: both;
}

.cd-timeline-img {
	position: absolute;
	left: 0;
	width: 40px;
	height: 40px;
	bottom: 20px;
	border-radius: 50%;
	background: #fff;
	box-shadow: 0 0 0 4px rgba(0,21,77,.9), inset 0 2px 0 rgba(255,255,255,.08), 0 3px 0 4px rgba(255,255,255,.05);
	img {
		display: block;
		width: auto;
		height: 30px;
		position: relative;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		margin: 0;
	}
	&.cd-picture {
		background: #fff;
	}
	&.cd-movie {
		background: #fff;
	}
	&.cd-location {
		background: #fff;
	}
}

@media only screen and (min-width: 992px) {
	.cd-timeline-block:nth-child(even) .cd-timeline-content {
		margin-right: 60px;
		margin-left: 0;
	}
	.cd-timeline-img {
		width: 60px;
		height: 60px;
		left: 50%;
		margin-left: -30px;
		-webkit-transform: translateZ(0);
		-webkit-backface-visibility: hidden;
	}
	.cssanimations .cd-timeline-img.is-hidden {
		visibility: hidden;
	}
	.cssanimations .cd-timeline-img.bounce-in {
		visibility: visible;
		-webkit-animation: cd-bounce-1 0.6s;
		-moz-animation: cd-bounce-1 0.6s;
		animation: cd-bounce-1 0.6s;
	}
	.cssanimations .cd-timeline-img.is-hidden {
		 visibility: hidden; 
	}
	.cssanimations .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
		-webkit-animation: cd-bounce-2-inverse 0.6s;
		-moz-animation: cd-bounce-2-inverse 0.6s;
		animation: cd-bounce-2-inverse 0.6s;
	}
	.cssanimations .cd-timeline-content.is-hidden {
		visibility: hidden;
	}

	.cd-timeline-content {
		margin-left: 0;
		font-size: 20px;
		padding: 1.2em 0;
		width: 45%;
	}
	.cd-timeline-content::before {
		top: 24px;
		left: 100%;
		border-color: transparent;
		border-left-color: rgba(0,0,0,0);
	}
	.cd-timeline-content .cd-read-more {
		float: left;
	}
	.cd-timeline-content .cd-date {
		position: absolute;
		width: 100%;
		left: 122%;
		top: 20px;
		font-size: 20px;
		padding: 0 1rem;
	}
	.cd-timeline-block .cd-timeline-content{
		text-align:left;
	}
	.cd-timeline-block:nth-child(odd) .cd-timeline-content {	  
		float: right;
		text-align:left;
	}
	.cd-timeline-block:nth-child(even) .cd-timeline-content {	  
	 	 text-align:right;
		.cd-date{
			text-align:left;
		}
	}
	
	.cd-timeline-block:nth-child(odd) .cd-timeline-content::before {
		top: 24px;
		left: auto;
		right: 100%;
		border-color: transparent;
		border-right-color: rgba(0,0,0,0);
	}
	.cd-timeline-block:nth-child(odd) .cd-timeline-content .cd-read-more {
		float: right;
	}
	.cd-timeline-block:nth-child(odd) .cd-timeline-content .cd-date {
		left: auto;
		right: 122%;
		text-align: right;
	}
	.cssanimations .cd-timeline-content.is-hidden {
		visibility: hidden;
	}
	.cssanimations .cd-timeline-content.bounce-in {
		visibility: visible;
		-webkit-animation: cd-bounce-2 0.6s;
		   -moz-animation: cd-bounce-2 0.6s;
				animation: cd-bounce-2 0.6s;
	}
	.cssanimations .cd-timeline-block:nth-child(odd) .cd-timeline-content.bounce-in {
		-webkit-animation: cd-bounce-2-inverse 0.6s;
		   -moz-animation: cd-bounce-2-inverse 0.6s;
				animation: cd-bounce-2-inverse 0.6s;
	}
}


@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -moz-transform: scale(1.2);
  }

  100% {
    -moz-transform: scale(1);
  }
}
@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

.cd-timeline-content {
	position: relative;
	margin-left: 60px;
	background: rgba(0,0,0,0);
	border-radius: 0;
	padding: 1em;
}



.cd-timeline-content:after {
	content: "";
	display: table;
	clear: both;
}
.cd-timeline-content h2 {
	color: $black;
}
.cd-timeline-content p, .cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
	font-size: 20px;
	color: $black;
}
.history-image{
	max-height: 150px;
	max-width: 150px;
	display:table;
	margin: 0 auto;
}
.history-image .business{
	max-height: 150px;
	max-width: 150px;
	display:table;
	margin: 0 auto;
}
.cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
  display: inline-block;
}
.cd-timeline-content p {
  margin:0;
  line-height: 1.6;
}
.cd-timeline-content .cd-read-more {
  float: right;
  padding: .8em 1em;
  color: white;
  border-radius: 0.25em;
}
.no-touch .cd-timeline-content .cd-read-more:hover {
  background-color: #bac4cb;
}
.cd-timeline-content .cd-date {
  	float: left;
	font-family: donlowMedium,serif;
	color: $blue;
	@media only screen and (max-width: 991px) {
		display: block;
		width: 100%;
	}
}
.cd-timeline-content::before {
  content: '';
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid rgba(0,0,0,0);
}

@media only screen and (min-width: 768px) {
	.cd-timeline-content h2 {
		font-size: 20px;
	}
	.cd-timeline-content p {
		font-size: 20px;
	}
	.cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
		font-size: 20px;
	}
}


@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(20px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
    transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -moz-transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(-20px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}