#ccc {
	position: fixed;
	z-index: 6;				
	font-smooth: antialised;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

@media (min-width:1024px) {
	#ccc {
		font-size: 16px
	}
}	
#ccc-overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
	background: rgba(0, 0, 0, .4);
	-webkit-transform: translate3d(0, -100%, 0);
	transform: translate3d(0, -100%, 0);
	opacity: 0;
	transition: opacity .4s, -webkit-transform 0s;
	transition: transform 0s, opacity .4s;
	transition: transform 0s, opacity .4s, -webkit-transform 0s;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-perspective: 1000;
	perspective: 1000;
	will-change: transform, opacity
}

#ccc.open #ccc-overlay {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	opacity: 1
}

#ccc-icon {
	position: fixed;
	bottom: 0;
	width: 80px;
	height: 80px;
	cursor: pointer;
	z-index: 4
}

#ccc[left] #ccc-icon {
	left: 0;
}

#ccc[right] #ccc-icon {
	right: 0;
}

#ccc[dark] #ccc-icon {
	fill: #a51926;
}

#ccc[dark] #ccc-icon .star path {
	fill: #fff;
}

#ccc[light] #ccc-icon {
	fill: #555;
}

#ccc[light] #ccc-icon .star path,#ccc[light] #ccc-icon .star ellipse {
	fill: #f4f4f4;
}

#ccc-module {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 2;
	transition: -webkit-transform .6s;
	transition: transform .6s;
	transition: transform .6s, -webkit-transform .6s;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-perspective: 1000;
	perspective: 1000;
	will-change: transform
}

#ccc[slideout] #ccc-module {
	top: 0;
	bottom: 0;
	width: 90%;
	max-width: 420px
}

#ccc[slideout][left] #ccc-module {
	left: auto;
	right: 100%;
	-webkit-transform: translateZ(0);
	transform: translateZ(0)
}

#ccc[slideout][left].open #ccc-module {
	right: 100%;
	-webkit-transform: translate3d(100%, 0, 0);
	transform: translate3d(100%, 0, 0)
}

#ccc[slideout][right] #ccc-module {
	right: auto;
	left: 100%;
	-webkit-transform: translateZ(0);
	transform: translateZ(0)
}

#ccc[slideout][right].open #ccc-module {
	-webkit-transform: translate3d(-100%, 0, 0);
	transform: translate3d(-100%, 0, 0)
}

#ccc[popup] #ccc-module {
	left: 0;
	right: 0;
	top: 100%;
	bottom: 0;
	-webkit-transform: translateZ(0);
	transform: translateZ(0)
}

#ccc[popup].open #ccc-module {
	-webkit-transform: translate3d(0, -100%, 0);
	transform: translate3d(0, -100%, 0)
}

#ccc-content {
	position: absolute;
	overflow-y: auto;
	padding: 24px;
	box-sizing: border-box
}

#ccc[slideout] #ccc-content {
	top: 0;
	bottom: 0;
	left: 0;
	right: 0
}

#ccc[popup] #ccc-content {
	width: 90%;
	height: 90%;
	max-height: 600px;
	max-width: 600px;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	border-radius: 12px
}

#ccc[dark] #ccc-content { 
	color: #fff; 
	fill: #fff;
	background-color: $blue;
}

#ccc[light] #ccc-content {
	color: #1d1d1d;
	fill: #1d1d1d;
	background-color: #f4f4f4
}

#ccc-close {
	position: absolute;
	right: 16px;
	top: 16px;
	width: 24px;
	cursor: pointer

}
#ccc-close:hover svg{
	fill:#a51926;
}

#ccc-title {
	font-size: 1.2em;
	line-height: 1.5em;
	font-weight: 700;
	padding-right: 32px;
	margin: 0;
	text-align: left
}

#ccc #ccc-recommended-settings {
	margin-left: 0;
	float: none;
	clear: both;
	border:2px solid $blue;
	text-transform: uppercase;
	padding: 10px 15px;
	background-color: $white;
	color:$blue;
}

#ccc h3 {
	margin: 0;
	font-weight: 700;
	padding-right: 120px
}

#ccc h3,
#ccc p {
	font-size: 1em;
	line-height: 1.5em;
	text-align: left
}

#ccc p {
	font-weight: 400;
	margin: 1em 0
}

#ccc hr {
	height: 1px;
	border: 0;
	margin: 24px 0;
	opacity: .25
}

#ccc .ccc-svg-element {
	width: 16px;
	height: 16px;
	display: inline-block;
	position: relative;
	top: 6px;
	margin-left: 8px
}

#ccc[dark] hr {
	background-color: #fff
}

#ccc[dark] a,
#ccc[dark] h2,
#ccc[dark] h3,
#ccc[dark] p,
#ccc[dark] span {
	color: #fff;
	fill: #fff
}

#ccc[light] hr {
	background-color: #1d1d1d
}

#ccc[light] a,
#ccc[light] h2,
#ccc[light] h3,
#ccc[light] p,
#ccc[light] span {
	color: #1d1d1d;
	fill: #1d1d1d
}

#ccc .optional-cookie {
	position: relative
}

#ccc .checkbox-toggle {
	position: absolute;
	top: -3px;
	right: 0;
	margin: 0;
	border-radius: 50px;
	border: 4px solid #fff
}

#ccc .checkbox-toggle-label {
	display: flex;
	margin: 0;
	cursor: pointer;
	z-index: 1
}

#ccc .checkbox-toggle-input {
	margin: 0;
	padding: 0;
	visibility: hidden;
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%
}

#ccc .checkbox-toggle-off,
#ccc .checkbox-toggle-on {
	flex: 1;
	flex-basis: 50%;
	width: 50%;
	min-width: 40px;
	text-align: center;
	z-index: 2;
	font-size: .75em;
	font-weight: 700;
	padding: 0 6px;
	white-space: nowrap
}

#ccc .checkbox-toggle-off {
	opacity: .65
}

#ccc .checkbox-toggle-toggle {
	display: block;
	width: 50%;
	height: 100%;
	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 3;
	border-radius: 50px;
	transition: all .4s ease;
	background-color: #000
}

#ccc .checkbox-toggle input.checked~.checkbox-toggle-toggle {
	left: 50%
}

#ccc[dark] .checkbox-toggle {
	background-color: #000;
	border-color: #fff
}

#ccc[dark] .checkbox-toggle-toggle {
	background-color: $blue;
}

#ccc[light] .checkbox-toggle {
	background-color: #fff;
	border-color: #d6d6d6;
}

#ccc[light] .checkbox-toggle-toggle {
	background-color: $blue
}

#ccc[light] .checkbox-toggle-off,
#ccc[light] .checkbox-toggle-on {
	color: #000
}

#ccc .ccc-alert {
	margin: -16px 0 0;
	overflow: hidden;
	height: 0;
	border-radius: 8px
} 
#ccc .ccc-alert.visible {
	height: auto;
	margin: 24px 0;
	padding: 16px 16px 0
}

#ccc .ccc-alert-title {
	display: flex;
	align-items: center;
	padding: 0
}

#ccc .ccc-alert-icon {
	display: inline-block;
	width: 30px;
	height: 30px;
	margin: 0 8px 0 0;
	flex-shrink: 0
}

#ccc .ccc-alert-break {
	margin: 12px 0
}

.third-party-cookie-link {
	display: flex;
	align-items: center;
	margin: 16px 0
}

.third-party-cookie-name {
	font-size: 1em;
	font-weight: 700
}

.third-party-cookie-icon {
	display: inline-block;
	width: 16px;
	height: 16px;
	margin-left: 16px
}

#ccc[dark] .ccc-alert {
	color: #fff;
	fill: #fff;
	background: #000;
}

#ccc[light] .ccc-alert {
	background: #eaeaea
}

#ccc #ccc-notify {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	height: auto;
	min-height: 48px;
	padding: 16px;
	margin: 0;
	border-radius: 0;
	overflow: hidden;
	z-index: 5;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	-webkit-transform: translate3d(0, 100%, 0);
	transform: translate3d(0, 100%, 0);
	transition: -webkit-transform .4s;
	transition: transform .4s;
	transition: transform .4s, -webkit-transform .4s
}

#ccc #ccc-notify:empty {
	height: 0;
	padding: 0
}

#ccc #ccc-notify.visible {
	-webkit-transform: translateZ(0);
	transform: translateZ(0)
}

#ccc #ccc-notify h3 {
	padding: 0
}

#ccc #ccc-notify p {
	margin: 0;
	opacity: .8;
	font-size: .9em
}

#ccc .ccc-notify-buttons {
	position: relative;
	margin-top: 16px
}

#ccc .ccc-notify-button {
	border: 2px solid;
	border-radius: 4px;
	padding: 8px 16px;
	font-size: 1em;
	margin: 4px 8px 4px 0;
	font-weight: 400;
	line-height: 1.5em;
	outline: 0;
	background: transparent;
	cursor: pointer
}

#ccc .ccc-notify-button:focus,
#ccc .ccc-notify-button:hover {
	outline: 0
}

#ccc #ccc-notify-dismiss {
	cursor: pointer;
	position: absolute;
	top: -4px;
	right: 0
}

@media (min-width:400px) {
	#ccc #ccc-notify-dismiss {
		position: relative;
		right: auto
	}
}

@media (min-width:600px) {
	#ccc #ccc-notify {
		font-size: 12px;
		flex-direction: row;
		align-items: center
	}
	#ccc .ccc-notify-buttons {
		margin-top: 0;
		white-space: nowrap;
		flex-shrink: 0
	}
}

@media (min-width:1200px) {
	#ccc #ccc-notify {
		font-size: 16px;
		z-index: 0;
		padding: 16px 100px
	}
}

#ccc[dark] #ccc-notify {
	color: #fff;
	fill: #fff;
	background-color: #313147
}

#ccc[dark] .ccc-notify-button {
	color: #fff;
	border-color: #fff
}

#ccc[dark] .ccc-button-solid {
	color: #111125;
	background: #fff;
	font-weight: 700
}

#ccc[light] #ccc-notify {
	color: #000;
	fill: #000;
	background: #f4f4f4
}

#ccc[light] .ccc-notify-button {
	color: #000;
	border-color: #000
}

#ccc[light] .ccc-button-solid {
	color: #fff;
	background: #000;
	font-weight: 700
}

#ccc-info-link {
	font-size: .8em;
	font-weight: 700;
	text-decoration: none
}

#ccc-info-link .svg-element {
	width: 14px;
	height: 14px;
	margin-left: 6px
}

#ccc[slideout] #ccc-info {
	height: 100px
}

#ccc[popup] #ccc-info {
	height: 40px
}

@media print {
	#ccc {
		display: none!important
	}
}
