.newsletter{

	.newletter-input.has-error{
		border-bottom:1px solid red;
	}
	.newsletter-block{
		max-width: 530px;
	    display: block;
	    margin: 0 auto;
	}
	.consent-block{
		display: block;
		padding:30px 0px 0;
		@media (max-width: 767px){
			padding:30px 30px 0;
		}
		@media (max-width: 480px){
			padding:15px 15px 0;
		}
	}
	.option{
		label {
		    position: relative;
		    padding-left: 1.95em;
		    cursor: pointer;
		    font-size: 14px;
		    line-height: 1.3;
		    text-align: left;
		    display: table;
		    a {
			    color: #8e9fbc;
			}

		}
		[type=checkbox]:checked, 
		[type=checkbox]:not(:checked) {
		    position: absolute!important;
		    left: -9999px;
		}
		 [type=checkbox]:checked+label:after, 
		 [type=checkbox]:not(:checked)+label:after {
		    content: '✔';
		    position: absolute;
		    top: .2em;
		    left: .2em;
		    font-size: 1.3em;
		    line-height: .8;
		    color: $blue;
		    transition: all .2s;
		}
		[type=checkbox]:checked+label:before, 
	    [type=checkbox]:not(:checked)+label:before {
		    content: '';
		    position: absolute!important;
		    left: 0;
		    top: 0;
		    width: 1.5em;
		    height: 1.5em;
		    border: 2px solid #fff;
		    background: #fff;
		    border-radius: 0px;
		    box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
		}

		 [type=checkbox]:not(:checked)+label:after {
		    opacity: 0;
		    -ms-transform: scale(0);
		    transform: scale(0);
		}
		 [type=checkbox]:checked+label:after {
		    opacity: 1;
		    -ms-transform: scale(1);
		    transform: scale(1);
		}
		&.has-error{
			label{
				color:red;
			}
			[type=checkbox]:checked+label:before, 
	   		 [type=checkbox]:not(:checked)+label:before {
	   		 	 border: 2px solid red;
	   		 }
		}
	} 
}

