.modal.in{
  background-color: rgba(255,255,255,0.8)
}
.socialbox{
	h3{
		display: table;
		margin: 50px auto;
		font-size: 40px;
		font-family: dlg_condensed_book,Arial,Helvetica,sans-serif;
		@media only screen and (max-width: 1200px) {
			font-size: 38px;
			margin: 45px auto;
		}
		@media only screen and (max-width: 991px) {
			font-size: 35px;
			margin: 30px auto;
		}
		@media only screen and (max-width: 767px) {
			font-size: 30px;
			margin: 20px auto;
		}
	}
	.share-title{
		font-size: 2rem;
		@media only screen and (max-width: 1200px) {
			font-size: 1.8rem;
		}
		@media only screen and (max-width: 991px) {
			font-size: 1.6rem;
		}
		@media only screen and (max-width: 767px) {
			font-size: 1.3rem;
		}
		svg{
			height: 40px;
			width: auto;
		}
	}
	.copy-label{
		@media only screen and (max-width: 767px) {
			display: none;
		}
	}
	.close-share-this{
		line-height: 0;
		position: relative;
		color: #fff;
		background: $blue;
		padding: 5px;
		display: table;
		margin: 50px auto 0 auto;
		&:hover{
			background: $blue;
			-moz-transition: all 0.15s;
			-o-transition: all 0.15s;
			-webkit-transition: all 0.15s;
			transition: all 0.15s;
		}
		svg{
			width: 30px;
			height: 30px;
			fill:$blue;
			line {
				stroke: #ffffff;
				stroke-width: 2px;
			}
		}
	}

  	.center-block{
		width: 100%;
		padding: 0;
		margin: 0 auto;
		list-style-type: none;
		display: inline-block;
		text-align: center;
		li{
			display: inline-block;
			text-align: center;
			margin: 15px;
			@media only screen and (max-width: 767px) {
				margin: 5px;
			}
			.share-btn{
				display: table;
				padding: 15px;
				background: $blue;
				color:#ffffff;
				font-size:30px;
				width:80px;
				-webkit-transition: all 0.15s;
				   -moz-transition: all 0.15s;
					 -o-transition: all 0.15s;
						transition: all 0.15s;				
				@media only screen and (max-width: 767px) {
					padding: 12px 15px;
					font-size: 18px;
					width: 45px;
				}

				&:hover{
					background: $blue;
				}
			}
		}
	}
	.share-quote{
		max-width: 700px;
		display: block;
		margin: 10px auto;
		font-size: 20px;
		text-align: center;
		font-family: dlg_condensed_light,Arial,Helvetica,sans-serif;
	}
	.keywords-list{
		padding: 0;
		margin: 0 auto;
		list-style-type: none;
		display: table;
		max-width: 700px;
		li{
			display: table;
			float: left;
			margin: 15px 5px;
			a{
				color: #555555;
				font-size: 32px;
				font-family: dlg_condensed_light,Arial,Helvetica,sans-serif;
			}
		}
	}
  	.share-url{
		padding-top: 40px;
		display: inline-block;
		width: 700px;
		max-width: 100%;
		@media only screen and (max-width: 991px) {
			padding-top: 30px;
		}
		@media only screen and (max-width: 767px) {
			padding-top: 20px;
		}
		.link.button{
			border: none;
			background: $blue;
			color: #ffffff;
			float: left;
			font-size: 20px;
			width: 20%;
			height: 70px;
			font-family: dlg_condensed_book,Arial,Helvetica,sans-serif;
			@media only screen and (max-width: 767px) {
				height: 60px;
				font-size: 16px;
				width: 110px;
			}
			&:hover{
				background:$blue;
				-moz-transition: all 0.15s;
				-o-transition: all 0.15s;
				-webkit-transition: all 0.15s;
				transition: all 0.15s;
			}
		}
		#clipboard_text{
			height: 70px;
			font-family: dlg_condensed_book,Arial,Helvetica,sans-serif;
			font-size: 20px;
			border:2px solid $blue;
			background-color: #fff;
			padding: 20px;
			width: 80%;
			float: left;
			@media only screen and (max-width: 767px) {
				height: 60px;
				width: calc(100% - 110px);
				padding: 20px 10px;
			}
		}
	}
}