a.challenge-us{
  color: #00154d;
  display: table;
  margin: 0 auto;
  padding: 10px 25px;
  text-decoration: none;
  text-transform: uppercase;
  border: 1px solid #00154d;
  font-family: donlowBold,serif;
  font-size: 20px;
  background: #fff;
	@media screen and (max-width: 991px){
		font-size: 18px;
	}
	@media screen and (max-width: 767px){
		font-size: 16px;
	}
  &:hover{
    background: #00154d;
    color: #fff;

  }
}
.cta-block {
  margin:50px 0;
  padding: 50px 0;
  background:#00154d url(../img/patterns/pattern-6.png) ;
	background-size: cover;
	background-position: center center;
	@media screen and (max-width: 991px){
		margin:40px 0;
  		padding: 40px 0;
	}
	@media screen and (max-width: 767px){
		margin: 30px 0;
  		padding: 30px 0;
	}
  .cta-link {
    position: relative;
    display: table;
    margin: 0 auto;
    padding:30px 100px;
    max-width: 1280px;
	@media screen and (max-width: 991px){
		 padding:25px 75px;
	}
	@media screen and (max-width: 767px){
		 padding:20px 50px;
	}
	@media screen and (max-width: 480px){
		padding:20px 30px;
	}
    h2,h3,h4,p{
      color: #fff;
    }
    a {
		color: #00154d;
		display: table;
		margin: 0 auto;
		padding: 10px 25px;
		text-decoration: none;
		text-transform: uppercase;
		border: 1px solid #fff;
		font-family: donlowBold,serif;
		font-size: 20px;
		background: #ffffff;
		text-align: center;
		@media screen and (max-width: 991px){
			font-size: 18px;
		}
		@media screen and (max-width: 767px){
			font-size: 16px;
		}
      &:hover{
        background: #00154d;
        color:#ffffff;
      }

      &.inverse{
        background: transparent;
        color:#ffffff;
        border: 1px solid #fff;
        &:hover{
          background:#ffffff;
          color: #00154d;
        }
      }

    }

    h4 {
      color: #fff;
      font-size: 20px;
      text-align: center;
      margin-bottom: 15px;
      text-align: center;
      font-family: donlowBold,serif;
      color: #fff;
      font-size: 2rem;
      line-height: 2rem;
      margin-bottom: 2rem;
		@media only screen and (max-width: 991px){
			font-size: 1.5rem;
			line-height: 1.5rem;
		}
		@media screen and (max-width: 767px){
			font-size: 1rem;
			line-height: 1rem;
			margin-bottom: 2rem;
		}
    }
    p {
      color: #fff;
      font-size: 20px;
      padding: 10px 0;
		@media screen and (max-width: 991px){
			font-size: 18px;
		}
		@media screen and (max-width: 767px){
			font-size: 16px;
		}
    }
    &:after {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      background: url(../img/patterns/pattern-6.png) #00154d;
      opacity: 0.5;
		

    }
    .content {
      position: relative;
      z-index: 2;
      color: #fff;
    }
  }
  &.cta-contracted{
    padding:0;
    margin: 0;
    .cta-link{
      padding-top:0;
      padding-bottom:0;
    }
  }
  &.cta-transparent{
    background: transparent;
    .cta-link{
      a{
        color:$blue;
        border-color: $blue;
        &:hover{
          background-color:$blue;
          color:#fff;
        }
      }
      &:after {
        display: none;
      }
    }
  }
}

.enquiries{
	background: $blue url(../img/patterns/pattern-6.png) 0 0 repeat;
	color:$white;
	padding: 50px 0;
	margin: 50px 0;
	@media screen and (max-width: 991px){
		margin:40px 0;
  		padding: 40px 0;
	}
	@media screen and (max-width: 767px){
		margin: 30px 0;
  		padding: 30px 0;
	}
	h2,h3,h4,p{
		color: #fff;
	}
	p{
		font-size: 20px;
		margin: 0;
		padding: 0;
		@media only screen and (max-width: 991px){
		  font-size: 18px;
		}
		@media only screen and (max-width: 767px){
		  font-size: 16px;
		}
	}
	.enquire-button{
		display: table;
		border: 1px solid $white;
		padding: 10px 25px;
		font-size: 20px;
		margin: 10px 0;
		@media only screen and (max-width: 991px){
			font-size: 18px;
		}
		@media only screen and (max-width: 767px){
			font-size: 16px;
			margin: 30px auto 0 auto;
		}
		&:hover{
			color:$blue;
			background: $white;
		}
	}
}

.wind-uplift-performance{
	padding: 50px 0;
	margin:50px 0;
	background: url(../img/patterns/pattern-6.png) #00154d;
	@media screen and (max-width: 991px){
		margin:40px 0;
  		padding: 40px 0;
	}
	@media screen and (max-width: 767px){
		margin: 30px 0;
  		padding: 30px 0;
	}
	h2,h3,h4,p{
		color: #fff;
	}
 	 a.btn{
		text-transform: uppercase;
		display: table;
		color:#00154d;
		background: #ffffff;
		border: 1px solid #fff;
		padding: 10px 40px;
		font-size: 20px;
		margin: 20px auto 0 auto;
    	border-radius: 0;
		@media only screen and (max-width: 991px){
			font-size: 18px;
		}
		@media only screen and (max-width: 767px){
			font-size: 16px;
		}
		&:hover{
			background-color:#00154d ;
			color:#ffffff;
		}
  	}
}


